import { defineStore } from "pinia";

import flightPrimeBookingApi from "@/apis/v2/flight/flightPrimeBookingApi";
import flightCommonTools from "@/tools/v2/flight/common/flightCommonTools";
import contentApi from "@/apis/contentApi";
import flightUpsellTools from "@/tools/v2/flight/upsell/flightUpsellTools";
import flightShoppingTools from "@/tools/v2/flight/shopping/flightShoppingTools";
import flightFilterTools from "@/tools/v2/flight/shopping/flightFilterTools";

export const useFlightUpsellStore = defineStore("flightUpsellStore", {
  state: () => ({
    flightShoppingFormModel: null, //upsell时方便显示表单搜索信息
    originalTripAirItem: null,
    itineraryArrangement: null,
    tripAirItems: null,
    flightItems: null,
    itineraryFareOptionKeyMap: null,
    airportCityMap: null,
    flightItemsShow: null,
    flightFareOptionsMap: null,
    flightFareOptionsShow: null,
    priceClasses: null,
    filterOptions: null,
    upsellFareOptionsSelected: null,
  }),
  actions: {
    /*
     * 用于列表页面Upsell
     * */
    upsellWithSegBasisCodes(formModel) {
      const airItineraryRPH = formModel.airItineraryRPH;
      const fareSegmentBasisCodes = formModel.fareSegmentBasisCodes;
      const upsellRQ = flightUpsellTools.buildUpsellRQ(
        airItineraryRPH,
        fareSegmentBasisCodes
      );
      const thisRef = this;

      thisRef.flightShoppingFormModel = sessionStorage.getItem(
        "flightShoppingFormModel"
      )
        ? JSON.parse(sessionStorage.getItem("flightShoppingFormModel"))
        : null;
      return flightPrimeBookingApi
        .verifyPrice(upsellRQ)
        .then((res) => {
          const upsellRS = res.data;

          if (
            upsellRS &&
            upsellRS.pricedItineraryList &&
            upsellRS.pricedItineraryList.length > 0
          ) {
            //Basic Info
            thisRef.flightItems = flightUpsellTools.buildFlightItems(upsellRS);

            thisRef.itineraryFareOptionKeyMap =
              flightUpsellTools.buildItineraryFareOptionKeyMap(upsellRS);

            thisRef.flightFareOptionsMap =
              flightUpsellTools.buildFlightFareOptionsMap(upsellRS);

            thisRef.priceClasses = flightCommonTools.buildPriceClasses(
              upsellRS.offer
            );

            //直接显示该OD的flightItem
            thisRef.flightItemsShow = flightUpsellTools.findSpecificFlightItems(
              thisRef.flightItems,
              formModel.tripSequenceNumber
            );

            //Filter
            thisRef.filterOptions = flightFilterTools.buildFilterOptions(
              thisRef.flightItemsShow
            );

            thisRef.airportCityMap = {};
            const airportCodes = flightShoppingTools.findAllAirportCodes(
              thisRef.flightItems
            );
            let promises = [];
            for (const airportCode of airportCodes) {
              let promise = contentApi.airportCity(airportCode);
              promises.push(promise);
            }

            Promise.all(promises).then((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  let data = res[i].data;
                  thisRef.airportCityMap[data.code] = data;
                }
              }
            });
          } else {
            thisRef.emptyState();
          }
          return upsellRS;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveItineraryArrangement(formModal) {
      this.itineraryArrangement =
        flightUpsellTools.buildItineraryArrangement(formModal);
    },
    saveShoppingFormModal(formModal) {
      this.flightShoppingFormModel = formModal;
    },
    /*
     * 用于Checkout页面upsell
     * */
    upsell(airItineraryRPH) {
      const upsellRQ = flightUpsellTools.buildUpsellRQ(airItineraryRPH);
      const thisRef = this;
      return flightPrimeBookingApi
        .verifyPrice(upsellRQ)
        .then((res) => {
          const upsellRS = res.data;
          if (
            upsellRS &&
            upsellRS.pricedItineraryList &&
            upsellRS.pricedItineraryList.length > 0
          ) {
            //Basic Info
            thisRef.originalTripAirItem = flightUpsellTools.buildOldTripAirItem(
              upsellRS.pricedItineraryList
            );
            thisRef.tripAirItems = flightUpsellTools.buildTripAirItems(
              upsellRS.pricedItineraryList
            );

            thisRef.itineraryFareOptionKeyMap =
              flightUpsellTools.buildItineraryFareOptionKeyMap(upsellRS);

            thisRef.flightFareOptionsMap =
              flightUpsellTools.buildFlightFareOptionsMap(upsellRS);

            thisRef.priceClasses = flightCommonTools.buildPriceClasses(
              upsellRS.offer
            );

            //Default trip flight items show
            if (thisRef.tripAirItems && thisRef.tripAirItems.length > 0) {
              thisRef.flightItemsShow = thisRef.tripAirItems[0].flightItems;
            }
            if (
              thisRef.flightFareOptionsMap &&
              Object.keys(thisRef.flightFareOptionsMap).length > 0
            ) {
              thisRef.flightFareOptionsShow =
                thisRef.flightFareOptionsMap[
                  thisRef.flightItemsShow[0].originDestinationKey
                ];
            }
            thisRef.airportCityMap = {};
            const airportCodes = flightUpsellTools.findAllAirportCodes(
              thisRef.tripAirItems
            );

            let promises = [];
            for (const airportCode of airportCodes) {
              let promise = contentApi.airportCity(airportCode);
              promises.push(promise);
            }

            return Promise.all(promises).then((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  let data = res[i].data;
                  thisRef.airportCityMap[data.code] = data;
                }
              }
            });
          } else {
            thisRef.emptyState();
          }
          return upsellRS;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    /*
     * 该方法用于checkout页面
     * */
    changeFareOptionsShow(originDestinationKey, fareOptionsSelected, amount) {
      const thisRef = this;
      thisRef.flightFareOptionsShow = null;
      if (
        !thisRef.flightFareOptionsMap ||
        Object.keys(thisRef.flightFareOptionsMap).length === 0
      )
        thisRef.flightFareOptionsShow = null;

      if (fareOptionsSelected && fareOptionsSelected.length > 0) {
        const itineraryFareOptionKey =
          flightShoppingTools.buildItineraryFareOptionKey(fareOptionsSelected);
        const itineraryKey = itineraryFareOptionKey.split(":!")[0];
        const itineraryPriceClassKeySplit = itineraryFareOptionKey.split("!!");
        const priceClassIdsKey = itineraryPriceClassKeySplit[1];

        thisRef.flightFareOptionsShow = thisRef.flightFareOptionsMap[
          originDestinationKey
        ].filter((flightFareOption) => {
          return this.fareOptionsIndicator(
            flightFareOption,
            itineraryKey,
            priceClassIdsKey,
            amount,
            null
          );
        });
      } else {
        thisRef.flightFareOptionsShow =
          thisRef.flightFareOptionsMap[originDestinationKey];
      }
    },
    /*
     * 该方法用于列表页指定OD的fare options显示，不能用于checkout 页
     * */
    showUpsellOptionsShow(originDestinationKey) {
      const thisRef = this;
      thisRef.flightFareOptionsShow = null;

      if (
        !thisRef.flightFareOptionsMap ||
        Object.keys(thisRef.flightFareOptionsMap).length === 0
      )
        thisRef.flightFareOptionsShow = null;
      else
        thisRef.flightFareOptionsShow =
          thisRef.flightFareOptionsMap[originDestinationKey];
    },

    fareOptionsIndicator(
      flightFareOption,
      itineraryKey,
      priceClassIdsKey,
      amount,
      filterType
    ) {
      let match = false;

      const totalPrice = flightFareOption.totalPrice;

      if (filterType === "UP" && amount && totalPrice < amount) {
        return false;
      } else if (filterType === "DOWN" && amount && totalPrice > amount) {
        return false;
      }

      const prePriceClassIdsKey = flightFareOption.prePriceClassIdsKey;
      console.info(prePriceClassIdsKey, priceClassIdsKey);
      if (
        (prePriceClassIdsKey == null &&
          priceClassIdsKey !== "null" &&
          priceClassIdsKey !== "undefined" &&
          priceClassIdsKey !== "" &&
          priceClassIdsKey !== null) ||
        (!priceClassIdsKey && prePriceClassIdsKey)
      ) {
        //选择的非空,被选的为空 || 选择的为空，备选的非空
        return false;
      }

      if (priceClassIdsKey && prePriceClassIdsKey !== priceClassIdsKey) {
        return false;
      }

      //没有brand fare的情况处理

      const itineraryKeyCompare =
        itineraryKey + "#" + flightFareOption.originDestinationKey;
      const itineraryKeyCombine =
        flightFareOption.supplierCode +
        ":" +
        flightFareOption.preItineraryKey +
        "#" +
        flightFareOption.originDestinationKey;

      if (itineraryKeyCompare !== itineraryKeyCombine) {
        return false;
      } else {
        match = true;
      }
      return match;
    },
    findItineraryRPH(itineraryFareOptionKey) {
      return this.itineraryFareOptionKeyMap[itineraryFareOptionKey];
    },
    changeUpsellFareOptionsSelected(upsellFareOptionsSelected) {
      this.upsellFareOptionsSelected = upsellFareOptionsSelected;
    },
    changeOriginalTripAirItem(tripAirItem) {
      this.originalTripAirItem = tripAirItem;
    },
    findItineraryRPHWithPreItinKey(fareOptionSelected) {
      if (fareOptionSelected) {
        const bookingSource = fareOptionSelected.supplierCode;
        let preItineraryKey = fareOptionSelected.preItineraryKey;
        let originDestinationKey = fareOptionSelected.originDestinationKey;
        const itineratyKeyCombined = preItineraryKey
          ? bookingSource + ":" + preItineraryKey + "#" + originDestinationKey
          : bookingSource + ":" + originDestinationKey;

        let find = Object.keys(this.itineraryFareOptionKeyMap).find(
          (key) => key.indexOf(itineratyKeyCombined) === 0
        );
        return this.itineraryFareOptionKeyMap[find];
      }
    },
    /*
     * 该方法用于最后一个OD选完Fare Option之后，通过增加价格匹配，获取指定airItineraryRPH,
     * 已验证，列表页Upsell可用
     * */
    findItineraryRPHWithFinalPrice(fareOptionSelected) {
      if (fareOptionSelected) {
        const bookingSource = fareOptionSelected.supplierCode;
        let preItineraryKey = fareOptionSelected.preItineraryKey;
        let originDestinationKey = fareOptionSelected.originDestinationKey;
        const itineratyKeyCombined = preItineraryKey
          ? bookingSource +
            ":" +
            preItineraryKey +
            "#" +
            originDestinationKey +
            ":!" +
            fareOptionSelected.currencyCode +
            fareOptionSelected.totalPrice
          : bookingSource +
            ":" +
            originDestinationKey +
            ":!" +
            fareOptionSelected.currencyCode +
            fareOptionSelected.totalPrice;
        console.info(itineratyKeyCombined);

        let find = Object.keys(this.itineraryFareOptionKeyMap).find(
          (key) => key.indexOf(itineratyKeyCombined) === 0
        );
        return this.itineraryFareOptionKeyMap[find];
      }
    },
    emptyState() {
      const thisRef = this;
      //Basic Info
      thisRef.originalTripAirItem = null;
      thisRef.itineraryArrangement = null;
      thisRef.tripAirItems = null;
      thisRef.flightItems = null;
      thisRef.itineraryFareOptionKeyMap = null;
      thisRef.airportCityMap = null;
      thisRef.flightItemsShow = null;
      thisRef.flightFareOptionsMap = null;
      thisRef.flightFareOptionsShow = null;
      thisRef.priceClasses = null;
      thisRef.upsellFareOptionsSelected = null;
    },
  },
});
