<script setup>
import { ref, watch } from "vue";
import commonUtils from "@/utils/commonUtils";
import { useI18n } from "vue-i18n";
import dateUtils from "../../../../../../utils/dateUtils";
import flightCommonTools from "../../../../../../tools/v2/flight/common/flightCommonTools";

let props = defineProps({
  flightItem: {},
  flightIndex: {},
  flightNum: {},
  priceClass: {},
  airlineItemMap: {},
  aircraftItemMap: {},
  airportCityMap: {},
});
const { t, locale } = useI18n(); // 解构调用函数
const priceClassRef = ref([]);

watch(
  () => props.priceClass,
  (newValue) => {
    priceClassRef.value = newValue;
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <div v-if="flightItem" class="mg-b-20">
    <h5>
      <span class="text-primary p-1">
        {{ $t("flight") }} {{ flightIndex + 1 }} {{ $t("to") }}
        {{ flightItem.destinationLocationCode }}</span
      >

      <span class="text-muted mg-l-10 font-16"
        >{{ dateUtils.formatEnglishDate(flightItem.departureDateTime).ddate }}
        {{ dateUtils.formatEnglishDate(flightItem.departureDateTime).month }},
        {{
          dateUtils.formatEnglishDate(flightItem.departureDateTime).year
        }}</span
      >
    </h5>
    <h5 v-if="priceClassRef && priceClassRef.priceClassName">
      <span
        class="text-muted mg-l-10 font-16 cursor-point"
        :data-bs-target="
          '#' + commonUtils.openModelByKey('PRICECLASS', flightIndex)
        "
        data-bs-toggle="modal"
        >{{ priceClassRef.priceClassName }} <i class="fa-solid fa-eye"></i
      ></span>
    </h5>
    <div class="row">
      <div class="col-10">
        <div class="tab-flight-info">
          <div>
            <div
              class="fw-semibold d-inline-block py-1 px-3 fs-sm bg-light rounded"
            >
              {{
                dateUtils.formatEnglishDate(flightItem.departureDateTime).time
              }}
            </div>
            <div class="text-muted text-center">
              {{ flightItem.departureAirportCode }}
            </div>
          </div>
          <div class="font-14 text-muted text-center">
            <span>
              {{ dateUtils.formatHourMinute(flightItem.durationInMins) }}</span
            >
            <div class="flight-line">
              <div><i class="fa-solid fa-plane-departure"></i></div>
              <div class="line"></div>
              <div><i class="fa-solid fa-plane-arrival"></i></div>
            </div>
            <span>{{
              flightItem.numStops === 0
                ? $t("direct")
                : $t("number-stops", { stopNumber: flightItem.numStops })
            }}</span>
          </div>
          <div>
            <div
              class="fw-semibold d-inline-block py-1 px-3 fs-sm bg-light rounded"
            >
              {{ dateUtils.formatEnglishDate(flightItem.arrivalDateTime).time }}
            </div>
            <div class="text-muted text-center">
              {{ flightItem.arrivalAirportCode }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-l-3"
      v-for="(segment, segmentIndex) in flightItem.flightSegments"
      :key="segmentIndex"
    >
      <div class="font_weight font-14">
        {{ dateUtils.formatEnglishDate(segment.departureDateTime).ddate }}
        {{ dateUtils.formatEnglishDate(segment.departureDateTime).month }}
        ,
        {{ dateUtils.formatEnglishDate(segment.departureDateTime).year }}

        <span
          class="fw-semibold d-inline-block py-1 px-3 fs-sm bg-light rounded"
        >
          {{
            dateUtils.formatEnglishDate(segment.departureDateTime).time
          }}</span
        >
        {{ $t("departure-from") }}
        {{
          flightCommonTools.getAirportName(
            locale,
            segment.departureAirportCode,
            airportCityMap
          )
        }}({{ segment.departureAirportCode }})
      </div>
      <div>
        {{ $t("flight-duration") }} :
        {{
          dateUtils.TimeDecrement(
            segment.arrivalDateTime,
            segment.departureDateTime
          )
        }}
      </div>
      <div class="font_weight font-14">
        {{ dateUtils.formatEnglishDate(segment.arrivalDateTime).ddate }}
        {{ dateUtils.formatEnglishDate(segment.arrivalDateTime).month }}
        ,
        {{ dateUtils.formatEnglishDate(segment.arrivalDateTime).year }}

        <span
          class="fw-semibold d-inline-block py-1 px-3 fs-sm bg-light rounded"
          >{{ dateUtils.formatEnglishDate(segment.arrivalDateTime).time }}</span
        >
        {{ $t("arrive-at") }}
        {{
          flightCommonTools.getAirportName(
            locale,
            segment.arrivalAirportCode,
            airportCityMap
          )
        }}
        ({{ segment.arrivalAirportCode }})
      </div>
      <div>
        <span v-if="segment.marketingAirlineCode">
          <img
            :src="commonUtils.getAirlinePicture(segment.marketingAirlineCode)"
            alt="img"
            class="pic-35"
          />
          {{
            flightCommonTools.getAirlineName(
              locale,
              segment.marketingAirlineCode,
              airlineItemMap
            )
          }}
          {{ segment.flightNumber }}
        </span>
      </div>
      <div>
        <span v-if="segment.cabinClass"
          >{{ segment.cabinClass }} <span> ,</span></span
        >

        <span v-if="segment.baggageAllowance" class="mg-l-8">
          <i class="fa-solid fa-suitcase-rolling"></i>
          {{ segment.baggageAllowance }}
        </span>
      </div>
      <hr v-if="segmentIndex < flightItem.flightSegments.length - 1" />
    </div>

    <!-- priceClass description Slide Left Block Modal -->
    <div
      :id="commonUtils.openModelByKey('PRICECLASS', flightIndex)"
      :aria-labelledby="commonUtils.openModelByKey('PRICECLASS', flightIndex)"
      aria-hidden="true"
      class="modal fade"
      role="dialog"
      tabindex="-1"
      v-if="priceClassRef"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                {{ priceClassRef.priceClassName }}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="block-content">
              <div class="p-2">
                <div
                  class="fs-sm"
                  v-for="(item, itemIndex) in priceClassRef.priceDescriptions"
                  :key="itemIndex"
                >
                  <p class="fs-sm fw-medium">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END priceClass description Slide Left Block Modal -->
  </div>
</template>

<style lang="scss" scoped></style>
