<script setup>
import { useTemplateStore } from "@/stores/template";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useCmsContentStore } from "@/stores/cms";
import { createVNode, onUnmounted, ref, watch } from "vue";
import { useHotelReserveStore } from "@/stores/v2/hotel/reserve/hotelReserveStore";
import hotelCommonTools from "@/tools/v2/hotel/common/hotelCommonTools";
import { HotelReserveFormModel } from "@/formmodel/sales/hotel/reserve/hotelReserveFormModel";
import useVuelidate from "@vuelidate/core";
import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import HotelItinerarySummaryPanel from "@/views/v2/sales/hotel/items/panel/HotelItinerarySummaryPanel.vue";
import HotelCheckOutRoomPassengerForm from "@/views/v2/sales/hotel/items/form/checkout/HotelCheckOutRoomPassengerForm.vue";
import HotelPoliciesPanel from "@/views/v2/sales/hotel/items/panel/tab/HotelPoliciesPanel.vue";
import HotelPriceSummaryOrBreakdownPanel from "@/views/v2/sales/hotel/items/panel/HotelPriceSummaryOrBreakdownPanel.vue";
import commonUtils from "@/utils/commonUtils";
import TripContactInfoForm from "@/views/v2/sales/common/items/form/TripContactInfoForm.vue";

const templateStore = useTemplateStore();
const { t, locale } = useI18n(); // 解构调用函数
const router = useRouter();
const route = useRoute();
const AModal = Modal;
const hotelReserveStore = useHotelReserveStore();
//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.HotelBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.HotelBackgroundImage.url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

const hotelReserveFormModelRef = ref(null);
const tripHotelItemRef = ref(null);
const tripContactInfoRef = ref({
  phoneNumber: null,
  emailAddress: null,
  countryAccessCode: null,
}); //联系电话

let second = ref(0);
let minutes = ref(0);
const clockRef = ref(null);
const limitTimeRef = ref(1800); //倒计时时间
const endTime = Date.now() + limitTimeRef.value * 1000; // 倒计时结束时间

//此处当页面最小化或者切换到其他页面时存在延迟（浏览器优化策略），需要记录一个结束时间
clockRef.value = setInterval(() => {
  limitTimeRef.value = Math.round((endTime - Date.now()) / 1000); //结束时间减去现在的时间就知道还剩多少时间
  if (limitTimeRef.value > 0) {
    minutes.value = Math.floor(limitTimeRef.value % 60);
    second.value = Math.floor(limitTimeRef.value / 60);
    limitTimeRef.value--;
  } else {
    clearInterval(clockRef.value);

    commonUtils.openToastBox(
      "WARNING",
      t("room-rates-have-expired-please-re-search"),
      t("warning"),
      function () {
        location.reload();
      }
    );
  }
}, 1000);

//#region query参数请求，进行hold
if (route.query != null && Object.keys(route.query).length > 0) {
  hotelReserveFormModelRef.value = parseHotelReserveFormModel(route.query);
  holdRoomInventory(hotelReserveFormModelRef.value);
}

function parseHotelReserveFormModel(query) {
  const referenceId = query.referenceId;
  const languageCode = query.languageCode;
  const checkIn = query.checkIn;
  const checkOut = query.checkOut;
  const bedTypeName = query.bedTypeName;
  const roomTypeName = query.roomTypeName;
  const roomPassengersFormat = query.roomPassengersFormat;
  const roomPassengers =
    hotelCommonTools.parseRoomPassengers(roomPassengersFormat);

  const hotelReserveFormModel = new HotelReserveFormModel(
    languageCode,
    checkIn,
    checkOut,
    null
  );

  let containPrimaryGuest = false;
  let guestRPH = 0;
  roomPassengers.forEach((roomPassenger) => {
    //添加房间
    const roomResGuestFormItem = hotelReserveFormModel.addNewRoomResGuest(
      referenceId,
      roomTypeName,
      bedTypeName
    );
    const adultNum = roomPassenger.adultNum;
    const childAges = roomPassenger.childAges;

    if (adultNum) {
      let containMain = false;
      for (let i = 0; i < adultNum; i++) {
        //添加住客
        const resGuest = roomResGuestFormItem.addNewResGuest(
          null,
          guestRPH,
          "10",
          30
        );

        if (!containMain) {
          resGuest.mainIndicator = true;
          containMain = true;
        }
        if (!containPrimaryGuest) {
          resGuest.primaryIndicator = true;
          containPrimaryGuest = true;
        }

        guestRPH++;
      }
    }
    if (childAges && childAges.length > 0) {
      for (const childAge of childAges) {
        const age = childAge.age;
        roomResGuestFormItem.addNewResGuest(null, guestRPH, "8", age);
        guestRPH++;
      }
    }
  });

  return hotelReserveFormModel;
}

//#endregion

watch(
  () => hotelReserveStore.tripHotelItem,
  (newValue) => {
    tripHotelItemRef.value = newValue;
  },
  { immediate: true, deep: true }
);

//region预定相关逻辑

const v = useVuelidate();

async function confirmBooking(hotelReserveFormModel) {
  const result = await v.value.$validate();
  if (!result) return;

  const hotelReserveFormModelCopy = JSON.parse(
    JSON.stringify(hotelReserveFormModel)
  );
  hotelReserveFormModel = mergeFormModel(
    hotelReserveFormModelCopy,
    tripContactInfoRef.value
  );

  AModal.confirm({
    content: t("you-will-confirm-the-reservation-now"),
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    width: 600,
    wrapClassName: "confirmModal",
    onOk() {
      return new Promise((resolve, reject) => {
        templateStore.pageLoader({ mode: "on" });
        hotelReserveStore
          .initiateReservation(hotelReserveFormModel)
          .then((res) => {
            if (res && !res.success) {
              let error = "";
              if (
                res.errors &&
                res.errors.errors &&
                res.errors.errors.length > 0
              ) {
                error = res.errors.errors[0].stringValue;
              }

              commonUtils.openToastBox(
                "ERROR",
                t("this-is-an-error-message"),
                error
              );
            } else {
              const hotelBookResRQ = hotelReserveStore.hotelBookResRQ;
              sessionStorage.setItem("bookRQ", JSON.stringify(hotelBookResRQ));
              let uniqueIDList = null;
              if (hotelBookResRQ) {
                const hotelReservationList =
                  hotelBookResRQ.hotelReservationList;

                if (hotelReservationList && hotelReservationList.length > 0) {
                  uniqueIDList = hotelReservationList[0].uniqueIDList;
                }
              }

              const bookingInfo = getBookingInfo(uniqueIDList);
              router.push({
                name: "v2-payment-methods",
                query: {
                  bookingId: bookingInfo.bookingId,
                  invoiceId: bookingInfo.invoiceId,
                  productType: "HOTEL",
                },
              });
            }
            AModal.destroyAll();
            templateStore.pageLoader({ mode: "off" });
          });
      }).catch((err) => {
        console.log(err);
        templateStore.pageLoader({ mode: "off" });
      });
    },
    cancelText: "Cancel",
    onCancel() {
      AModal.destroyAll();
    },
  });
}

function mergeFormModel(hotelReserveFormModel, tripContactInfo) {
  const roomResGuests = hotelReserveFormModel.roomResGuests;
  for (const roomResGuest of roomResGuests) {
    const resGuests = roomResGuest.resGuests;
    for (const resGuest of resGuests) {
      if (resGuest.primaryIndicator) {
        resGuest.phoneNumber = tripContactInfo.phoneNumber;
        resGuest.countryAccessCode = tripContactInfo.countryAccessCode;
        resGuest.emailAddress = tripContactInfo.emailAddress;
      }
    }
  }
  return hotelReserveFormModel;
}

function getBookingInfo(bookingReferenceIdList) {
  let bookingId = null;
  let invoiceId = null;
  if (bookingReferenceIdList && bookingReferenceIdList.length > 0) {
    bookingReferenceIdList.forEach((item) => {
      if (item.type === "BOOKING_ID") {
        bookingId = item.id;
      } else if (item.type === "INVOICE_ID") {
        invoiceId = item.id;
      }
    });
  }
  return {
    bookingId,
    invoiceId,
  };
}

//endregion 预定相关逻辑

//region 辅助函数

function holdRoomInventory(hotelReserveFormModel) {
  templateStore.pageLoader({ mode: "on" });
  hotelReserveStore
    .holdRoomInventory(hotelReserveFormModel)
    .then((res) => {
      if (res && !res.success) {
        let error = "";
        if (res.errors && res.errors.errors && res.errors.errors.length > 0) {
          error = res.errors.errors[0].stringValue;
        }
        commonUtils.openToastBox("ERROR", t("this-is-an-error-message"), error);
      }
      templateStore.pageLoader({ mode: "off" });
    })
    .catch((err) => {
      templateStore.pageLoader({ mode: "off" });
    });
}

function goToHotelDetails(query) {
  //details页面带过来的多余参数不清楚会不会有影响，先删掉
  if (query.checkIn) {
    delete query.checkIn;
  }
  if (query.checkOut) {
    delete query.checkOut;
  }
  if (query.roomTypeName) {
    delete query.roomTypeName;
  }
  if (query.bedTypeName) {
    delete query.bedTypeName;
  }
  if (query.referenceId) {
    delete query.referenceId;
  }
  router.push({ name: "sales-hotel-details-v2", query: route.query });
}

//endregion

//region 设备相关

const equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);

//endregion

onUnmounted(() => {
  //组件销毁的时候清除定时器
  clearInterval(clockRef.value);
});
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("check-out") }}</h2>
        <ul>
          <li class="pre-page" @click="goToHotelDetails">
            {{ $t("hotel-details") }}
          </li>
          <li>
            <i class="fas fa-circle"></i>
            {{ $t("check-out") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div v-if="equipmentType !== 'iphone'" class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goToHotelDetails">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>

      <div v-if="tripHotelItemRef" class="row">
        <div class="col-8">
          <div>
            <div
              class="alert alert-warning d-flex align-items-center justify-content-between mg-b-20"
              role="alert"
            >
              <div class="flex-grow-1 me-3">
                <p class="mb-0">
                  <i class="fa fa-fw fa-exclamation-circle"></i>
                  <span
                    class="text-black"
                    v-html="
                      $t(
                        'please-mark-in-fill-in-within-seconds-to-avoid-the-room being',
                        {
                          second: second,
                          minutes: minutes,
                        }
                      )
                    "
                  ></span>
                </p>
              </div>
            </div>
          </div>

          <!-- hotel Info -->
          <HotelItinerarySummaryPanel :trip-hotel-item="tripHotelItemRef" />
          <!-- hotel Info END-->

          <!-- Contact Info Form -->
          <TripContactInfoForm :tripContactInfo="tripContactInfoRef" />
          <!-- Contact Info Form END-->

          <!--Room  Passenger Form -->
          <HotelCheckOutRoomPassengerForm
            :equipment-type="equipmentType"
            :room-passenger-items="
              hotelReserveFormModelRef && hotelReserveFormModelRef.roomResGuests
                ? hotelReserveFormModelRef.roomResGuests
                : []
            "
          />
          <!--Room Passenger Form END-->

          <!--   Policies     -->
          <HotelPoliciesPanel
            v-if="
              tripHotelItemRef.policies && tripHotelItemRef.policies.length > 0
            "
            :policies="tripHotelItemRef.policies"
            :title="$t('policies')"
          />
          <!--   Policies   END  -->

          <!-- Price Summary  Or Breakdown-->
          <HotelPriceSummaryOrBreakdownPanel
            :trip-hotel-item="tripHotelItemRef"
            @confirmBooking="confirmBooking(hotelReserveFormModelRef)"
          />
          <!-- Price Summary  Or Breakdown END -->
        </div>

        <div class="col-4">
          <div class="tour_booking_form_box">
            <h3 class="heading_theme">
              {{ $t("booking-instructions") }}
            </h3>
            <div
              class="p-2 mg-b-10"
              v-html="$t('booking-instructions-content')"
            ></div>
          </div>

          <div class="tour_booking_form_box">
            <h3 class="heading_theme">
              {{ $t("travel-tips") }}
            </h3>
            <div class="p-2 mg-b-10">
              <i class="fa-solid fa-bed text-warning mg-r-8"></i>
              {{ $t("travel-tips-content") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
