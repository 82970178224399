<script setup>
import { computed, getCurrentInstance, inject, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useSecurityStore } from "@/stores/security";
import { useTemplateStore } from "@/stores/template"; // Vuelidate, for more info and examples you can check out https://github.com/vuelidate/vuelidate
import useVuelidate from "@vuelidate/core";
import { minLength, required } from "@vuelidate/validators";
import securityApi from "@/apis/securityApi";
import { Input, InputPassword } from "ant-design-vue";
import { useI18n } from "vue-i18n";

const AInputPassword = InputPassword;
const AInput = Input;
const { t } = useI18n();
// Main store and Router
const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const securityStore = useSecurityStore();

// Input state variables
const state = reactive({
  username: null,
  password: null,
});

// Validation rules
//自定义正则表达式校验器
const rules = computed(() => {
  return {
    username: {
      required,
      minLength: minLength(3),
    },
    password: {
      required,
      minLength: minLength(5),
    },
  };
});

// Use vuelidate
const v$ = useVuelidate(rules, state);

//region sign in
let errorMessage = ref("");

async function signIn() {
  const result = await v$.value.$validate();

  if (!result) return;
  templateStore.pageLoader({ mode: "on" });

  securityApi
    .signIn(state.username, state.password)
    .then((res) => {
      if (res.data) {
        securityStore.userInfo = res.data;
        sessionStorage.setItem("user", JSON.stringify(res.data));
        let routeInfo = JSON.parse(sessionStorage.getItem("routeInfo"));
        if (routeInfo && Object.keys(routeInfo).length > 0) {
          const routeName = routeInfo.routeName;
          delete routeInfo.routeName;
          router.push({ name: routeName, query: routeInfo });
        } else {
          //没有的话默认跳转至首页
          router.push({ name: "home" });
        }
        templateStore.pageLoader({ mode: "off" });
      } else {
        sessionStorage.removeItem("user");
        templateStore.pageLoader({ mode: "off" });
      }
    })
    .catch((error) => {
      console.log(error);
      if (error.status === 401) {
        errorMessage.value = t("user-name-and-password-mismatch");
      }
      const errorAlert = document.getElementById("errorPasswordAlert");
      if (errorAlert) {
        errorAlert.classList.add("show");
        setTimeout(() => {
          errorAlert.classList.add("fade");
        }, 15000);
      }
      sessionStorage.removeItem("user");
      templateStore.pageLoader({ mode: "off" });
    });
}

//endregion

//region google login
const Vue3GoogleOauth = inject("Vue3GoogleOauth");
const cns = getCurrentInstance();

// 谷歌登录相关
async function handleGoogleSignIn() {
  try {
    const googleUser =
      await cns.appContext.config.globalProperties.$gAuth.signIn();
    if (!googleUser) {
      return null;
    }
    // const userEmail = googleUser.getBasicProfile().getEmail();
    let idToken = googleUser.getAuthResponse().id_token;
    console.info(idToken);
    //   securityApi.signIn(null, null, "google", idToken).then((res) => {
    //     console.log(res);
    //     // if (res.data) {
    //     //   securityStore.userInfo = res.data;
    //     //   sessionStorage.setItem("user", JSON.stringify(res.data));
    //     //   let preStepRouteName = sessionStorage.getItem("preStepRouteName", name);
    //     //   let preStepQuery = JSON.parse(sessionStorage.getItem("preStepQuery"));
    //     //   if (preStepRouteName) {
    //     //     if (
    //     //       preStepQuery &&
    //     //       preStepQuery instanceof Object &&
    //     //       Object.keys(preStepQuery).length > 0
    //     //     ) {
    //     //       sessionStorage.removeItem("preStepRouteName");
    //     //       sessionStorage.removeItem("preStepQuery");
    //     //       router.push({ name: preStepRouteName, query: preStepQuery });
    //     //     } else {
    //     //       sessionStorage.removeItem("preStepRouteName");
    //     //       sessionStorage.removeItem("preStepQuery");
    //     //       router.push({ name: preStepRouteName });
    //     //     }
    //     //   } else {
    //     //     router.back();
    //     //   }
    //     //   templateStore.pageLoader({ mode: "off" });
    //     // } else {
    //     //   sessionStorage.removeItem("user");
    //     //   templateStore.pageLoader({ mode: "off" });
    //     // }
    //   });
  } catch (error) {
    //on fail do something
    console.error(error);
  }
}

//endregion
</script>

<template>
  <!-- ALERT -->
  <div
    id="errorPasswordAlert"
    class="alert alert-danger alert-dismissible fade"
    role="alert"
  >
    <p class="mb-0">
      {{ errorMessage }}
    </p>
    <!--    <button-->
    <!--      aria-label="Close"-->
    <!--      class="btn-close"-->
    <!--      data-bs-dismiss="alert"-->
    <!--      type="button"-->
    <!--    ></button>-->
  </div>
  <form id="main_author_form" action="#" @submit.prevent="signIn">
    <div class="form-group">
      <a-input
        v-model:value="state.username"
        :class="{
          'is-invalid': v$.username.$errors.length,
        }"
        :placeholder="$t('enter-your-username')"
        class="main_input_height ant-form-control"
        @blur="v$.username.$touch"
      />
      <div
        v-if="v$.username.$errors.length"
        class="invalid-feedback animated fadeIn"
      >
        {{ $t("please-enter-your-username") }}
      </div>
    </div>
    <div class="form-group">
      <a-input-password
        v-model:value="state.password"
        :class="{
          'is-invalid': v$.password.$errors.length,
        }"
        :placeholder="$t('enter-your-password')"
        class="main_input_height ant-form-control"
        @blur="v$.password.$touch"
      />
      <div
        v-if="v$.password.$errors.length"
        class="invalid-feedback animated fadeIn"
      >
        {{ $t("please-enter-your-password") }}
      </div>
      <div class="d-inline-block">
        <router-link
          class="d-inline-block p-lg-1"
          to="/security/forget-password"
          >{{ $t("forgot-password") }}?
        </router-link>
        <router-link class="d-inline-block" to="/security/reactive-account">
          {{ $t("reactive-account") }}?
        </router-link>
      </div>
    </div>
    <div class="text-end">
      <a class="btn-link cursor-point" @click="handleGoogleSignIn">
        <i class="fa-brands fa-google font-20 main_color"></i
      ></a>
      <a class="btn-link cursor-point">
        <i class="fa-brands fa-facebook main_color font-20 mg-l-8"></i
      ></a>
    </div>
    <div class="common_form_submit">
      <button class="btn btn_theme">{{ $t("sign-in") }}</button>
    </div>
    <div class="have_acount_area">
      <p>
        {{ $t("dont-have-a-account") }}?
        <router-link class="main_color" to="/security/sign-up"
          >{{ $t("register-now") }}
        </router-link>
      </p>
    </div>
  </form>
</template>

<style lang="scss" scoped></style>
