<script setup>
import { useI18n } from "vue-i18n";
import { Modal } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { useTripStore } from "@/stores/v2/trip/tripStore";
import { ref, watch } from "vue";
import CarRentalDriverInfoPanel from "@/views/v2/trip/booking/items/panel/carrental/reservation/items/panel/CarRentalDriverInfoPanel.vue";
import CarRentalDetailsPanel from "@/views/v2/sales/carrental/items/panel/CarRentalDetailsPanel.vue";
import CarRentalPriceSummaryPanel from "@/views/v2/sales/carrental/items/panel/CarRentalPriceSummaryPanel.vue";
import ReservationOverViewPanel from "@/views/v2/trip/booking/commom/ReservationOverViewPanel.vue";
import ReservationOperationPanel from "@/views/v2/trip/booking/commom/ReservationOperationPanel.vue";

const props = defineProps({
  tripId: {},
  isFromTripList: {},
  tripItemType: {},
});

const { t, locale } = useI18n(); // 解构调用函数

const emits = defineEmits();
const AModal = Modal;
const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const tripStore = useTripStore();

const tripCarRentalItemRef = ref(null);
watch(
  () => tripStore.tripItems,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      const tripItem = newValue[0];
      if (tripItem && tripItem.tripCarRentalItems) {
        const tripCarRentalItems = tripItem.tripCarRentalItems;
        if (tripCarRentalItems && tripCarRentalItems.length > 0) {
          tripCarRentalItemRef.value = tripCarRentalItems[0];
        }
      }
    }
  },
  { immediate: true, deep: true }
);

function syncSessionAndSearch() {
  emits("syncSessionAndSearch");
}
</script>

<template>
  <div v-if="tripCarRentalItemRef" class="row">
    <div class="col-10">
      <!-- booking manage -->
      <ReservationOperationPanel
        :booking-id="tripId"
        :trip-item-type="tripItemType"
        :booking-status="tripCarRentalItemRef.tripItemStatus"
        @syncSessionAndSearch="syncSessionAndSearch"
      />
      <!-- booking manage END-->

      <ReservationOverViewPanel
        :is-from-trip-list="isFromTripList"
        :trip-id="tripId"
        :trip-item-type="tripItemType"
        :booking-status="tripCarRentalItemRef.tripItemStatus"
        :created-date-time="tripCarRentalItemRef.createdDateTime"
      />

      <!-- tour Info -->
      <CarRentalDetailsPanel :car-rental-item="tripCarRentalItemRef" />
      <!-- tour Info END-->

      <!-- driver Info -->
      <CarRentalDriverInfoPanel :driver="tripCarRentalItemRef.driver" />
      <!-- driver Info END-->

      <!-- Price Summary  Or Breakdown-->
      <CarRentalPriceSummaryPanel
        :is-reservation="true"
        :trip-car-rental-item="tripCarRentalItemRef"
      />
      <!-- Price Summary  Or Breakdown END -->
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
