export default {
  any: "any",
  all: "all",
  address: "address",
  amenities: "Amenities",
  amount: "Amount",
  adult: "Adult",
  age: "Age",
  adults: "Adults",
  "adult-price": "Adult Price",
  airlines: "Airlines",
  "agency-portal": "Agency Portal",
  "arrival-time": "Arrival time",
  "add-another-flight": "Add another flight",
  "advance-question": "Advance question",
  "already-have-an-account": "Already have an account?",
  "apart-hotel": "Apart-hotel",
  "all-inclusive": "All Inclusive",
  "airport-shuttle-included": "Airport shuttle included",
  "all-photos": "All Photos",
  "add-another-room": "Add another room",

  back: "Back",
  booking: "Booking",
  bookings: "Bookings",
  "bed-type": "Bed Type",
  "booking-preview": "Booking Preview",
  "birth-date": "Birth Date",
  "by-credit-card": "By Credit Card",
  "back-to-home": "Back to home",
  "booking-amount": "Booking amount",
  "breakfast-included": "Breakfast Included",
  "booking-confirm": "Booking Confirm",
  "booking-date-range": "Booking Date Range",
  "booking-in-process": "Booking In Process",
  "booking-status": "Booking Status",
  "booking-success": "Booking success!",
  "booking-confirmed": "Your booking #{ bookingId } is confirmed",
  "back-to-results": "Back To Results",
  "bookings-found": "bookings found",
  "booking-id": "Booking Id",

  condo: "Condo",
  cabin: "Cabin",
  company: "Company",
  connection: "connection",
  contact: "Contact",
  checkin: "Check In",
  checkout: "Check Out",
  child: "Child",
  chinese: "Chinese",
  children: "Children",
  confirmed: "Confirmed",
  confirm: "Confirm",
  cancel: "Cancel",
  cancelled: "Cancelled",
  close: "Close",
  continue: "Continue",
  create: "Create",
  "create-uppercase": "CREATE",
  "cancellation-policy": "Cancellation Policy",
  "check-in": "Check In",
  "check-out": "Check Out",
  "check-in-date": "Check In Date",
  "check-out-date": "Check Out Date",
  "child-price": "Child Price",
  "cabin-class": "Cabin Class",
  "child-less-than": "Less than 12 yrs",
  "child-index-age": "Child { childIndex} age ",
  "created-date": "Created Date",
  "cancellation-policy-refundable-caution":
    "Cancel your reservation from <span class='text-warning'>{beginTime}</span> to <span class='text-warning'>{endTime}</span>,You'll be charged <span class='font-18 font_weight text-danger'>{currency} {amount}</span>.",
  "cancellation-policy-nights-caution":
    "Cancel your reservation from <span class='text-warning'>{beginTime}</span> to <span class='text-warning'>{endTime}</span>,You'll be charged for the <span class=\"font-18 font_weight text-danger\">{nmbrOfUnit} night(s)</span>  of your stay plus taxes and fees.",
  "cancellation-policy-nonrefundable-caution":
    "Cancel your reservation before <span class='text-warning'>{beginDate}</span>, and you'll get a full refund. After that, you'll be charged all. Times are based on the property’s local time.",
  "check-in-to-check-out": "Check-in to Check-out",
  "confirm-booking": "Confirm Booking",
  "continue-to-payment": "Continue To Payment",
  "common-area": "Common Area",
  "confirmation-number-with-supplier": "Confirmation number",
  "choose-your-room": "Choose your room",
  "cancel-booking": "Cancel Booking",
  "call-us": "Call us for any help",
  "mail-us": "Mail to our support team",
  "confirmation-number": "Confirmation Number:",
  "contact-us": "Contact us",
  "contact-with-us": "Contact with us",
  "children-ages": "Children Ages 0 to 17",
  "copy-right": "Copyright © 2024 All Rights Reserved",
  "check-email-info":
    "You can check your email for further details. We have sent you a mail with details.",

  details: "Details",
  destination: "Destination",
  date: "Date",
  done: "Done",
  discount: "Discount",
  dashboard: "Dashboard",
  "dinner-included": "Dinner Included",
  "double-room": "Double Room",
  "departure-date": "Departure Date",
  "departure-time": "Departure time",
  "discover-your-ideal": "Discover your ideal experience with us",
  "dont-have-a-account": "Dont have an account",

  exterior: "Exterior",
  email: "Email",
  expired: "Expired",
  error: "Error",
  english: "English",
  excellent: "Excellent",
  "explore-our-hot-deals": "Explore our hot deals",
  "error-400-message":
    "You have issued an incorrect request, or it is corrupt and the server cannot understand it",
  "error-401-message":
    "We are sorry but you are not authorized to access this page",
  "error-403-message":
    "We are sorry but you do not have permission to access this page",
  "error-404-message":
    "We are sorry but the page you are looking for was not found",
  "error-500-message":
    "We are sorry but our server encountered an internal error",
  "error-503-message":
    "We are sorry but our service is currently not available",
  "extra-service-total": "Extra Service Total",
  "extra-service": "Extra Service",
  "expiry-time": "Expiry Time",
  "expiry-date": "Expiry Date",
  "expire-time-min-s": "Expire in { minutes } min {second}s",
  "enter-the-email": "Please enter the email combined with your account",
  "export-to-sabre": "Export To Sabre",
  "email-address": "Email Address",

  fees: "fees",
  from: "From",
  flight: "Flight",
  1023: "Filter",
  flights: "Flights",
  "flight-class": "Flight class",
  "from-to": "From {from} to {to}",
  "flight-price": "Flight Price",
  "num-flights-found": "{flightViewItemsShowLength} flights found",
  "forgot-password": "Forgot Password",
  "filter-by-price": "Filter by price",
  "follow-us": "Follow us on",
  "first-name": "First name",
  "for-room": "for { roomNum } room",
  "frequent-flyer-airline": "Frequent Flyer Airline",
  "frequent-flyer-airline-number": "Frequent Flyer Airline Number",
  "frequent-answer-and-question": "Frequent answer and question",

  "get-free-quote": "Get free quote",
  "go-beyond-your-imagination": "Go beyond your imagination",
  "going-to": "Going to",
  "get-latest-news-offers": "Get the latest news and offers",
  "guest-rating-our-picks": "Guest rating + our picks",
  "guest-rating": "Guest Rating",
  guests: "Guests",
  "good-3.5+": "Good 3.5+",
  "general-question": "General question",
  "go-back": "Go Back",
  "guest-name": "Guest Name",
  "given-name": "Given Name",
  "go-to-home": "Go to home",

  hotel: "Hotel",
  hotels: "Hotels",
  home: "Home",
  "hotel-home": "Hotel Home",
  "hotel-name": "Hotel Name",
  "hotel-price": "Hotel Price",
  "hotel-location": "Hotel location",
  "hotel-amenities": "Hotel amenities",
  "hostel-backpacker": "Hostel/Backpacker",

  info: "Info",
  infant: "Infant",
  "info-uppercase": "INFO",
  "info-source": "Info Source: {supplierCode}",
  "itinerary-review": "Itinerary Review",
  "infant-price": "Infant Price",
  "infant-less-than": "Less than 2 yrs",
  "inbox-menu": "Inbox Menu",
  "include-in-total-amount": "INCLUDE IN TOTAL AMOUNT",
  "includes-taxes-fees": "includes taxes & fees",
  "invoice-number": "Invoice number:",
  "journey-date": "Journey Date",

  logout: "Logout",
  "lessons-hours": "10 lessons · 3 hours",
  "log-out": "Log Out",
  "load-more": "Load more",
  "last-name": "Last name",
  "leave-us-a-message": "Leave us a message",
  "logged-in-to-stay-in-touch": "Logged in to stay in touch",
  "lunch-included": "Lunch Included",
  "learn-html5": "Learn HTML5 in 10 simple and easy to follow steps",

  meal: "Meal",
  "meal-plans-available": "Meal plans available",
  "more-photos": "More Photos",
  "multi-city": "Multi city",
  "more-details": "More details",
  "my-bookings": "My bookings",
  "my-profile": "My Profile",

  next: "Next",
  night: "night",
  name: "Name",
  nights: "nights",
  nationality: "Nationality",
  "night-stay": "night stay",
  "no-room-type-name": "No Room Type Name",
  "number-of-stops": "Number of stops",
  "num-passenger": "{passengersNum} Passenger",
  "num-night-stay": "{num}-night stay",
  "num-hotel": "  {length} hotel(s) found",
  "non-refundable": "Non-refundable",
  "need-help": "Need any help?",
  "name-submitted-successfully":
    "{customerName}, your booking was submitted successfully!",

  or: "or",
  overview: "Overview",
  "one-way": "One Way",
  "our-partners": "Our partners",
  "operation-uppercase": "OPERATION",

  pool: "Pool",
  package: "Package",
  passport: "Passport",
  phone: "phone",
  passengers: "Passengers",
  properties: "properties",
  prev: "Prev",
  price: "Price",
  previous: "Previous",
  policies: "Policies",
  "price-uppercase": "PRICE",
  "passenger-information": "Passenger information",
  "payment-method": "Payment Method",
  "passenger-class": "Passenger, Class",
  "price-starts-from": "From {currency} {amount} ",
  "passenger-room-class": "Passenger,Room,Class",
  "please-enter-a-valid-password": "Please enter a valid password",
  "please-enter-a-valid-username": "Please enter a valid username",
  "please-enter-a-valid-firstname": "Please enter a valid firstname",
  "please-enter-a-valid-lastname": "Please enter a valid lastname",
  "please-enter-a-valid-email": "Please enter a valid email address",
  "please-enter-a-valid-phone-number": "Please enter a valid phone number",
  "please-enter-your-departure-point": "Please enter your  departure point",
  "please-enter-your-arrival-point": "Please enter your arrival point",
  "please-enter-hotel-location": "Please enter hotel location",
  "passport-expiry-date":
    "Passport expiry date is within 6 months of departure or return",
  "price-low-to-high": "Price:low to high",
  "price-high-to-low": "Price:high to low",
  "price-range": "Price Range",
  "package-flight-hotel": "Package(Flight + Hotel)",
  "property-type": "Property Type",
  "price-info": "Price Info",
  "price-summary": "Price Summary",
  "passenger-info": "Passenger Info",
  "price-details": "Price Details",
  "phone-number": "Phone Number",
  "privacy-policy": "Privacy Policy",
  "passport-number": "Passport Number",
  "print-invoice": "Print Invoice",
  "print-voucher": "Print Voucher",
  "price-total": "{ currency } {price} total",

  recommended: "Recommended",
  room: "Room",
  rooms: "Rooms",
  remove: "Remove",
  refundable: "Refundable",
  reset: "Reset",
  register: "Register",
  reserve: "Reserve",
  "read-and-accept-conditions": "I read and accept all Terms and conditions",
  "room-name-index": "Room {index} { roomTypeName }",
  "round-trip": "Round trip",
  "reset-your-password": "Reset your password",
  "room-types-rate-comments": "Rate Comments - { roomTypes } -  {roomIndex}",
  "status-uppercase": "STATUS",
  "remember-me": "Remember Me",
  "rate-comments": "Rate Comments",
  "register-now": "Register now",
  "regular-question": "Regular question",
  "register-account": "Register account",
  "register-your-account": "Register your account",
  "reactive-account": "Reactive account",
  "reactive-your-account": "Reactive your account",
  "return-date": "Return Date",
  "room-index": "Room - Index",
  "room-price": "Room Price",
  "room-price-total": "Room Price Total",
  "room-name": "Room Name",
  "reset-password": "Reset Password",
  "rate-description": "Rate Description",
  "room-quantity": "Room Quantity",
  "remove-room": "Remove room",
  "room-prices-details": "Room Prices Details",

  sales: "Sales",
  submit: "Submit",
  search: "Search",
  spa: "Spa",
  sort: "Sort",
  status: "Status",
  supplier: "Supplier",
  subscribe: "Subscribe",
  sleeps: "Sleeps",
  surname: "Surname",
  support: "Support",
  "sign-in": "Sign In",
  "subscribe-our-newsletter": "Subscribe to our newsletter",
  "show-more": "Show More",
  "show-less": "Show less",
  "select-your-room": "Select your room",
  "stay-in-touch": "Stay in touch",
  "sign-in-now": "Sign in now",
  "sign-in-your-account": "Sign In your account",
  "send-links": "Send links",
  "send-message": "Send message",
  "sign-up": "Sign up",
  "search-by-destination": "Search by destination",
  "search-by-departure": "Search by departure",
  "see-fare-details": " See fare details",
  "searching-result": "Searching Result",
  "star-rating": "Star rating",
  "sort-by": "Sort by",
  "show-details": "Show details",
  "show-more-room-rate": "Show More Room Rate",
  "stopover-in": "Stopover #{ arrivalDateTime } in  #{ arrivalAirportName }",
  "see-all-properties": "See all properties",
  "service-name": "Service Name",
  "special-requests": "Special Requests",
  "select-status": "Select Status",
  "sub-total": "Sub Total",
  "select-the-child-age": "Please select the child's age!",
  "special-requests-optional": "Special requests (Optional)",
  "sure-export-booking": "Are you sure to export booking #{ bookingId }",
  "sure-cancel-booking": "Are you sure to cancel booking #{ bookingId }",

  taxes: "Taxes",
  tax: "Tax",
  to: "To",
  type: "Type",
  total: "Total",
  traveler: "Traveler",
  "taxes-total": "Taxes Total",
  "terms-and-condition": "Terms & Condition",
  "trip-advisor": "Trip Advisor",
  "today-price": "Today's Price",
  "taxes-and-fees": "Taxes and fees",
  "taxes-fees": "taxes & fees",
  "taxes-fees-included": "taxes & fees included",
  "total-due": "Total Due",
  "trip-total-price": "Trip total price",
  "traveler-info": "Traveler Info",
  "traveler-name": "Traveler Name",
  "taxes-include-total": "Taxes(Include in total amount)",
  "total-amount": "Total Amount",

  "your-choice": "Your choices",
  "your-booking-detail":
    "Your booking details has been sent to: { emailAddress }",

  "very-good-4.0+": "Very good 4.0+",
  "view-on-map": "View on map",
  "view-your-booking": "View your booking",

  "welcome-please-login": "Welcome, please login.",
  "where-are-you-going": "Where are you going",
  "wonderful-4.5+": "Wonderful 4.5+",
  "who-we": "Who we are",

  "guest-uppercase": "GUEST",

  "fare-rules": "Fare Rules",

  "cruise-package-code": "Cruise package code",
  deposit: "deposit",
  "deposit-in": "Deposit in {key}",
  "trip-total-in": "Trip total in {key}",
  "guest-info": "Guest Info",

  "side-overlay-content": "Side Overlay content..",
  carts: "Carts",
  reports: "Reports",
  "edit-profile": "Edit Profile",
  "current-language": "Current Language",
  "all-languages": "All Languages",
  "basic-search": "Basic search",
  "filter-by": "Filter by",
  "passengers-summary":
    "{adult} Adult(s) , {child} Child(s) , {infant} Infant(s) ",
  in: "in",
  "hotel-shopping": "Hotel Shopping",
  "travel-room-num": "{travelersNumber} traveler(s) , {roomCount}  room(s)",
  "who-checking": "Who's checking in?",
  "cancellation-policies": "CANCELLATION POLICIES",
  "daily-prices": "Daily Prices",
  "cruise-shopping": "Cruise Shopping",
  "booking-progress": "Booking Progress",
  "select-dining-preference": "Select your Dining Preference",
  "cruise-dinning":
    "In February 2019, Celebrity Summit took part in Celebrity’s fleet-wide Revolution. Restaurants and lounges became more than just dining rooms and pass time areas. Staterooms became home away from home and guests’ personal sanctuaries. Celebrity Summit stopped being just a ship and became a better way to experience the world.",
  location: "Location",
  carRental: "Car Rental",
  seats: "Seats",
  "car-rental-search": "Car Rental Search",
  return: "Return",
  "car-transfer-home": "Car Transfer Home",
  "search-car-transfer": "Search Car Transfer ",
  infants: "infants",
  "adult-child-infant":
    "{adultNum}  adult(s), {childNum} child(s) , {infantNum}  infant(s)",
  "add-another": "Add another",
  "your-journey": "Your journey",
  "travel-document-apply": "Travel Document Apply",
  validity: "Validity",
  review: "review",
  documents: "documents",
  applicant: "applicant",
  gender: "Gender",
  "personal-details": "Personal details",
  "package-search": "Package Search",
  "hotel-result": "Hotel Result",
  "passengers-room-summary":
    "{passengersNum} passengers ,{roomCount} room(s) ,{cabinName}",
  "minimum-price": "Input minimum price",
  "maximum-price": "Input maximum price",
  "contact-info": "Contact Info",
  "no-matched-location": "! No matched options {location}",
  "see-more-description": "See more description",
  "activities-home": "Activities Home",
  "per-application": "Per Application",
  "personal-info": "Personal Information",
  "start-date": "Start Date",
  "end-date": "End Date",
  "cancel-at": "Cancel at: ",
  "activity-duration": "Activity duration: ",
  "check-availability": "Check availability",
  "about-this-activity": "About this activity",
  "youth-child": "Youth/Child",
  "country-to-visit": "Country To Visit",
  senior: "Senior",
  language: "Language",
  "per-adult": "Per Adult",
  per: "Per",
  "start-at": "Start at",
  "question-description": "Question description",
  "necessary-info": "Necessary info",
  group: "Group",
  transmission: "Transmission",
  "please-select-your-trip": "Please select your {tripType} trip",
  operating: "Operating",
  "deadline-for-reservations": "The deadline for reservations is {dateTime}",
  "terms-and-conditions": "Terms and conditions",
  "departing-station": "Departing Station",
  "arriving-station": "Arriving Station",
  provider: "Provider",
  "ticket-details": "Ticket details",
  "continue-to-passengers": "Continue to Passengers",
  "pass-option": "Pass Option",
  "select-options": "Select Options",
  "valid-from-date": "Valid from {date}",
  "comfort-category": "Comfort Category",
  "pass-review": "Pass Review",
  "duration-near-to-far": "Duration:near to far",
  "duration-far-to-near": "Duration:far to near",
  "fare-before-commission": "Fare before commission",
  "total-after-commission": "Total after commission",
  "rail-passenger": "Rail Passenger",
  "passenger-types": "Passenger types",
  "rail-review": "Rail Review",
  "selling-price": "Selling price",
  inbound: "INBOUND",
  outbound: "OUTBOUND",
  "first-travel-date": "First travel date",
  "age-range": "Age range : {range}",
  "name-appear-as-passport": "Your name must appear as on your passport",
  "choose-to-travel": "Choose to travel",
  "traveler-day-within":
    "Travel days can be anytime within { within } of the first date of travel, as written on your pass.",
  pass: "Pass",
  "years-old-over": "{age} years old and over",
  "age-from-to": "{fromAge} to {toAge} years old",
  youth: "Youth",
  "select-a-validity": "Please select a validity !",
  "ticket-review": "Ticket Review",
  ancillary: "Ancillary",
  "enter-a-email-address": "Enter a email address",
  "enter-your-phone-number": "Enter your phone number",
  "enter-your-passport": "Enter your passport",
  "frequent-flyer-airline-number-option":
    "Frequent Flyer Airline Number(option)",
  "are-you-sure": "Are you sure?",
  "you-will-confirm-the-trip-now":
    "You will confirm the trip now and pay later",
  "you-will-pay-now": "You will pay and confirm the trip now",
  warning: "Warning",
  "booking-details": "Booking details",
  ticketing: "Ticketing",
  "where-are-you-from": "Where are you from?",
  "postal-code": "Postal code",
  "properties-num": "{num} properties",
  "when-is-your-arrival-date": "When is your arrival date?",
  "when-is-your-departure-date": "When is your departure date?",
  "back-to-room": "BACK TO ROOM",
  "review-pay": "REVIEW & PAY",
  "tours-and-activities": "Tours And Activities",
  visiting: "Visiting",
  "enter-your-answer": "Enter your answer",
  timeErr: "The arrival time cannot be earlier than the departure time",
  "you-will-cancel-reservation": "You will cancel reservation",
  "reservation-result": "Reservation Result",
  "order-details": "Order Details",
  "password-reminder": "Password reminder",
  "create-account": "Create account",
  "select-your-extras": "Select your extras",
  "rail-search": "Rail Search",
  "who-is-checking-in": "Who is checking in?",
  "credit-card-info": "Credit Card Info",
  "ticket-conditions": "Ticket Conditions",
  "early-morning-am": "Early Morning (12:00am - 4:59am)",
  "morning-am": "Morning (5:00am - 11:59am)",
  "after-pm": "Afternoon (12:00pm - 5:59pm)",
  "evening-pm": "Evening (6:00pm - 11:59pm)",
  non: "Non",
  stop: "Stop",
  "non-stop": "Non-stop",
  "choose-frequent-flyer-airline-option":
    "Choose Frequent Flyer Airline(option)",
  mr: "Mr.",
  ms: "Ms.",
  mrs: "Mrs.",
  dr: "Dr.",
  prof: "Prof.",
  mister: "Mister.",
  miss: "Miss.",
  passenger: "Passenger",
  "no-additional-baggage": "No additional baggage",
  "no-additional-meal": "No additional meal",
  segment: "Segment",
  "not-issued": "NOT_ISSUED",
  "in-process": "IN PROCESS",
  "issued-uppercase": "ISSUED",
  "successful-ticketing":
    "Ticketing is processing, status will update in few minutes.",
  "ticket-issue-failed": "Ticket issue failed!",
  success: "success",
  oops: "Oops...",
  "select-your-transmitting-system": "Select your transmitting system",
  "source-type": "Source Type : {sourceType}",
  "get-a-quate": "Get a quote",
  "select-your-plan": "Select your plan",
  "personal-information": "Personal Information",
  "special-features": "Special Features",
  "with-starr-travelead":
    "With Starr TraveLead, we can rest assured you a care free trip! Hassle-free purchase with one click!",
  "insurance-special-features-one": "Medical expenses up to HK$1,000,000",
  "insurance-special-features-two":
    "Covers trip cancellation with immediate effect",
  "insurance-special-features-three":
    "Covers accidental loss or damage to mobile phone",
  "single-trip-travel-plan": "Single Trip Travel Plan",
  "essential-insurance": "Essential",
  "extra-insurance": "Extra",
  "supreme-insurance": "Supreme",
  "apply-insurance": "Apply",
  coverage: "Coverage",
  "sum-insured": "Sum Insured",
  "Section 1 - Accidental Death and Disablement *":
    "Section 1 - Accidental Death and Disablement *",
  "Death or permanent disablement arising within 12 months of an accident.":
    "Death or permanent disablement arising within 12 months of an accident.",
  "(a) Acident while in a common carrier":
    "(a) Acident while in a common carrier",
  "(b) Other accidents": "(b) Other accidents",
  "room-amenities": "Room amenities",
  "hotel-remarks": "Limit 250 characters",
  "synchronize-command": "Synchronize Command",
  "synchronized-success": "Synchronized Success",
  "manage-this-order": "Manage this order",
  "change-flight": "Change Flights",
  "cancellation-quote": "Cancellation Quote",
  "add-seats": "Add seats",
  "add-bags": "Add bags",
  "name-correction": "Name correction",
  "other-requests": "Other requests",
  "change-order": "Change order {orderName}",
  "premium-economy": "Premium Economy",
  "first-cabin": "First",
  "business-cabin": "Business",
  "search-available-flight": "Search available flights",
  "available-flight": "Available flights",
  "changes-summary": "Changes summary",
  "new-flight-details": "New Flight Details",
  "previous-flight-details": "Previous Flight Details",
  "payment-details": "Payment details",
  "before-change-amount": "Before change amount",
  "after-change-amount": "After change amount",
  description: "Description",
  "cost-of-change": "Cost of change",
  "confirm-and-pay-with-balance": "Confirm and pay with balance",
  "change-flight-confirm":
    "This will be charged. Please note - future changes to this order will require support assistance.",
  "confirm-payment-of": "Confirm payment of {currency} {amount}",
  "confirm-cancel-of":
    "If you cancel the flight now, We will return {currency} {refundPrice} to you. Do you confirm the cancellation ?",
  "will-not-be-refundable":
    "If you cancel the flight now, Will not be refundable to you. Do you confirm the cancellation ?",
  "departure-from": "Departure from",
  "arrive-at": "Arrive at",
  "flight-cancellation-successful": "Flight cancellation successful",
  "flight-cancellation-failure": "Flight cancellation failure",
  duration: "Duration",
  "order-change-departure-time": "Departure time(The local time shall prevail)",
  version: "Version",
  "flight-version-price": "Revision {index} fee ",
  "alteration-cost": "Alteration Cost",
  "flight-total-price": "Flight Total Price",
  "flight-fare-price": "Fare Total Price",
  "flight-alteration-price": "Alteration Total Cost",
  other: "Other",
  "sleep-num": "Up to {num} person (Charges are based on actual occupancy)",
  "sleep-num-range":
    " {min} - {max} person (Charges are based on actual occupancy)",
  "years-old": "years old",
  "supplier-terms-conditions": "Terms and Conditions",
  "eps-terms-and-conditions": "EPS's Terms and Conditions",
  "please-check-first": "Please check first!",
  "important-information": "Important information",
  "add-addition": "Add additional services",
  "additional-service-selected": "Additional services have been selected",
  baggage: "Baggage",
  "baggage-price": "Baggage price",
  "seat-price": "Seat price",
  "service-type": "Service name",
  time: "Time",
  change: "Change",
  add: "Add",
  "select-check-in-baggage": "Select check in baggage",
  "select-meal": "Select meal",
  seat: "Seat",
  "you-will-cancel-this-additional-service":
    "You will cancel this additional service",
  "successful-cancel": "Successful cancel",
  "optional-additional-services": "Optional additional services",
  "add-baggage": "Add baggage",
  "add-seat": "Add seat",
  "add-meal": "Add meal",
  "optional-baggage-service": "Optional baggage service",
  "optional-seat-service": "Optional seat service",
  "buy-now-and-save-at-least": "Buy now and SAVE at least 30%!",
  "segment-info": "Segment Info",
  "manage-this-reservation": "Manage this reservation",
  "change-reservation": "Change reservation {orderName}",
  "new-ticket-fee": "New ticket fee",
  "sure-about-the-modification": "Are you sure about the modification ?",
  "baggage-option-in-the-flight": "Baggage option in the flight",
  "advance-search": "Advance Search",
  arrival: "Arrival",
  "is-direct": "Is Direct",
  "departure-level": "Departure Level",
  "first-class": "First",
  "business-class": "Business",
  include: "Include",
  exclude: "Exclude",
  "pick-some": "Pick Some",
  "please-select-cabin-class": "Please select cabin class",
  "passengers-summary-and-cabin": " {passengersNum} Passenger(s),{cabinName} ",
  "promotion-code": "Promotion Code",
  yes: "Yes",
  no: "No",
  "language-code": "Language Code",
  "airfield-radius": "Airfield Radius",
  "flexibility-flight": "Flexibility",
  "required-attributes": "Required Attributes",
  "fare-basis-codes": "Fare Basis Codes",
  "metro-code": "Metro Code",
  "loyalty-num": "Loyalty Number",
  "max-num": "A maximum of {num} people can be selected",
  "loyalty-passengers-summary":
    "Loyalty Passengers : {adult} Adult(s) , {child} Child(s) , {infant} Infant(s) }",
  "tour-code": "Tour Code",
  "account-code": "Account Code",
  "please-select-vendor": "Please select vendor",
  "air-equip-type": "Air Equip Type",
  "fare-type": "Fare Type",
  account: "Account",
  "choose-frequent-flyer-airline": "Choose Frequent Flyer Airline",
  preferred: "Preferred",
  unacceptable: "Unacceptable",
  only: "Only",
  required: "Required",
  "tax-and-fees-description":
    "“This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for their taxes, and/or taxes that we pay, to taxing authorities on your booking (including but not limited to sales, occupancy, and value added tax). This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, the hotel supplier and/or the website you booked on, retain as part of the compensation for our and/or their services, which varies based on factors such as location, the amount, and how you booked. For more details, please see the Terms and Conditions.” ",
  "hotel-booking-overview": "Hotel Booking Overview",
  "operating-airline": "Operating Airline",
  "fare-basis-code": "Fare Basis Code",
  "book-class-code": "Book Class Code",
  "confirm-modify": "Confirm Modify",
  "passenger-name-modify-notice":
    "Fill in the passenger name to be modified and click Confirm",
  "non-baggage": "Non-baggage",
  "resort-fee-notice": "Exclusive in total, customer has to pay at hotel",
  "resort-fee": "Resort Fee",
  "edit-name": "Edit Name",
  "payment-now": "Payment Now",
  "you-will-pay-for-trip":
    'You will pay <span class="h5 text-danger"\n' +
    "                                  >{currency }\n" +
    "                                  { totalAmount}</span\n" +
    "                                >\n" +
    "                                by credit card for the trip",
  "confirm-to-change-to-your-current-name":
    "Do you confirm to change to your current name",
  "please-select-channel": "Please select channel",
  "please-select-air-equip-type": "Please select air equip type",
  "please-select-required-attributes": "Please select required attributes",
  including: "Including",
  excluding: "Excluding",
  "select-flight-you-want-change":
    "Please select the flight you want to modify !",
  "continue-modify": "Do you want to continue modifying ?",
  "supplier-fare-option": "Supplier fare options",
  "supplier-fare-option-num": "{num} supplier fare options",
  "enter-your-passport-optional": "Enter your passport (optional)",
  "expire-date-optional": "Expire date (optional)",
  "choose-flight-to": "Choose flight to {locationName}",
  "additional-service-charge": "Additional service charge",
  "reservation-have-been-changed":
    "  Some of your reservations have been changed by the airline and require  your action. Now you can",
  "view-affected-reservations": "View affected reservations",
  "needs-review-reservations": "Needs review Reservations",
  "needs-review": "Needs review",
  airline: "Airline",
  "issuing-date": "Issuing date",
  "my-trips": "My Trips",
  "new-details": "New details",
  "previous-details": "Previous details",
  summary: "Summary",
  "changes-detected": "Changes detected",
  "passenger-will-departure-from-to":
    "Passengers will depart from {from} {later} later , and arrive at {to} {later} later",
  "flight-to-location": "Flight to {location}",
  direct: "Direct",
  "no-direct": "No Direct",
  "flight-duration": "Flight duration",
  "request-cancellation": "Request cancellation",
  "request-change": "Request change",
  "issue-id": "Issue Id",
  "issue-type-id": "Issue Type Id",
  "info-source-id": "Info Source Id",
  "create-time": "Create time",
  "update-time": "Update time",
  "direct-ticketing": "Direct Ticketing",
  "re-price": "Reprice",
  "please-select-your-payment-method": "Please select your payment method",
  cash: "Cash",
  "credit-card": "Credit card",
  "directly-to-airline": "Directly to Airline",
  "order-reprice": "Order Reprice",
  "get-more-fare-options": "Get more fare options",
  segments: "Segments",
  "booking-date-start-to-end": "Booking date start to Booking date  end",
  received: "Received",
  "unknown-seat": "Unknown seat",
  "fare-rule-description": "Fare Rule Description",
  "issue-ticket": "Issue Ticket",
  "stored-fare": "Stored Fare",
  "base-fare": "Base Fare",
  "passenger-fare": "Passenger Fare",
  "total-fare": "Total Fare",
  "single-charge": "Single charge",
  "seat-details": "Seat Details",
  "accept-special-service-applications":
    "In order to facilitate the travel of more passengers, this flight can accept some special service applications. You can complete your application in a few simple steps",
  "edit-special-service": "Edit special service",
  "add-special-assistance": "Add special assistance",
  "assistance-option": "Assistance option",
  "no-assistance-option-in-the-segment": "No assistance option in the segment",
  "select-the-special-help-you-need": "Select the special help you need",
  "no-additional-assistance": "No additional assistance",
  "optional-assistance": "Optional assistance",
  "frequent-flyer-number-correction": "Frequent flyer number correction",
  "passenger-frequent-flyer-modify-notice":
    "Fill in the passenger Frequent Flyer number to be modified and click Confirm",
  "frequent-flyer-number": "Frequent flyer number",
  "edit-frequent-flyer-number": "Edit Frequent Flyer number",
  mx: "Mx.",
  "my-choice": "My choice",
  "passenger-choice": "Passenger choice",
  "your-seat": "Your seat",
  "filter-condition": "Filter condition",
  air: "Air",
  "car-transfer-shuttles": "Car Transfer and Shuttles",
  ferries: "Ferries",
  trip: "Trip",
  "trip-list": "Trip List",
  "record-locator": "Record Locator",
  "trip-status": "Trip Status",
  trips: "Trips",
  "trips-list": "Trips List",
  "all-trips": "All Trips",
  "trips-found": "Trips found",
  "create-new": "Create New",
  "reservations-notice": "Reservations Notice",
  welcome: "Welcome",
  "no-data": "No data",
  "air-reservation": "Air Reservation",
  "total-taxes": "Total Taxes",
  "taxes-name": "Taxes Name",
  "taxes-amount": "Taxes Amount",
  "add-entertainment": "Add Entertainment",
  "passenger-taxes": "Passenger Taxes",
  "order-change-departure-date": "Departure Date(The local time shall prevail)",
  "flight-search": "Flight Search",
  "air-shopping": "Air Shopping",
  "flight-itinerary": "Flight Itinerary",
  "flight-passenger": "Flight Passenger",
  "reservations-needing-review": "Reservations needing review",
  "held-reservations": "Held reservations",
  "view-all-reservations": "View all reservations",
  "affected-reservations": "Affected reservations",
  "ticket-orders": "Ticket Orders",
  female: "Female",
  male: "Male",
  unspecified: "Unspecified",
  "enter-redress-number-option": "Enter redress number (optional)",
  "enter-known-traveler-number-option":
    "Enter known traveler number (optional)",
  "stored-fares": "Stored Fares",
  "passenger-fares": "Passenger Fares",
  "view-fares": "View Fares",
  "equiv-fare": "Equiv Fare",
  "confirm-ticketing-now": "Confirm ticketing now ?",
  none: "None",
  fare: "Fare",
  ok: "OK",
  "submit-order": "Submit  Order",
  "search-order": "Search Order",
  "store-fare": "Store Fare",
  assistance: "Assistance",
  minor: "Minor",
  entertainment: "Entertainment",
  television: "Television",
  games: "Games",
  internet: "Internet",
  power: "Power",
  "entertainment-option": "Entertainment Option",
  "passport-information-correction": "Passport Information Correction",
  "passenger-passport-modify-notice":
    "Fill in the passenger Passport number to be modified and click Confirm",
  "edit-passport-number": "Edit Passport Number",
  "confirm-to-change-to-your-passport":
    "Do you confirm to change to your Passport",
  "confirm-to-change-to-your-frequent-flyer-number":
    "Do you confirm to change to your Frequent Flyer number",
  modify: "Modify",
  view: "View",
  edit: "Edit",
  "passport-expiry-time": "Passport Expiry Time",
  null: "Null",
  "redress-number": "Redress Number",
  "known-traveler-number": "Known Traveler Number",
  "add-phone-number": "Add Phone Number",
  "add-email-address": "Add Email Address",
  "loyalty-info": "Loyalty Info",
  "you-will-modify-name-now": "You will modify name now",
  "gender-correction": "Gender Correction",
  "passport-correction": "Passport Correction",
  "birth-date-correction": "Birthday Correction",
  "frequent-flyer-airline-correction": "Frequent Flyer Airline Correction",
  "redress-number-correction": "Redress Number Correction",
  "traveler-number-correction": "Traveler Number Correction",
  "phone-number-correction": "Phone Number Correction",
  "email-correction": "Email Correction",
  "held-orders": "Held Orders",
  "view-all-orders": "View all Orders",
  "selected-insurances": "Selected Insurances",
  "selected-equips": "Selected Equips",

  "add-passengers": "Add passengers",
  "equip-type": "Equip type",
  "days-before-departure": "Days before departure",
  "days-after-departure": "Days after departure",
  "time-before-departure": "Time before departure(h)",
  "time-after-departure": "Time after departure(h)",
  "fares-and-fees": "Fares And Fees",
  "add-passenger-type": "Add passenger type",
  "passenger-type-codes": "Passenger Type Codes(PTCs)",
  "add-account": "Add account",
  "loyalty-accounts": "Loyalty accounts",
  optional: "Optional",
  attributes: "Attributes",
  "fare-types": "Fare Types",
  "please-input-codes": "Please Input codes",
  "code-input": "Code input",
  "best-pricing-code": "Best pricing code",
  configuration: "Configuration",
  "contract-id": "Contract ID",
  channel: "Channel",
  "content-options": "Content options",
  "please-input-airlines": "Please input airlines",

  "air-shopping-uppercase": "AIR SHOPPING",
  "modify-flight": "Modify Flight",
  "fare-options": "Fare Options",
  "flight-to": "Flight to {locationCode}",
  "some-fare-options-for-you": "Here are some  fare options for you!",
  source: "Source",
  "booking-class": "Booking Class",
  loyalty: "loyalty",
  "fare-restrictions": "Fare Restrictions",
  services: "Services",
  vendor: "Vendor",
  "view-segments": "View Segments",
  suppliers: "Supplier(s)",
  sources: "Source(s)",
  "travel-and-baggage": "Travel And Baggage",
  "stopover-airport": "Stopover Airport",
  "please-enter": "Please Enter",
  "add-ancillary-service": "Add Ancillary Service",
  "additional-services-menu": "Additional services menu",
  "current-segment": "Current Segment",
  "current-passenger": "Current Passenger",
  "select-details": "Select Details",
  "current-selection-details": "Current selection details",
  "itinerary-info": "Itinerary Info",
  "show-more-options": "Show more options",
  "price-breakdown": "Price Breakdown",
  "view-price-breakdown": "View Price Breakdown",
  "view-price-summary": "View Price Summary",
  "description-uppercase": "DESCRIPTION",
  "deferred-payment": "Deferred payment",
  "requester-name": "Requester Name",
  "summary-uppercase": "SUMMARY",
  "quoted-fares": "Quoted  Fares",
  "price-quote-number": "Price Quote Number",
  "quote-number": "Quote Number",
  "fare-details": "Fare Details",
  delete: "Delete",
  "fare-quotes": "Fare Quotes",
  "new-fare-quote": "New Fare Quote",
  system: "System",
  "you-have-not-selected-fare":
    "You have not selected fare! Please select at least one.",
  "marketing-carrier": "Marketing carrier",
  "search-activities": "Search Activities",
  "please-enter-three-uppercase-code": "Please enter three uppercase code",
  "invalid-input": "Invalid input!",
  "ticketing-time-limit": "Ticketing Time Limit",
  "requested-ticketing-date": "Requested Ticketing Date",
  "change-itinerary": "Change Itinerary",
  "add-new-flight": "Add new flight",
  "reshop-uppercase": "RESHOP",
  "flight-details": "Flight details",
  "modify-success": " Modify success !",
  "ticket-numbers": "Ticket Numbers",
  "ticketing-date": "Ticketing date",
  "ticket-segments": "Ticket segments",
  "fare-type-code": "Fare type code",
  "jobs-panel": "Jobs Panel",
  job: "Job",
  "ancillary-service-selected": "Ancillary service selected",
  "see-descriptions": "See descriptions",
  "purchase-additional-service": "Purchase Additional Service",
  "per-unit": "per unit",
  "no-services": "No services",
  selection: "Selection",
  "no-selection": "No selection",
  "services-summary": "Services Summary",
  student: "Student",
  "purchase-ancillary-service": "Purchase ancillary service",
  "seat-selection": "Seat selection",
  "student-price": "Student Price",
  available: "Available",
  unavailable: "Unavailable",
  selected: "Selected",
  "seat-selected": "seat selected",
  "services-total": "Services Total",
  "ticket-service": "Ticket service",
  "taxes-uppercase": "TAXES",
  "continue-pay-services":
    "Do you want to continue pay {currencyCode} {amount} now?",
  "you-will-pay-for-services": "You will pay for services",
  "manage-ancillary-service": "Manage Ancillary Service",
  "trip-approval": "Trip Approval",
  "manage-seat-selection": "Manage Seat Selection",
  "price-expiration-date-time": "Price Expiration Date Time",
  "price-is-out-of-date": "Your price is out of date and needs to be reprice",
  "ticket-not-down": "Ticket Not Down",
  "confirm-delete-flight": "Are you sure to delete this flight ?",
  "ticket-info": "Ticket Info",
  "back-to-reservation": "Back to reservation",
  "back-to-submit-order": "Back to submit order",
  action: "Action",
  choose: "Choose",

  departure: "Departure",
  "filter-and-sort": "Filter and sort",
  stops: "Stops",
  "expire-date": "Expire date",
  "name-title": "Name Title ",
  "total-uppercase": "TOTAL",
  "you-will-confirm-the-reservation-now":
    "You will confirm the reservation now and pay later",
  "room-only": "Room only",
  "sure-print-voucher": "Are you sure to print voucher ?",

  "cancel-ticket": "Cancel Ticket",
  "you-will-cancel-this-ticket": "You will cancel this ticket",
  "you-have-not-selected-ticket":
    "You have not selected ticket! Please select at least one.",
  "void-ticket": "Void Ticket",
  "cancel-successful": "Cancel successfully!",
  evening: "Evening",
  "standard-search": "Standard search",
  "flexible-search": "Flexible search",
  "view-all": "View all",
  "hotel-list": "Hotel List",
  "hotel-num": "{hotelNum} hotel(s) found",
  "user-rating": "User Rating",
  "hotel-details": "Hotel Details",
  "cancel-policies": "Cancel Policies",
  "daily-rates": "Daily Rates",
  "base-amount": "Base Amount",
  "see-rate-comments": "See Rate  Comments",
  "room-amount": "Room Amount",
  admin: "Admin",
  departments: "Departments",
  locations: "Locations",
  employees: "Employees",
  "employment-positions": "Employment Positions",
  "travel-policies": "Travel Policies",
  "travel-rules": "Travel Rules",
  department: "Department",
  "party-from": "Party From",
  "party-to": "Party To",
  "employee-position": "Employee Position",
  comments: "Comments",
  "role-from": "Role From",
  "role-to": "Role To",
  "party-relationship-type": "Party Relationship Type",
  "role-type": "Role Type",
  notes: "Notes",
  enabled: "Enabled",
  "facility-name": "Facility Name",
  owner: "Owner",
  "create-new-employee": "Create New Employee",
  "please-enter-last-name": "Please Enter Last Name",
  "please-enter-first-name": "Please Enter First Name",
  "please-enter-email-address": "Please Enter Email Address",
  "confirm-password": "Confirm Password",
  "choose-a-save-one": "Choose a save one..",
  password: "Password",
  "total-lower-case": "total",
  "select-one": "Please select one",
  "baggage-allowance": "Baggage Allowance",
  "flight-list": "Flight List",
  "please-enter-your-valid-point": "Please enter your valid point",
  "min-price": "Min Price",
  "max-price": "Max Price",
  "please-enter-your-username": "Please enter your username",
  "please-enter-your-password": "Please enter your password",
  "change-language": "Change Language",
  "enter-your-username": "Enter your username",
  "enter-your-password": "Enter your password",
  "enter-your-first-name": "Enter your first name",
  "enter-your-last-name": "Enter your last name",
  "enter-your-email-address": "Enter your email address",
  "please-enter-price-range": "Please enter valid price range",
  "payment-time-is-expired": "Payment time is expired!",
  "this-is-an-error-message": "This is an error message",
  "this-is-an-success-message": "This is a success message",
  "this-is-a-warning-message": "This is a warning message",
  "terms-conditions": "Terms and Conditions",
  "fees-included-uppercase":
    "FEES <span class='font-14'>(included in base fare)</span>",
  "discount-uppercase": "DISCOUNT",
  promotion: "Promotion",
  "promo-code": "Promo Code",
  "fees-included": "Fees <span class='font-14'>(included in base fare)</span>",
  "flexibility-your-own-flying-experience":
    "We will bring you a new booking experience that lets you have it all Flexibility to customize your own flying experience",
  "unpaid-hotel-reservation-notice":
    "This reservation is an unpaid reservation. If the payment is not completed before <span class='font_weight '> {date}</span> (the time is subject to the local time of the hotel), the system will automatically cancel the reservation. Please complete the payment as soon as possible to retain the reservation",
  "basic-information": "Basic Information",
  "name-info": "Name",
  "date-of-birth": "Date of birth",
  "mobile-number": "Mobile Number",
  "times-are-based-on-local": "*Times are based on the property’s local time.",
  apply: "Apply",
  "my-additional-services": "My additional services",
  "my-selected-seats": "My selected seats",
  "segment-already-contains-the-same-type-additional-services":
    "This segment already contains the same type of additional services, please delete and add",
  "segment-already-contains-the-same-type-seat":
    "This segment already contains the same type of seat, please delete and add",
  "booking-cancelled-successfully": "Booking cancelled successfully",
  "fare-is-expired": "Fare is expired!",
  "please-enter-promo-code": "Please enter promo code !",
  "please-select": "Please select",
  "trying-to-check-please-wait-for-a-moment":
    "Trying to check, please wait for a moment!",
  "price-lowest": "Price(Lowest)",
  "price-highest": "Price(Highest)",
  "user-rating-lowest": "User Rating(Lowest)",
  "user-rating-highest": "User Rating(Highest)",
  "star-rating-lowest": "Star Rating(Lowest)",
  "star-rating-highest": "Star Rating(Highest)",
  "hotel-name-lowest": "Hotel Name(A-Z)",
  "hotel-name-highest": "Hotel Name(Z-A)",
  "input-hotel-name": "Input Hotel Name",
  breakfast: "Breakfast",
  "name-of-city-or-airport": "Name of city or airport",
  "duration-shortest": "Duration(Shortest)",
  "duration-longest": "Duration(Longest)",
  "departure-earliest": "Departure(Earliest)",
  "departure-latest": "Departure(Latest)",
  "arrival-earliest": "Arrival(Earliest)",
  "arrival-latest": "Arrival(Latest)",
  "early-morning": "Early morning",
  morning: "Morning",
  afternoon: "Afternoon",
  "the-booking-has-been-paid-and-is-waiting-to-be-issued":
    "The booking has been paid and is waiting to be issued",
  "number-stops": "{stopNumber} Stop(s)",
  "no-flights": "No Flights",
  "no-flight-in-origin-des":
    "No {originLocationName} - {destinationLocationName} on {month} {date}",
  "review--fare-to": "Review fare to  {locationCode}",
  loading: "Loading",
  "add-promo-code": "Add Promo Code",
  "this-promo-code-is-invalid": "This promo code is invalid !",
  "frequent-flyer-programme": "Frequent flyer programme",
  "all-programmes": "All Programmes",
  profile: "Profile",
  "multi-way": "Multi way",
  "user-name-and-password-mismatch": "User name and password mismatch",
  "travel-documents": "Travel Documents",
  save: "Save",
  select: "Select",
  "choose-the-loyalty-to-replace": "Choose the loyalty you want to replace",
  "transaction-fees": "Transaction Fees",
  "tour-activity": "Tour Activity",
  "activity-summary": "Activity Summary",
  "type-of-vehicle": "What type of vehicle?",
  "vans-trucks": "Vans & Trucks",
  "pick-return-location": "Pick up & Return location",
  "same-return-location": "Same return location",
  "pickup-and-return-time": "Pick up location & Time",
  "i-live-in": "I live in",
  "pick-up-location": "Pick up location",
  "return-location": "Return location",
  "living-country": "Living country",
  "vehicle-num": "{carRentalItemsLength} vehicle(s) found",
  "minimum-driving-age-years": "Minimum driving age : {minimumDriverAge}years",
  "driver-info": "Driver Info",
  "leaving-from": "Leaving from",
  "normal-rules": "Normal Rules",
  "structured-rules": "Structured Rules",
  "before-departure": "Before departure",
  "after-departure": "After departure",
  "change-fee": "Change fee",
  "cancellation-fee": "Cancellation fee",
  unchangeable: "Unchangeable",
  "mainland-travel-permit": "Mainland Travel Permit",
  "add-documents": "Add documents",
  "alert-message": "Alert Message",
  "place-of-birth": "Place of Birth",
  mobile: "Mobile",
  "hong-kong-identity-card": "Hong Kong Identity Card",
  "there-are-no-optional-rooms": "There are no optional rooms",
  "select-grade": "Select Grade",
  others: "Others",
  "you-do-not-need-upgrade-jump-next-step":
    "You don't need to upgrade, just jump to the next step",
  "select-upgrade-type": "Select Upgrade Type",
  "flying-hours": "Flying Hours",
  "hours-more-than": "Hours more than",
  city: "City",
  "with-condition-not-exceeding": "With condition not exceeding",
  "add-grade-policy": "Add Grade Policy",
  "modify-grade-policy": "Modify Grade Policy",
  telephone: "Telephone",
  visa: "Visa",
  "APEC-card": "APEC Card",
  "chinese-name": "Chinese Name",
  "no-programmes": "No Programmes",

  travellers: "Travellers",
  "traveller-selected": "{num} traveller(s) selected",
  "please-enter-to-select": "Please enter to select",
  "please-enter-to-remove": "Please enter to remove",
  "airline-seat-preferences": "Airline Seat Preferences",
  "airline-meal-preferences": "Airline Meal Preferences",

  "vendor-code": "Vendor Code",
  "program-id": "Program ID",
  "membership-id": "Membership ID",
  "issue-date": "Issue Date",
  "visa-entries": "Visa Entries",
  "single-visa": "Single",
  "multiple-visa": "Multiple",
  "visa-type": "Visa Type",
  "visa-no": "Visa No.",
  "APEC-card-no": "APEC Card No.",
  "seat-preference": "Seat Preference",
  "meal-preference": "Meal Preference",
  "preference-type": "Preference Type",
  preference: "Preference",
  "meal-type": "Meal Type",
  "group-division-name": "Group Division Name",
  "loyalty-level": "Loyalty Level",
  "customer-loyalty": "Customer Loyalty",
  "program-name": "Program Name",
  arranger: "Arranger",
  "approver-for": "Approver for",
  "arranger-for": "Arranger for",
  "username-or-email": "Username or Email",
  "brand-fares-only": "Brand Fares Only",
  "brand-fares": "Brand Fares",
  "best-price-only": "Best Price Only",
  "flexibility-fare-info": "Flexibility",
  free: "Free",
  "penalty-options": "Penalty Options",
  "operated-by": "Operated by",
  "currently-selected": "Currently Selected",
  "including-tax-uppercase": "INCLUDING TAX",
  "original-selection-including-tax": "ORIGINAL SELECTION INCLUDING TAX",
  "total-including-tax": "TOTAL INCLUDING TAX",
  "the-fare-is-out-of-policy": "The fare is out of policy",
  "order-histories": "Order histories",
  "car-rental-shopping": "Car Rental Shopping",

  "vehicle-name-lowest": "Vehicle Name(A-Z)",
  "vehicle-name-highest": "Vehicle Name(Z-A)",
  automatic: "Automatic",
  manual: "Manual",
  "protection-extra": "Protection & Extra",
  "create-order": "Create Order",
  "no-segments": "No segments",
  "no-passengers": "No passengers",
  "please-select-at-least-one-segment-passenger":
    "Please select at least one segment and one passenger !",
  "upsell-is-not-supported": "Upsell is not supported,Please change flight",
  "do-you-want-keep-issuing-tickets":
    "Do you want to keep issuing tickets or go to reservation details ?",
  "to-reservation": "To Reservation",
  "continue-issue-ticket": "Continue Issue Ticket",

  car: "Car",
  purchase: "Purchase",
  economy: "Economy",
  first: "First",
  business: "Business",
  "successfully-confirm": "Successfully confirm!",
  "this-trip-is-not-in-compliance":
    "This trip is not in compliance with the following travel rule(s):",
  "you-need-submit-your-travel-approval":
    "You need to submit your travel approval first",
  approver: "Approver",
  "valid-thru-date": "Valid Thru Date",
  "quote-remarks": "Quote Remarks",
  "application-reason": "Application Reason",
  "your-travel-approval-submitted": "Your Travel Approval is Submitted",
  "secure-flight-info": "Secure Flight Info",
  "change-information": "Change Information",
  "segment-already-contains-the-same-type-seat-should-modify":
    "This segment already contains the same type of seat and will now be modified to the currently selected seat",
  "you-will-change-your-info-now": "You will change  info now",
  "change-passenger-info": "Change passenger info",
  "change-person-name": "Change Person Name",
  "add-contact-info": "Add Contact Info",
  "employee-position-type": "Employee Position Type",
  "travel-policy": "Travel Policy",
  "switch-the-language": "Switch the language",
  CHECK_IN: "Check In",
  CHECK_OUT: "Check Out",
  FEES: "Fees",
  INSTRUCTION: "Instruction",
  SPECIAL_INSTRUCTIONS: "Special Instructions",
  MANDATORY: "Mandatory",
  OPTIONAL: "Optional",
  POLICY: "Policy",
  KNOW_BEFORE_YOU_GO: "Know Before You Go",
  roles: "Roles",
  "trip-id": "Trip ID",
  "trip-hotel-item-id": "Trip Hotel Item Id",
  "party-name": "Party Name",
  "party-roles": "Party Roles",
  "party-basic-info": "Party Basic Info",
  "party-type": "Party Type",
  "party-code": "Party Code",
  "group-company-name": "Group/Company Name",
  "legal-name": "Legal Name",
  "organization-unit-path": "Organization Unit Path",
  disabled: "Disabled",
  "party-role-id": "Party Role ID",
  "room-passengers": "Passengers",
  "approval-failure": "Approval failure !",
  "successful-approval": "Successful approval !",
  "user-login-type": "User Login Type",
  internal: "Internal",
  external: "External",
  "employment-position": "Employment Position",
  "send-notification-email": "Send Notification Email",
  "party-rules": "Party Rules",
  "new-employee": "New Employee",
  "you-will-add-this-employee": "You will now add this employee",
  id: "ID",
  "edit-employee-info": "Edit Employee Info",
  "you-will-modify-this-employee":
    "You will now modify this employee's information",
  username: "Username",
  "you-will-add-this-department": "You will now add this department",
  "you-will-modify-this-department":
    "You will now modify this department's information",
  "you-will-add-this-travel-policy": "You will now add this travel policy",
  "you-will-modify-this-travel-policy":
    "You will now modify this travel policy's information",
  "you-will-add-this-travel-rule": "You will now add this travel rule",
  "you-will-modify-this-travel-rule":
    "You will now modify this travel rule's information",
  "new-travel-rule": "New Travel Rule",
  "successfully-add": "Successfully add",
  "fail-to-add": "Fail to add",
  "password-enter-warning":
    "Please enter both upper and lower case letters, numbers, special characters (!{'@'}#%^&*?) Password of more than 8 characters",
  "confirm-password-enter-warning": "The entered password is inconsistent",
  "successfully-modify": "Successfully modify",
  "fail-to-modify": "Fail to modify",
  "you-will-add-this-employee-position":
    "You will now add this employee position type",
  "you-will-modify-this-employee-position":
    "You will now modify this employee position type's information",
  "ticketing-remarks": "Ticketing Remarks",
  "please-enter-english-remarks": "Please enter English remarks",
  "select-the-existing-ID-information-join":
    "Select the existing ID information to join",
  "you-will-add-document-now": "You will add this document now",
  "add-success": "successfully added !",
  "party-documents": "Party Documents",
  "document-type": "Document Type",
  "document-id": "Document ID",
  "party-document": "Party Document",
  "issue-authority": "Issue Authority",
  "holder-nationality": "Holder Nationality",
  "holder-type": "Holder Type",
  "holder-name": "Holder Name",
  "effective-date": "Effective Date",
  "issue-location": "Issue Location",
  "issue-state-province": "Issue State/Province",
  "issue-country": "Issue Country",
  "birth-country": "Birth Country",
  "birth-place": "Birth Place",
  "person-info": "Person Info",
  "contact-mechanisms": "Contact Mechanisms",
  relationships: "Relationships",
  purpose: "Purpose",
  "airline-code": "Airline Code",
  "travel-tips": "Travel tips",
  "travel-tips-content":
    "Room rates and general taxes are included in the order price, excluding city tax (Europe, Malaysia), resort fee, facility fee (North America), municipal fee (Dubai), Tourism tax (Malaysia), Sales and Services Tax (SST, Malaysia) and other charges levied by the hotel; Any additional expenses incurred by the guest at the hotel, such as additional service charges and fines for violations, shall be settled separately with the hotel.",
  "booking-instructions": "Booking instructions",
  "booking-instructions-content":
    '<div class="foont-weight font-18 text-warning"> Please read them carefully to avoid check-in problems</div>\n' +
    '<div class="font_weight mg-t-5"> Check-in and check-out requirements </div>\n' +
    "<div> Check-in time starts at 14:00</div>\n" +
    "<div> Check-out time noon </div>\n" +
    "<div> Minimum age for self-check-in 18</div>\n" +
    "<div> Check-in time ends at any time </div>\n" +
    "<div> Please use the first name Pinyin query (first name + last name) to book when staying in domestic hotels. </div>\n" +
    "<div> Please check in before the latest check-in time shown on the page. If there is no required latest check-in time, please check in before 22:00 on the first night. </div>\n" +
    '<div class="font_weight mg-t-5"> Special check-in instructions </div>\n' +
    "<div> This property hosts an airport pick-up service (additional charges may apply). Guests must contact the property reception with arrival details prior to travel, using the contact details found on the booking confirmation. Front desk staff will greet guests upon arrival. Upon check-in, cardholders must present the credit card used to make the reservation and a matching photo ID. Each guest is required to present a valid passport. The hotel only accepts such identification.\n" +
    "Late or late </div>\n" +
    "<div> If the order is delayed, please contact customer service in advance, otherwise the hotel may not inform the guest in any way, and directly cancel the entire order without refund. </div>\n" +
    '<div class="font_weight mg-t-5"> Other </div>\n' +
    "<div> Your special needs, the hotel will try to arrange according to the day of check-in, without any guarantee. </div>\n" +
    "<div> All orders beyond the latest free cancellation day will be considered as unmodifiable, if you have special circumstances, you can contact customer service for assistance. </div>\n" +
    "<div> Please apply for the invoice within 90 days after the order leaves the store, the invoice will not be issued after the deadline, thank you for your understanding. </div>",
  "please-enter-uppercase-code": "Please enter  uppercase code",
  "please-enter-number": "Please enter number",
  "customer-loyalties": "Customer Loyalties",
  "travel-preferences": "Travel Preferences",
  "you-will-add-this-data": "You will now add this data",
  "you-will-modify-this-data": "You will now modify this data",
  "airline-prefs": "Airline prefs",
  "out-of-policy": "Out of policy",
  "requires-approval": "Requires approval",
  "reprice-is-need": "Reprice is need!",
  "department-name": "Department Name",
  "score-increment": "Score increment",
  "recommends-lowest": "Recommends",
  "recommends-highest": "Recommends(Highest)",
  capt: "Capt.",
  dame: "Dame.",
  lady: "Lady.",
  lord: "Lord.",
  "the-rt-hon": "The Rt Hon.",
  rabbi: "Rabbi.",
  rev: "Rev.",
  sir: "Sir.",
  baroness: "Baroness.",
  baron: "Baron.",
  viscount: "Viscount.",
  viscountes: "Viscountes.",
  "associated-user-logins": "Associated User Logins",
  security: "Security",
  import: "Import",
  "fail-to-upload": "Fail to upload",
  "successfully-upload": "Successfully upload",
  "update-credits": "Update Credits",
  corporation: "Corporation",
  family: "Family",
  "modify-department": "Modify department",
  "you-will-delete-this-data": "You will delete this data",
  "employment-position-type": "Employment Position Type",
  "class-type": "Position Class Type",
  "quoteId-is-null": "Quote Id is null",
  "cancel-failed": "Cancel failed !",
  "cancel-successfully": "Cancel successfully !",
  "reject-failed": "Reject failed !",
  "reject-successfully": "Reject successfully !",
  "rule-name": "Rule Name",
  "input-param-enum": "Input Param Enum",
  "operator-enum-id": "Operator Enum Id",
  "travel-rule-condition": "Travel Rule Condition",
  "sequence-number": "Sequence Number",
  "param-enum": "Param Enum",
  operator: "Operator",
  "new-trip": "New trip",
  "trip-details": "Trip details",
  "import-pnr": "Import PNR",
  "company-logo": "Company Logo",
  "upload-logo": "Upload logo",
  "basic-profile": "Basic Profile",
  "telephone-numbers": "Telephone Numbers",
  "email-addresses": "Email Addresses",
  "membership-numbers": "Membership Numbers",
  "traveller-membership": "Traveller Membership",
  "traveller-preference": "Traveller Preference",
  approval: "Approval",
  "approval-required": "Approval Required",
  "require-reason-purpose-code": "Require Reason/Purpose Code",
  "who-is-travelling": "Who's travelling ?",
  "you-will-add-data-now": "You will add this data now",
  "you-will-modify-data-now": "You will modify this data now",
  "you-don't-have-permission-to-book": "You don't have permission to book",
  "please-select-optional": "Please select (optional)",
  reload: "Reload",
  "reload-success": "Reload successfully!",
  "insurance-mo": "Insurance(MO)",
  "ferries-mo": "Ferries(MO)",
  "holiday-package-mo": "Holiday Package(MO)",
  "dynamic-package-coming-soon": "Dynamic Package(Coming Soon)",
  "not-configured-yet": "Not configured yet",
  "delete-fare-quote": "Delete Fare Quote",
  "no-fares": "No fares",
  "please-select-at-least-one-segment": "Please select at least one segment !",
  "please-select-at-least-one-passenger":
    "Please select at least one passenger !",
  "you-will-delete-this-fare-quote": "you will delete this fare quote now",
  "this-type-document-already-exists":
    "This type of document already exists. Do not add it again",
  grades: "Grades",
  "employee-grade": "Employee Grade",
  "grade-code": "Grade code",
  "grade-name": "Grade name",
  "you-will-add-this-employee-grade": "You will add this employee grade",
  "you-will-modify-this-employee-grade": "You will modify this employee grade",
  grade: "Grade",
  "in-policy": "In policy",
  "the-preference-already-exists": "The preference already exists",
  unsuccessful: "Unsuccessful",
  "logging-in": "Logging in",
  "cabin-entitlement": "Cabin Entitlement",
  "hotel-entitlement": "Hotel Entitlement",
  "cabin-upgrade": "Cabin Upgrade",
  "upgrade-allowed-uppercase": "UPGRADE ALLOWED",
  "username-and-password-mismatch": "User name and password mismatch",
  traveller: "Traveller",
  "country-access-code": "Country Access Code",
  "you-will-add-this-party": "You will add this party",
  "you-will-modify-this-party": "You will modify this party",
  "whether-to-start-a-schedule-job": "Whether to start a schedule job",
  "successfully-import": "Successfully import",
  "fail-to-import": "Fail to import",
  "please-upload-the-correct-image-format":
    "Please upload the correct image format",
  "add-document": "Add Document",
  "no-travel-information": "No travel information",
  "country-code": "Country Code",
  "travel-breaks-some-rules": "Travel breaks some rules",
  "please-mark-in-fill-in-within-seconds-to-avoid-the-room being":
    "Please mark in <span class='text-danger'>{second} min {minutes} s</span> Fill in within seconds to avoid the room being booked out or the price changing",
  "room-rates-have-expired-please-re-search":
    "Room rates have expired, please re-search",
  country: "Country",
  "require-reason-purpose": "Require Reason/Purpose Code",
  connections: "Connections",
  "back-to-policy": "Back to travel policies",
  "dynamic-package": "Dynamic Package",
  "more-fare-options": "More fare options",
  "original-selection": "Original Selection",
  Passport: "Password",
  "pick-up-point": "Pick-up point",
  "drop-off-point": "Drop-off point",
  "seats-num": "seats",
  "or-similar-vehicle-class": "OR SIMILAR {className}",
  "the-model-is-subject-to-availability":
    "The model you get on your pick up day is subject to availability.",
  "basic-protection-included": "Basic protection included",
  "full-vehicle-details": "Full vehicle details",
  "basic-protection": "Basic Protection",
  "you-will-upgrade-protection-selecting-vehicle":
    "You will be able to upgrade your protection after selecting this vehicle",
  "unlimited-mileage": "Unlimited mileage",
  "car-details": "Car Details",
  "protection-plan": "Protection Plan",
  "packages-shopping": "Packages Shopping",
  added: "added",
  "who-is-flying": "Who's flying?",
  "contact-name": "Contact Name",
  "please-enter-passenger-information-first":
    "Please enter passenger information first",
  "select-a-guest": "Select a guest",
  "package-reservation": "Package Reservation",
  "original-net-price": "Original Net Price",
  "vat-included": "VAT Included",
  "please-select-at-least-one-passenger-first":
    "Please select at least one passenger first!",
  "about-us": "About Us",
  "car-rental": "Car Rental",
};
