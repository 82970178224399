<script setup>
import { computed, ref, watch } from "vue";
import { HotelAvailFormModel } from "@/formmodel/sales/hotel/availability/hotelAvailFormModel";
import dateUtils from "@/utils/dateUtils";
import FlatPickr from "vue-flatpickr-component";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import hotelContentApi from "@/apis/v2/content/hotelContentApi";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import hotelCommonTools from "@/tools/v2/hotel/common/hotelCommonTools";
import { useTemplateStore } from "@/stores/template";
import { useHotelAvailabilityStore } from "@/stores/v2/hotel/availability/hotelAvailabilityStore";
import HotelAvailRoomPassengerForm from "@/views/v2/sales/hotel/items/form/availability/HotelAvailRoomPassengerForm.vue";

const props = defineProps({
  jumboable: {
    type: Boolean,
  },
  jumpTo: {
    type: String,
    description: "route name to jump",
  },
  equipmentType: {},
  isFromHome: {},
});

const { t, locale } = useI18n(); // 解构调用函数
const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const hotelAvailabilityStore = useHotelAvailabilityStore();

//region 初始化数据
const suggestPlaceOptionsRef = ref([]);
const childAgesValidCheckRef = ref(false);
const hotelAvailFormModelRef = ref(
  new HotelAvailFormModel(
    true,
    locale.value,
    null,
    null,
    null,
    dateUtils.getOffsetDate(new Date(), 7),
    dateUtils.getOffsetDate(new Date(), 8),
    null
  )
);

hotelAvailFormModelRef.value.addNewRoomPassenger(1, 0, []);

const flatPickrStateRef = ref({
  // Initial values
  dateRange: [
    hotelAvailFormModelRef.value.checkInDate,
    hotelAvailFormModelRef.value.checkOutDate,
  ],
  configRange: {
    mode: "range",
    minDate: dateUtils.getOffsetDate(new Date(), 2),
    dateFormat: "Y-m-d",
    locale:
      locale.value === "zh_CN" ||
      locale.value === "zh_TW" ||
      locale.value === "zh_HK"
        ? "zh"
        : locale.value,
  },
});

const dropdownWidth = computed(() => {
  return props.equipmentType === "iphone" || props.equipmentType === "ipad"
    ? "90%"
    : "130%";
});
//endregion

//region 表单校验和提交
const rules = {
  locationCode: {
    required,
  },
  checkInDate: {
    required,
  },
  checkOutDate: {
    required,
  },
};
const v = useVuelidate(rules, hotelAvailFormModelRef);

async function submitHotelAvailFormModel(hotelAvailFormModel) {
  childAgesValidCheckRef.value = false;
  //在父组件中调用，则会校验所有子组件的规则，并统一返回校验结果
  const validateResult = await v.value.$validate();
  if (v.value.$errors && v.value.$errors.length > 0) {
    v.value.$errors.forEach((item) => {
      if (item != null && item.$property === "age") {
        childAgesValidCheckRef.value = true;
      }
    });
  }
  if (!validateResult) return;

  let formModelCopy = JSON.parse(JSON.stringify(hotelAvailFormModel));
  const shoppingQueryModel =
    hotelCommonTools.formatHotelAvailFormModel(formModelCopy);
  shoppingQueryModel.roomPassengersFormat =
    hotelCommonTools.formatRoomPassengers(formModelCopy.roomPassengers);
  shoppingQueryModel.isFromHome = props.isFromHome;
  if (props.jumboable) {
    router.push({
      name: props.jumpTo,
      query: shoppingQueryModel,
    });
  } else {
    router.replace({ query: shoppingQueryModel });
    searchHotelByFormModel(hotelAvailFormModel);
  }
}

//endregion

//#region query参数请求，进行搜索
if (!props.jumboable) {
  if (route.query != null && Object.keys(route.query).length > 0) {
    hotelAvailFormModelRef.value = hotelCommonTools.parseHotelAvailFormModel(
      route.query
    );
    flatPickrStateRef.value.dateRange = [
      hotelAvailFormModelRef.value.checkInDate,
      hotelAvailFormModelRef.value.checkOutDate,
    ];
    searchHotelByFormModel(hotelAvailFormModelRef.value);
  }
}

//#endregion

//region 语言改变，重新搜索
watch(
  () => locale.value,
  (newValue) => {
    hotelAvailFormModelRef.value.languageCode = newValue;
    const queryCopy = JSON.parse(JSON.stringify(route.query));
    if (queryCopy && queryCopy.languageCode) {
      queryCopy.languageCode = newValue;
      router.replace({ query: queryCopy });
    }

    flatPickrStateRef.value.configRange.locale =
      newValue === "zh_CN" || newValue === "zh_TW" || newValue === "zh_HK"
        ? "zh"
        : newValue;
  },
  { immediate: true }
);
//endregion

//region 表单相关函数
function suggestPlaces(keyword, locale) {
  hotelContentApi.hotelPlaces(keyword, locale).then((res) => {
    suggestPlaceOptionsRef.value = res.data;
    hotelAvailFormModelRef.value.locationCode = null;
  });
  document.getElementById("destination-dropdown-menu").classList.add("show");
}

function selectPlace(hotelSearchFormModel, suggestPlace, menuId) {
  if ("airport" === suggestPlace.type) {
    hotelSearchFormModel.locationCode = suggestPlace.airportCityCode;
    hotelSearchFormModel.locationType = "AIRPORT";
  } else if ("hotel" === suggestPlace.type) {
    hotelSearchFormModel.locationCode =
      suggestPlace.tticode || suggestPlace.ttiCode;
    hotelSearchFormModel.locationType = "HOTEL";
  } else {
    hotelSearchFormModel.locationCode = suggestPlace.placeId;
    hotelSearchFormModel.locationType = "PLACE";
  }
  hotelSearchFormModel.locationName = suggestPlace.name;
  document.getElementById(menuId).classList.remove("show");
}

function updateCheckInAndCheckOutDates(dates) {
  if (dates.length === 2) {
    const checkIn = dateUtils.format(dates[0]);
    const checkOut = dateUtils.format(dates[1]);
    hotelAvailFormModelRef.value.checkInDate = checkIn;
    hotelAvailFormModelRef.value.checkOutDate = checkOut;
  } else {
    hotelAvailFormModelRef.value.checkInDate = null;
    hotelAvailFormModelRef.value.checkOutDate = null;
  }
}

function formatPlaceType(type) {
  if (!type && !"" === type) return;
  let placeType = type.charAt(0).toUpperCase() + type.slice(1);
  return placeType.replaceAll("_", " ");
}

function getNights(checkInDate, checkOutDate) {
  return dateUtils.getDaysByDateRange(checkInDate, checkOutDate);
}

async function searchHotelByFormModel(formModel) {
  templateStore.pageLoader({ mode: "on" });
  //每次搜索之后存储表单
  sessionStorage.setItem("hotelAvailFormModel", JSON.stringify(formModel));
  let res = await hotelAvailabilityStore.hotelAvail(formModel);
  templateStore.pageLoader({ mode: "off" });
}

//endregion
</script>

<template>
  <div
    class="row"
    :class="{
      theme_search_form_area: props.jumpTo == null,
    }"
  >
    <div class="col-lg-12 col-12">
      <div class="tour_search_form">
        <form action="javascript:void(0);">
          <div class="row">
            <div class="col-lg-6 col-xl-3 col-md-12 col-sm-12 col-12">
              <div class="search_boxed">
                <p>{{ $t("destination") }}</p>
                <input
                  id="sales-hotel-destination"
                  v-model="hotelAvailFormModelRef.locationName"
                  :class="{
                    'is-invalid': v.locationCode.$errors.length > 0,
                  }"
                  @blur="v.locationCode.$touch"
                  aria-expanded="false"
                  aria-haspopup="true"
                  autocomplete="off"
                  data-bs-toggle="dropdown"
                  name="sales-hotel-destination"
                  :placeholder="$t('name-of-city-or-airport')"
                  type="text"
                  @input="(event) => suggestPlaces(event.target.value, locale)"
                />
                <span
                  id="whereGo"
                  class="text-danger"
                  v-if="v.locationCode.$errors.length > 0"
                  >{{ $t("please-enter-hotel-location") }}</span
                >
                <span id="whereGo" v-else
                  >{{ $t("where-are-you-going") }}?</span
                >

                <div
                  id="destination-dropdown-menu"
                  aria-labelledby="sales-hotel-destination"
                  class="dropdown-menu fs-sm full-width hotel_search_dropdown"
                  :style="{ width: dropdownWidth }"
                >
                  <div v-if="suggestPlaceOptionsRef.length > 0">
                    <a
                      v-for="(place, suggestIndex) in suggestPlaceOptionsRef"
                      :key="suggestIndex"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="
                        selectPlace(
                          hotelAvailFormModelRef,
                          place,
                          'destination-dropdown-menu'
                        )
                      "
                    >
                      <div class="row">
                        <div class="col-1">
                          <i
                            v-if="place.type === 'hotel'"
                            class="fa fa-hotel"
                          ></i>
                          <i
                            v-else-if="place.type === 'airport'"
                            class="fa fa-plane-departure"
                          ></i>
                          <i v-else class="fa fa-location-dot"></i>
                        </div>
                        <div class="col-11 ps-0">
                          {{ place.name }} ({{ formatPlaceType(place.type) }})
                          <p
                            v-if="place.type === 'hotel'"
                            class="text-muted me-0 margin-0"
                          >
                            {{ place.cityName }},{{ place.countryCode }}
                          </p>
                          <p
                            v-if="place.type === 'airport'"
                            class="text-muted me-0 margin-0"
                          >
                            {{ place.name }}({{ place.airportCode }}),{{
                              place.countryCode
                            }}
                          </p>
                          <p v-else class="text-muted me-0 margin-0">
                            {{ place.nameFull }},{{ place.countryCode }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div v-else>
                    <div class="dropdown-item">
                      <i class="fa fa-magnifying-glass me-3"></i>
                      {{ $t("search-by-destination") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-xl-5 col-md-12 col-sm-12 col-12">
              <div class="form_search_date">
                <div class="search_boxed">
                  <p>{{ $t("check-in-to-check-out") }}</p>
                  <FlatPickr
                    class="form-control"
                    name="flight-search-date"
                    placeholder="Y-m-d"
                    v-model="flatPickrStateRef.dateRange"
                    :class="{
                      'is-invalid':
                        v.checkInDate.$errors.length > 0 ||
                        v.checkOutDate.$errors.length > 0 ||
                        v.checkInDate.$model === v.checkOutDate.$model,
                    }"
                    :config="flatPickrStateRef.configRange"
                    @on-change="updateCheckInAndCheckOutDates"
                  />
                  <span class="mg-l-8"
                    >{{
                      getNights(
                        hotelAvailFormModelRef.checkInDate,
                        hotelAvailFormModelRef.checkOutDate
                      )
                    }}
                    {{ $t("night") }} (s)
                  </span>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 col-xl-4 col-md-12 col-sm-12 col-12 pad_search_passenger"
            >
              <HotelAvailRoomPassengerForm
                :child-ages-valid-check="childAgesValidCheckRef"
                :hotel-avail-form-model="hotelAvailFormModelRef"
                :equipment-type="equipmentType"
              />
            </div>

            <div class="top_form_search_button">
              <button
                class="btn btn_theme btn_md"
                @click="submitHotelAvailFormModel(hotelAvailFormModelRef)"
              >
                {{ $t("search") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
