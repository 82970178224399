<script setup>
import { useI18n } from "vue-i18n";
import { Modal } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { useTripStore } from "@/stores/v2/trip/tripStore";
import { ref, watch } from "vue";
import ActivityItinerarySummaryPanel from "@/views/v2/sales/activity/items/panel/ActivityItinerarySummaryPanel.vue";
import ReservationContactInfoPanel from "@/views/v2/trip/booking/commom/ReservationContactInfoPanel.vue";
import ActivityPassengerInfoPanel from "@/views/v2/sales/activity/items/panel/ActivityPassengerInfoPanel.vue";
import ActivityNecessaryInfoPanel from "@/views/v2/sales/activity/items/panel/ActivityNecessaryInfoPanel.vue";
import ActivityPriceSummaryPanel from "@/views/v2/sales/activity/items/panel/ActivityPriceSummaryPanel.vue";
import ReservationOperationPanel from "@/views/v2/trip/booking/commom/ReservationOperationPanel.vue";
import ReservationOverViewPanel from "@/views/v2/trip/booking/commom/ReservationOverViewPanel.vue";

const props = defineProps({
  tripId: {},
  isFromTripList: {},
  tripItemType: {},
});

const { t, locale } = useI18n(); // 解构调用函数

const AModal = Modal;
const router = useRouter();
const route = useRoute();
const emits = defineEmits();
const templateStore = useTemplateStore();
const tripStore = useTripStore();

const tripActivityItemRef = ref(null);
watch(
  () => tripStore.tripItems,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      const tripItem = newValue[0];
      if (tripItem && tripItem.tripActivityItems) {
        const tripActivityItems = tripItem.tripActivityItems;
        if (tripActivityItems && tripActivityItems.length > 0) {
          tripActivityItemRef.value = tripActivityItems[0];
        }
      }
    }
  },
  { immediate: true, deep: true }
);

function buildContactInfo(passengers) {
  if (passengers && passengers.length > 0) {
    return passengers.find((item) => item.phoneNumber);
  }
}

function syncSessionAndSearch() {
  emits("syncSessionAndSearch");
}
</script>

<template>
  <div v-if="tripActivityItemRef" class="row">
    <div class="col-10">
      <!-- booking manage -->
      <ReservationOperationPanel
        :booking-id="tripId"
        :trip-item-type="tripItemType"
        :booking-status="tripActivityItemRef.tripItemStatus"
        @syncSessionAndSearch="syncSessionAndSearch"
      />
      <!-- booking manage END-->

      <ReservationOverViewPanel
        :is-from-trip-list="isFromTripList"
        :trip-id="tripId"
        :trip-item-type="tripItemType"
        :booking-status="tripActivityItemRef.tripItemStatus"
        :created-date-time="tripActivityItemRef.createdDateTime"
      />

      <!-- tour Info -->
      <ActivityItinerarySummaryPanel
        :trip-activity-item="tripActivityItemRef"
      />
      <!-- tour Info END-->

      <!-- contact Info -->
      <ReservationContactInfoPanel
        :contact-info="buildContactInfo(tripActivityItemRef.passengers)"
      />
      <!-- contact Info END-->

      <!-- Passenger Info -->
      <ActivityPassengerInfoPanel
        :passengers="tripActivityItemRef.passengers"
      />
      <!-- Passenger Info END-->

      <!--   necessary info       -->
      <ActivityNecessaryInfoPanel
        :necessary-infos="tripActivityItemRef.necessaryInfos"
      />
      <!--    necessary info   END  -->

      <!-- Price Summary  Or Breakdown-->
      <ActivityPriceSummaryPanel
        :is-reservation="true"
        :trip-activity-item="tripActivityItemRef"
      />
      <!-- Price Summary  Or Breakdown END -->
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
