<script setup>
import { ref, watch, watchEffect } from "vue";
import { useTemplateStore } from "@/stores/template";
import { useRoute, useRouter } from "vue-router";
import FlightShoppingForm from "@/views/v2/sales/flight/items/form/shopping/FlightShoppingForm.vue";
import { useFlightShoppingStore } from "@/stores/v2/flight/shopping/flightShoppingStore";
import { useI18n } from "vue-i18n";
import { useFlightUpsellStore } from "@/stores/v2/flight/upsell/flightUpsellStore";
import FlightItemsAndUpsellOptionSelectPanel from "@/views/v2/sales/flight/items/panel/shopping/FlightItemsAndUpsellOptionSelectPanel.vue";
import FlightItemsAndFareOptionSelectPanel from "@/views/v2/sales/flight/items/panel/shopping/FlightItemsAndFareOptionSelectPanel.vue";
import { useCmsContentStore } from "@/stores/cms";

const { t, locale } = useI18n(); // 解构调用函数
let router = useRouter();
let route = useRoute();

const templateStore = useTemplateStore();
const flightUpsellStore = useFlightUpsellStore();
const flightShoppingStore = useFlightShoppingStore();

const flightShoppingInfoRef = ref(null);

const airSearchTypeRef = ref(null);

watch(
  () => route.query,
  (newValue) => {
    if (newValue) {
      airSearchTypeRef.value = newValue.airSearchType;
    } else {
      airSearchTypeRef.value = null;
    }
  },
  { immediate: true, deep: true }
);

watchEffect(() => {
  if (flightShoppingStore.flightShoppingFormModel) {
    flightShoppingInfoRef.value = JSON.parse(
      JSON.stringify(flightShoppingStore.flightShoppingFormModel)
    );
  } else if (flightUpsellStore.flightShoppingFormModel) {
    flightShoppingInfoRef.value = JSON.parse(
      JSON.stringify(flightUpsellStore.flightShoppingFormModel)
    );
  }

  if (
    flightShoppingInfoRef.value &&
    flightShoppingInfoRef.value.passengers &&
    flightShoppingInfoRef.value.passengers.length > 0
  ) {
    let passengers = [];

    if (templateStore.activeCBT) {
      passengers = [];
      let adultNum = flightShoppingInfoRef.value.passengers.filter(
        (item) => item.passengerTypeCode === "ADT"
      ).length;
      passengers.push({ quantity: adultNum, passengerTypeCode: "ADT" });
    } else {
      flightShoppingInfoRef.value.passengers.forEach((item) => {
        const quantity = item.quantity;
        const passengerTypeCode = item.passengerTypeCode;
        passengers.push({ quantity, passengerTypeCode });
      });
    }

    flightShoppingInfoRef.value.passengers = passengers;
  }
});

//region 初始化表单对象

//region 设备区分
const equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
//endregion

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.FlightBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.FlightBackgroundImage.url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("flight-list") }}</h2>
        <ul>
          <li
            class="pre-page"
            v-if="route.query && route.query.isFromHome"
            @click="router.push({ name: 'home' })"
          >
            {{ $t("home") }}
          </li>
          <li
            v-else
            class="pre-page"
            @click="router.push({ name: 'sales-flight-home-v2' })"
          >
            {{ $t("flight") }}
          </li>
          <li>
            <i class="fas fa-circle"></i>
            {{ $t("flight-list") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <!-- Form Area -->
  <section class="fligth_top_search_main_form_wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_search_form_area">
            <FlightShoppingForm
              :jumboable="false"
              :equipment-type="equipmentType"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Flight Search Areas -->

  <!--机票列表 + Fare options中心区域显示-->
  <FlightItemsAndUpsellOptionSelectPanel v-if="airSearchTypeRef === 'UPSELL'" />
  <FlightItemsAndFareOptionSelectPanel v-else />
  <!--机票列表 + Fare options中心区域显示END-->
</template>

<style scoped></style>
