<script setup>
import { ref, watch } from "vue";
import { useCmsContentStore } from "@/stores/cms";
import { useTemplateStore } from "@/stores/template";
import Banner from "@/components/templates/home/Banner.vue";
import Partners from "@/components/templates/home/Partners.vue";
import Imagination from "@/components/templates/home/Imagination.vue";
import HotelAvailForm from "@/views/v2/sales/hotel/items/form/availability/HotelAvailForm.vue";
import HotelExploreDeals from "@/views/sales/hotel/items/panel/hotelExploreDeals.vue";

const templateStore = useTemplateStore();

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getHotelHeaderContent();
cmsContentStore.getHotelIdealExperiences();
cmsContentStore.getHotelPromotionPrimaryDeals();
cmsContentStore.getPromotionManagements();
cmsContentStore.getOurPartner();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.hotelHeaderContent,
  (newValue) => {
    console.log(newValue);
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgroundImage) {
      const backgoundImage = newValue.backgroundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

//region 设备识别
const equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
//endregion 设备识别
</script>

<template>
  <!--   Banner Area -->
  <Banner
    :background-image-url="backgroundImageUrlRef"
    :header-content="cmsContentStore.hotelHeaderContent"
  />

  <section id="theme_search_form">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_search_form_area">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="hotels"
                role="tabpanel"
                aria-labelledby="hotels-tab"
              >
                <HotelAvailForm
                  :equipment-type="equipmentType"
                  :jumboable="true"
                  :jump-to="'sales-hotel-list-v2'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Imagination
    :promotion-deals="cmsContentStore.hotelIdealExperiences"
    v-if="
      cmsContentStore.promotionManagement
        ? cmsContentStore.promotionManagement.showHotelPageIdealExperience
        : ''
    "
  />

  <hotel-explore-deals
    v-if="
      cmsContentStore.promotionManagement
        ? cmsContentStore.promotionManagement.showHotelPageHotPromotion
        : ''
    "
    :promotion-hotels="cmsContentStore.hotelPromotionPrimaryDeals"
    :show-hotel-promotion-user-rating="
      cmsContentStore.promotionManagement
        ? cmsContentStore.promotionManagement.ShowHotelPromotionUserRating
        : ''
    "
  />

  <!-- Our partners Area -->
  <div v-if="cmsContentStore.contentManagement">
    <Partners
      :our-partners="cmsContentStore.ourPartners"
      v-if="cmsContentStore.contentManagement.showOurPartnersContent"
    />
  </div>
</template>

<style scoped></style>
