<script setup>
import { ref, watch } from "vue";
import { useProfileStore } from "@/stores/profile";
import { useTemplateStore } from "@/stores/template";
import { useVuelidate } from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import CustomerProfileBasicInformationModifyForm from "@/views/profiles/items/form/profile/CustomerProfileBasicInformationModifyForm.vue";
import { Modal } from "ant-design-vue";
import CustomerProfileContactModifyForm from "@/views/profiles/items/form/profile/CustomerProfileContactModifyForm.vue";
import CustomerProfileAirportSecurityModifyForm from "@/views/profiles/items/form/profile/CustomerProfileAirportSecurityModifyForm.vue";
import CustomerProfileTravelDocumentModifyForm from "@/views/profiles/items/form/profile/CustomerProfileTravelDocumentModifyForm.vue";
import CustomerProfileFlightPreferenceModifyForm from "@/views/profiles/items/form/profile/CustomerProfileFlightPreferenceModifyForm.vue";
import CustomerProfileMembershipModifyForm from "@/views/profiles/items/form/profile/CustomerProfileMembershipModifyForm.vue";
import CustomerProfileAdditionalTravellerModifyPannel from "@/views/profiles/items/panel/CustomerProfileAdditionalTravellerModifyPannel.vue";
import commonUtils from "@/utils/commonUtils";

const props = defineProps({
  profileInfo: {},
  documentTypes: {},
  userProfileFormModal: {},
});

const { t } = useI18n();
const AModal = Modal;
const emits = defineEmits([]);
const user = JSON.parse(sessionStorage.getItem("user"));
const profileStore = useProfileStore();
const templateStore = useTemplateStore();

const modalOfFormRef = ref({
  basicInfo: false,
  contact: false,
  airportSecurity: false,
  travelDocument: false,
  flightPreference: false,
  membership: false,
  additionalTraveller: false,
});

const userProfileFormModalRef = ref(null);

watch(
  () => props.userProfileFormModal,
  (newValue) => {
    userProfileFormModalRef.value = newValue;
  },
  { immediate: true, deep: true }
);

function openOrCloseModal(type, isOpen) {
  switch (type) {
    case "BASICINFO":
      modalOfFormRef.value.basicInfo = isOpen;
      break;
    case "CONTACT":
      modalOfFormRef.value.contact = isOpen;
      break;
    case "AIRPORTSECURITY":
      modalOfFormRef.value.airportSecurity = isOpen;
      break;
    case "TRAVELDOCUMENT":
      modalOfFormRef.value.travelDocument = isOpen;
      break;
    case "FLIGHTPREFERENCE":
      modalOfFormRef.value.flightPreference = isOpen;
      break;
    case "MEMBERSHIP":
      modalOfFormRef.value.membership = isOpen;
      break;
    case "ADDITIONALTRAVELLER":
      modalOfFormRef.value.additionalTraveller = isOpen;
      break;
  }
}

const v = useVuelidate();

async function modifyBasicInformation(profileFormModel, type) {
  const result = await v.value.$validate();
  if (!result) return;

  openOrCloseModal(type, false);
  templateStore.pageLoader({ mode: "on" });
  profileStore.modifyBasicInformation(profileFormModel).then((res) => {
    if (res && !res.success) {
      const error = res.errors.errors[0].stringValue;
      commonUtils.openToastBox("ERROR", t("this-is-an-error-message"), error);
    } else {
      commonUtils.openToastBox(
        "SUCCESS",
        t("this-is-an-success-message"),
        "Modified successfully!",
        function () {
          emits("loadUserProfile");
        }
      );
    }

    templateStore.pageLoader({ mode: "off" });
  });
}

async function modifyContact(profileFormModel, type) {
  const result = await v.value.$validate();
  if (!result) return;

  openOrCloseModal(type, false);
  templateStore.pageLoader({ mode: "on" });
  profileStore.modifyContact(profileFormModel).then((res) => {
    commonUtils.openToastBox(
      "SUCCESS",
      t("this-is-an-success-message"),
      "Modified successfully!",
      function () {
        emits("loadUserProfile");
      }
    );

    templateStore.pageLoader({ mode: "off" });
  });
}

async function modifyAirportSecurity(profileFormModel, type) {
  const result = await v.value.$validate();
  if (!result) return;

  openOrCloseModal(type, false);
  templateStore.pageLoader({ mode: "on" });

  profileStore.modifyAirportSecurity(profileFormModel).then((res) => {
    commonUtils.openToastBox(
      "SUCCESS",
      t("this-is-an-success-message"),
      "Modified successfully!",
      function () {
        emits("loadUserProfile");
      }
    );
    templateStore.pageLoader({ mode: "off" });
  });
}

async function modifyTravelDocuments(profileFormModel, type) {
  const result = await v.value.$validate();
  if (!result) return;

  openOrCloseModal(type, false);
  templateStore.pageLoader({ mode: "on" });
  profileStore.modifyTravelDocuments(profileFormModel).then((res) => {
    commonUtils.openToastBox(
      "SUCCESS",
      t("this-is-an-success-message"),
      "Modified successfully!",
      function () {
        emits("loadUserProfile");
      }
    );
    templateStore.pageLoader({ mode: "off" });
  });
}

async function modifyFlightPreference(profileFormModel, type) {
  const result = await v.value.$validate();
  if (!result) return;

  openOrCloseModal(type, false);
  templateStore.pageLoader({ mode: "on" });
  profileStore.modifyFlightPreference(profileFormModel).then((res) => {
    commonUtils.openToastBox(
      "SUCCESS",
      t("this-is-an-success-message"),
      "Modified successfully!",
      function () {
        emits("loadUserProfile");
      }
    );
    templateStore.pageLoader({ mode: "off" });
  });
}

async function modifyMemberships(profileFormModel, type) {
  const result = await v.value.$validate();
  if (!result) return;

  openOrCloseModal(type, false);
  templateStore.pageLoader({ mode: "on" });
  profileStore.modifyMemberships(profileFormModel).then((res) => {
    commonUtils.openToastBox(
      "SUCCESS",
      t("this-is-an-success-message"),
      "Modified successfully!",
      function () {
        emits("loadUserProfile");
      }
    );
    templateStore.pageLoader({ mode: "off" });
  });
}
</script>

<template>
  <div v-if="profileStore.profileViewItem">
    <!--    basic information-->
    <div class="row">
      <div class="col-10">
        <div class="font_weight font-24">
          {{ $t("basic-information") }}
        </div>
      </div>
      <div class="col-2 cursor-point">
        <a class="link-primary" @click="openOrCloseModal('BASICINFO', true)">{{
          $t("edit")
        }}</a>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12 mg-b-10">
            Make sure this information matches your travel ID,like your passport
            or license.
          </div>
          <div class="col-5">
            <div class="font_weight">{{ $t("name-title") }}</div>
            <div class="text-muted">
              {{
                profileInfo.nameTitle ? profileInfo.nameTitle : "Not provided"
              }}
            </div>
          </div>
          <div class="col-5">
            <div class="font_weight">{{ $t("gender") }}</div>
            <div class="text-muted">
              {{ profileInfo.gender ? profileInfo.gender : "Not provided" }}
            </div>
          </div>
          <div class="col-5">
            <div class="font_weight">{{ $t("name-info") }}</div>
            <div class="text-muted">
              {{ profileInfo.surname }}/{{ profileInfo.givenName }}
            </div>
          </div>
          <div class="col-5">
            <div class="font_weight">{{ $t("date-of-birth") }}</div>
            <div class="text-muted">
              {{
                profileInfo.birthDate ? profileInfo.birthDate : "Not provided"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    basic information end-->

    <!--    contact-->
    <div class="row mg-t-20">
      <div class="col-10">
        <div class="font_weight font-24 mg-b-20">
          {{ $t("contact") }}
        </div>
      </div>
      <div class="col-2 cursor-point">
        <a class="link-primary" @click="openOrCloseModal('CONTACT', true)">{{
          $t("edit")
        }}</a>
      </div>
      <div class="col-12 mg-b-10">
        Receive account activity alerts and trip updates by sharing this
        information.
      </div>
      <div class="col-5">
        <div class="font_weight">{{ $t("mobile-number") }}</div>
        <div class="text-muted">
          {{
            profileInfo.mobileNumber
              ? "+" +
                profileInfo.mobileCountryAccessCode +
                " " +
                profileInfo.mobileNumber
              : "Not provided"
          }}
        </div>
      </div>
      <div class="col-5">
        <div class="font_weight">{{ $t("email") }}</div>
        <div class="text-muted">
          {{
            profileInfo.emailAddress ? profileInfo.emailAddress : "Not provided"
          }}
        </div>
      </div>
      <div class="col-5">
        <div class="font_weight">Emergency contact</div>
        <div class="text-muted">
          {{
            profileInfo.emergencyNumber
              ? "+" +
                profileInfo.emergencyCountryAccessCode +
                " " +
                profileInfo.emergencyNumber
              : "Not provided"
          }}
        </div>
      </div>

      <div class="col-5">
        <div class="font_weight">Address</div>
        <div class="text-muted">
          {{
            profileInfo.country ||
            profileInfo.province ||
            profileInfo.city ||
            profileInfo.region ||
            profileInfo.postCode
              ? profileInfo.country +
                " " +
                profileInfo.province +
                " " +
                profileInfo.city +
                " " +
                profileInfo.region +
                " " +
                profileInfo.postCode
              : "Not provided"
          }}
        </div>
      </div>
    </div>
    <!--    contact info end-->

    <div class="row mg-t-20">
      <div class="col-6">
        <div class="font_weight font-24">
          {{ $t("more-details") }}
        </div>
        <div>
          Speed up your booking by securely saving essential travel details.
        </div>
        <div class="mg-t-10">
          <button
            class="btn btn-secondary"
            style="width: 80%"
            @click="openOrCloseModal('AIRPORTSECURITY', true)"
          >
            <div class="text-start">
              <div>Airport security</div>
              <div class="font-14">TSA PreCheck and Redress number</div>
            </div>
          </button>
        </div>
        <div class="mg-t-10">
          <button
            class="btn btn-secondary"
            style="width: 80%"
            @click="openOrCloseModal('TRAVELDOCUMENT', true)"
          >
            <div class="text-start">
              <div>Travel Documents</div>
              <div class="font-14">Passport</div>
            </div>
          </button>
        </div>
        <div class="mg-t-10">
          <button
            class="btn btn-secondary"
            style="width: 80%"
            @click="openOrCloseModal('FLIGHTPREFERENCE', true)"
          >
            <div class="text-start">
              <div>Flight preferences</div>
              <div class="font-14">Seat preference and home airport</div>
            </div>
          </button>
        </div>
        <div class="mg-t-10">
          <button
            class="btn btn-secondary"
            style="width: 80%"
            @click="openOrCloseModal('MEMBERSHIP', true)"
          >
            <div class="text-start">
              <div>Reward programs</div>
              <div class="font-14">Frequent flyer and membership programs</div>
            </div>
          </button>
        </div>
      </div>
      <div class="col-6">
        <div class="font_weight font-24">Additional travellers</div>
        <div>
          Make booking a breeze by saving profiles or family,friends,or
          teammates who often travel with you.
        </div>
        <div
          class="mg-b-10"
          style="display: flex; flex-direction: column"
          v-if="
            profileStore.profileViewItems &&
            profileStore.profileViewItems.length > 0
          "
        >
          <a
            class="link-primary cursor-point"
            href="javascript:void(0)"
            v-for="(traveller, travellerIndex) in commonUtils.sortByParams(
              profileStore.profileViewItems,
              'surname'
            )"
            :key="travellerIndex"
            >{{ traveller.surname }} {{ traveller.givenName }}</a
          >
        </div>
        <div class="mg-t-10">
          <button
            class="btn btn-secondary"
            style="width: 80%"
            @click="openOrCloseModal('ADDITIONALTRAVELLER', true)"
          >
            Add additional traveller
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--  add/edit basic info-->
  <a-modal
    v-model:open="modalOfFormRef.basicInfo"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
    :maskClosable="false"
    width="50%"
    z-index="22222"
  >
    <CustomerProfileBasicInformationModifyForm
      :user-profile-form-modal="userProfileFormModalRef"
      @modifyBasicInformation="modifyBasicInformation"
    />
  </a-modal>
  <!-- add/edit basic info END-->

  <!--  add/edit contact-->
  <a-modal
    v-model:open="modalOfFormRef.contact"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
    :maskClosable="false"
    width="40%"
    z-index="22222"
  >
    <CustomerProfileContactModifyForm
      :user-profile-form-modal="userProfileFormModalRef"
      @modifyContact="modifyContact"
    />
  </a-modal>
  <!-- add/edit contact END-->

  <!--  add/edit airport security-->
  <a-modal
    v-model:open="modalOfFormRef.airportSecurity"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
    :maskClosable="false"
    width="40%"
    z-index="22222"
  >
    <CustomerProfileAirportSecurityModifyForm
      :user-profile-form-modal="userProfileFormModalRef"
      @modifyAirportSecurity="modifyAirportSecurity"
    />
  </a-modal>
  <!-- add/edit airport security END-->

  <!--  add/edit travel document-->
  <a-modal
    v-model:open="modalOfFormRef.travelDocument"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
    :maskClosable="false"
    width="40%"
    z-index="22222"
  >
    <CustomerProfileTravelDocumentModifyForm
      :user-profile-form-modal="userProfileFormModalRef"
      @modifyTravelDocuments="modifyTravelDocuments"
    />
  </a-modal>
  <!-- add/edit travel document END-->

  <!--  add/edit flight preference-->
  <a-modal
    v-model:open="modalOfFormRef.flightPreference"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
    :maskClosable="false"
    width="40%"
    z-index="22222"
  >
    <CustomerProfileFlightPreferenceModifyForm
      :user-profile-form-modal="userProfileFormModalRef"
      @modifyFlightPreference="modifyFlightPreference"
    />
  </a-modal>
  <!-- add/edit  flight preference END-->

  <!--  add/edit membership-->
  <a-modal
    v-model:open="modalOfFormRef.membership"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
    :maskClosable="false"
    width="40%"
    z-index="22222"
  >
    <CustomerProfileMembershipModifyForm
      :user-profile-form-modal="userProfileFormModalRef"
      @modifyMemberships="modifyMemberships"
    />
  </a-modal>
  <!-- add/edit  membership END-->

  <!--  add/edit additional traveller-->
  <a-modal
    v-model:open="modalOfFormRef.additionalTraveller"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
    :maskClosable="false"
    width="100%"
    wrapClassName="ancillary-full-modal"
    z-index="22222"
  >
    <CustomerProfileAdditionalTravellerModifyPannel
      :profile-info="profileInfo"
    />
  </a-modal>
  <!-- add/edit  additional traveller END-->
</template>

<style scoped></style>
