<script setup>
import { ref, watch } from "vue";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import FlatPickr from "vue-flatpickr-component";
import { useI18n } from "vue-i18n";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  documentItem: {},
  passportIndex: {},
  passportNum: {},
});

const { t } = useI18n();

const emits = defineEmits();
const documentFormModelRef = ref(null);

watch(
  () => props.documentItem,
  (newValue) => {
    documentFormModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);

let rules = {
  docID: { required },
  expireDate: { required },
};

const v = useVuelidate(rules, documentFormModelRef);

//region 护照

function passportCountryChange(value) {
  documentFormModelRef.value.docIssueCountry = value.iso2;
  documentFormModelRef.value.docHolderNationality = value.iso2;
}

function passportNumberInput(value, event) {
  if (typeof value !== "string") return;
  documentFormModelRef.value.docID = value;
  if (event && event.country) {
    documentFormModelRef.value.docIssueCountry = event.country.iso2;
    documentFormModelRef.value.docHolderNationality = event.country.iso2;
  }
}

//endregion 护照

function addDocument() {
  emits("addDocument");
}

function removeDocument(index, partyDocumentId) {
  emits("removeDocument", index, partyDocumentId);
}
</script>

<template>
  <div class="col-5">
    <label class="form-label" for="example-text-input"
      >{{ $t("passport-number") }}
    </label>
    <VueTelInput
      :value="documentFormModelRef.docID"
      :class="{
        'is-invalid': v.docID.$errors.length,
      }"
      :autoFormat="false"
      :dropdownOptions="{
        showDialCodeInList: false,
        showDialCodeInSelection: false,
        showFlags: true,
      }"
      :inputOptions="{
        placeholder: $t('enter-your-passport'),
        autocomplete: false,
      }"
      autocomplete="off"
      class="form-control flex"
      mode="international"
      @input="passportNumberInput"
      @country-changed="passportCountryChange"
    />
  </div>
  <div class="col-5">
    <label class="form-label" for="example-text-input"
      >{{ $t("expire-date") }}
    </label>
    <FlatPickr
      id="example-flatpickr-default"
      v-model="documentFormModelRef.expireDate"
      :class="{
        'is-invalid': v.expireDate.$errors.length,
      }"
      :config="{
        minDate: 'today',
      }"
      :placeholder="$t('expire-date')"
      class="form-control"
    />
  </div>
  <div
    class="col-2"
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="mg-t-20 cursor-point text-primary" @click="addDocument">
      Add
    </div>
    <div
      class="mg-t-20 cursor-point text-danger mg-l-8"
      v-if="passportNum > 1"
      @click="
        removeDocument(passportIndex, documentFormModelRef.partyDocumentId)
      "
    >
      {{ $t("remove") }}
    </div>
  </div>
</template>

<style lang="scss">
.vue-tel-input {
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #dfe3ea;
  text-align: left;
}
</style>
