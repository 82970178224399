<script setup>
import { onMounted, ref, watch } from "vue";
import { useTemplateStore } from "@/stores/template";
import { FlightShoppingSortFormModel } from "@/formmodel/sales/flight/shopping/flightShoppingSortFormModel";
import { FlightShoppingFilterFormModel } from "@/formmodel/sales/flight/shopping/flightShoppingFilterFormModel";
import flightSortTools from "@/tools/v2/flight/shopping/flightSortTools";
import flightFilterTools from "@/tools/v2/flight/shopping/flightFilterTools";
import { useRoute, useRouter } from "vue-router";
import { Drawer, Empty, Spin } from "ant-design-vue";

import { useAirContentStore } from "@/stores/v2/content/airContentStore";
import { useI18n } from "vue-i18n";

import dateUtils from "@/utils/dateUtils";

import FlightShoppingFilterAndSortForm from "@/views/v2/sales/flight/items/form/shopping/FlightShoppingFilterAndSortForm.vue";
import BaseFlightListMobileItem from "@/views/v2/sales/flight/items/group/BaseFlightListMobileItem.vue";
import FlightFareOptionsSelectPanel from "@/views/v2/sales/flight/items/panel/shopping/FlightFareOptionsSelectPanel.vue";
import { useFlightUpsellStore } from "@/stores/v2/flight/upsell/flightUpsellStore";
import flightCommonTools from "@/tools/v2/flight/common/flightCommonTools";
import BaseFlightListItem from "@/views/v2/sales/flight/items/group/BaseFlightListItem.vue";

const props = defineProps({
  equipmentType: {},
});
const route = useRoute();
const router = useRouter();

const ADrawer = Drawer;
const AEmpty = Empty;
const { t, locale } = useI18n(); // 解构调用函数
const templateStore = useTemplateStore();
const flightUpsellStore = useFlightUpsellStore();
const airContentStore = useAirContentStore();

airContentStore.findAircraftsMap();
airContentStore.findAirlineMap();

const fareOptionShowRef = ref(false);

const flightItemsShowRef = ref(null);
const flightItemsShowLengthRef = ref(null);

const itineraryArrangementRef = ref(null);

const airlineItemMapRef = ref(null);
const aircraftItemMapRef = ref(null);
const airportCityMapRef = ref(null);

//过滤排序相关
const shoppingSortFormModelRef = ref(
  new FlightShoppingSortFormModel("PRICE", "ASC")
);
const shoppingFilterFormModelRef = ref(
  new FlightShoppingFilterFormModel([], [], [], [], [], [], [])
);
const filterOptionsRef = ref(null);
const combineKeys = ref([]); //更新flightItemsShowRef相关
const flightGroupInfosRef = ref([]);
const activeFlightGroupNumberRef = ref(
  route.query.tripSequenceNumber ? parseInt(route.query.tripSequenceNumber) : 1
); //记录当前行程序号
const preFareOptionPriceRef = ref(route.query.preFareOptionPrice);
const fareKeyRef = ref(null);
const specificFlightFareOptionsRef = ref(null);
const fareOptionsSelectedRef = ref([]);

if (sessionStorage.getItem("flightShoppingFormModel")) {
  const flightShoppingFormModel = JSON.parse(
    sessionStorage.getItem("flightShoppingFormModel")
  );
  flightUpsellStore.saveItineraryArrangement(flightShoppingFormModel);
}

if (sessionStorage.getItem("flightGroupInfos")) {
  flightGroupInfosRef.value = JSON.parse(
    sessionStorage.getItem("flightGroupInfos")
  );
}

//#region pinia监听，数据获取
watch(
  () => flightUpsellStore.flightItemsShow,
  (newValue) => {
    flightItemsShowRef.value = flightSortTools.sortFlightItems(
      newValue,
      shoppingSortFormModelRef.value
    );

    if (flightItemsShowRef.value && flightItemsShowRef.value.length > 0) {
      flightItemsShowLengthRef.value = flightItemsShowRef.value.length;
    }

    fareKeyRef.value = null;
    activeFlightGroupNumberRef.value = 0;
  },
  { immediate: true, deep: true }
);
watch(
  () => flightUpsellStore.filterOptions,
  (newValue) => {
    filterOptionsRef.value = newValue;
  },
  { immediate: true, deep: true }
);

watch(
  () => flightUpsellStore.itineraryArrangement,
  (newValue) => {
    if (newValue) {
      itineraryArrangementRef.value = newValue.flights;
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => flightUpsellStore.flightFareOptionsShow,
  (newValue) => {
    if (newValue) {
      specificFlightFareOptionsRef.value = newValue;
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => airContentStore.airlineItemMap,
  (newValue) => {
    airlineItemMapRef.value = newValue;
  },
  { immediate: true, deep: true }
);
watch(
  () => airContentStore.aircraftItemMap,
  (newValue) => {
    aircraftItemMapRef.value = newValue;
  },
  { immediate: true, deep: true }
);
watch(
  () => flightUpsellStore.airportCityMap,
  (newValue) => {
    airportCityMapRef.value = newValue;
  },
  { immediate: true, deep: true }
);
//endregion

const showMaxIndex = ref(10);
const isLoadMore = ref(true);

function resetShowMore() {
  showMaxIndex.value = 10;
  isLoadMore.value = true;
}

function loadMoreItems() {
  showMaxIndex.value += 10;
  if (showMaxIndex.value >= flightItemsShowLengthRef.value) {
    isLoadMore.value = false;
  }
}

function mouseover() {
  let over = null;
  console.info("over");
}

//#region 过滤排序总入口
function sortAndFilter(sortFormModel, filterFormModel) {
  const flightItemsShowCopy = JSON.parse(
    JSON.stringify(flightUpsellStore.flightItemsShow)
  );
  if (filterFormModel) {
    shoppingFilterFormModelRef.value = filterFormModel;
    flightItemsShowRef.value = flightFilterTools.filterFlightItems(
      flightItemsShowCopy,
      shoppingFilterFormModelRef.value
    );
  }

  if (sortFormModel) {
    shoppingSortFormModelRef.value = sortFormModel;
    flightItemsShowRef.value = flightSortTools.sortFlightItems(
      flightItemsShowRef.value,
      shoppingSortFormModelRef.value
    );
  }

  if (flightItemsShowRef.value && flightItemsShowRef.value.length > 0) {
    flightItemsShowLengthRef.value = flightItemsShowRef.value.length;
  }
  resetShowMore();
}

//#endregion

//#region 辅助函数

//显示指定飞行顺序的航班列表
function goToSpecificFlightGroup(
  changeType,
  tripSequenceNumber,
  itineraryArrangement,
  fareOptionsSelected,
  flightGroupInfos
) {
  //完成所有航班Fare Options的选择
  const fareOptionSelected = fareOptionsSelected.find(
    (item) => item && item.originDestinationKey
  );
  if (tripSequenceNumber === itineraryArrangement.length) {
    const airItineraryRPH =
      flightUpsellStore.findItineraryRPHWithFinalPrice(fareOptionSelected);

    const routeUrl = router.resolve({
      name: "sales-flight-check-out-v2",
    });

    window.open(
      routeUrl.href + "?airItineraryRPH=" + airItineraryRPH,
      "_blank"
    );
  }
  //通过change flight，跳转至Flight options
  else if (changeType === "CHANGE" && tripSequenceNumber >= 0) {
    const specificFlightGroupInfo = flightGroupInfos[tripSequenceNumber];
    combineKeys.value = specificFlightGroupInfo.combineKey;
    clearFlightAndFareOptionSelected(tripSequenceNumber);

    const queryInfo = flightGroupInfos[tripSequenceNumber].queryInfo;
    if (queryInfo) router.replace({ query: queryInfo });

    activeFlightGroupNumberRef.value = tripSequenceNumber;
    fareOptionShowRef.value = false;
  }
  //选择完Fare options，跳转至下一航班Flight options
  else if (changeType === "UPSELL") {
    const upsellShoppingQueryModel = buildUpsellShoppingQueryModel(
      flightGroupInfos,
      fareOptionsSelected,
      route.query,
      tripSequenceNumber
    );

    router.replace({ query: upsellShoppingQueryModel });
  }

  clearFlightSortAndFilterConditions();
  //划到顶部
  window.scrollTo(0, 0);
}

function buildUpsellShoppingQueryModel(
  flightGroupInfos,
  fareOptionsSelected,
  routeQuery,
  tripSequenceNumber
) {
  let queryFormModal = {
    airSearchType: "UPSELL",
    fareSegmentBasisCodes: [],
    airItineraryRPH: null,
  };

  const fareOptionSelected = fareOptionsSelected.find(
    (item) => item && item.fareSegmentBasisCodes
  );

  flightGroupInfos.forEach((flightGroupInfo) => {
    const fareOptionSelected = flightGroupInfo.fareOptionSelected;

    if (fareOptionSelected) {
      const fareSegmentBasisCodes = fareOptionSelected.fareSegmentBasisCodes;
      queryFormModal.fareSegmentBasisCodes.push(...fareSegmentBasisCodes);
    }
  });
  queryFormModal.airItineraryRPH =
    flightUpsellStore.findItineraryRPHWithPreItinKey(fareOptionSelected);
  queryFormModal.fareSegmentBasisCodes.push(
    ...fareOptionSelected.fareSegmentBasisCodes
  );
  const upsellShoppingQueryModel = buildBasicShoppingQueryModel(queryFormModal);

  flightGroupInfosRef.value[activeFlightGroupNumberRef.value].queryInfo =
    routeQuery;

  sessionStorage.setItem(
    "flightGroupInfos",
    JSON.stringify(flightGroupInfosRef.value)
  );

  Object.keys(routeQuery).forEach(
    (key) => (upsellShoppingQueryModel[key] = routeQuery[key])
  );

  upsellShoppingQueryModel.tripSequenceNumber = tripSequenceNumber;
  upsellShoppingQueryModel.preFareOptionPrice = fareOptionSelected.totalPrice;
  upsellShoppingQueryModel.airItineraryRPH = queryFormModal.airItineraryRPH;
  upsellShoppingQueryModel.fareSegmentBasisCodes =
    queryFormModal.fareSegmentBasisCodes;
  upsellShoppingQueryModel.fareSegmentBasisCodesFormat = formatSimpleArray(
    queryFormModal.fareSegmentBasisCodes
  );

  return upsellShoppingQueryModel;
}

//查看Fare options
function viewFareOptions(
  currentTripSequenceNumber,
  itineraryArrangement,
  fareOptionsSelected,
  originDestinationKey,
  supplierCodes,
  bestTotalPrice,
  flightInfo,
  fareIndex
) {
  fareKeyRef.value = currentTripSequenceNumber + "_" + fareIndex;
  if (!combineKeys.value) combineKeys.value = [];

  combineKeys.value.push(originDestinationKey);
  //记录当前选择航班
  flightGroupInfosRef.value[currentTripSequenceNumber].flightInfo = flightInfo;
  flightGroupInfosRef.value[currentTripSequenceNumber].marketingAirline =
    flightInfo.flightSegments[0].marketingAirlineCode;
  //存储下一航班所需过滤条件
  const nextTripSequenceNumber = currentTripSequenceNumber + 1;
  if (currentTripSequenceNumber < itineraryArrangement.length - 1) {
    const specificFlightGroupInfo =
      flightGroupInfosRef.value[nextTripSequenceNumber];
    specificFlightGroupInfo.supplierCodes = supplierCodes;

    specificFlightGroupInfo.combineKey = combineKeys.value;

    specificFlightGroupInfo.marketingAirline =
      flightInfo.flightSegments[0].marketingAirlineCode;
  }

  flightUpsellStore.showUpsellOptionsShow(originDestinationKey);
  fareOptionShowRef.value = true;
}

//清除指定飞行顺序后的所有已选择的航班
function clearFlightAndFareOptionSelected(activeFlightGroupNumber) {
  fareOptionsSelectedRef.value.splice(
    activeFlightGroupNumber,
    fareOptionsSelectedRef.value.length - activeFlightGroupNumber
  );
  for (
    let i = activeFlightGroupNumber + 1;
    i < flightGroupInfosRef.value.length;
    i++
  ) {
    const specificFlightGroupInfo =
      flightGroupInfosRef.value[activeFlightGroupNumber];
    specificFlightGroupInfo.flightInfo = null;
  }
}

function clearFlightSortAndFilterConditions() {
  shoppingSortFormModelRef.value = new FlightShoppingSortFormModel(
    "PRICE",
    "ASC"
  );
  shoppingFilterFormModelRef.value = new FlightShoppingFilterFormModel(
    [],
    [],
    [],
    [],
    [],
    [],
    []
  );
}

//--------------移动端操作--------------
const filterAndSortOpenRef = ref(false);
const showFilterAndSortDrawer = () => {
  filterAndSortOpenRef.value = true;
};
const closeFilterAndSortDrawer = () => {
  filterAndSortOpenRef.value = false;
};

//----------移动端加载----------------

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

function handleScroll() {
  const distance =
    document.documentElement.scrollHeight -
    document.documentElement.scrollTop -
    document.documentElement.clientHeight;
  if (distance > -2 && distance < 2) {
    loadMoreItems();
  }
}

function closeFareOptions() {
  fareOptionShowRef.value = false;
}

function getFlightEmptyLabel(flightShoppingFormModel) {
  let label = t("no-flights");
  if (flightShoppingFormModel) {
    const originDestinations = flightShoppingFormModel.originDestinations;
    if (originDestinations && originDestinations.length > 0) {
      const itinerary = originDestinations[0];
      if (itinerary) {
        const originLocationName = itinerary.originLocationName;
        const destinationLocationName = itinerary.destinationLocationName;
        const departureDate = itinerary.departureDate;
        label = t("no-flight-in-origin-des", {
          originLocationName: originLocationName,
          destinationLocationName: destinationLocationName,
          month: dateUtils.formatEnglishDate(departureDate).month,
          date: dateUtils.formatEnglishDate(departureDate).ddate,
        });
      }
    }
  }

  return label;
}

//#endregion

function buildBasicShoppingQueryModel(formModel) {
  const shoppingQueryModel = {};

  for (const key of Object.keys(formModel)) {
    const value = formModel[key];

    if (typeof value !== "string") continue;
    shoppingQueryModel[key] = formModel[key];
  }

  return shoppingQueryModel;
}

function formatSimpleArray(array) {
  let format = "";
  if (array == null || array.length === 0) return format;

  for (const ele of array) {
    //遍历数组中的每一项
    if (!ele || (typeof ele === "object" && Object.keys(ele) === 0)) {
      continue;
    }

    if (typeof ele === "string") {
      format += ele; //如果是字符串数组则直接与元素分隔符拼接
    } else {
      for (const key of Object.keys(ele)) {
        if (!key) continue;
        const value = ele[key] ? ele[key] : "";
        if (!value) continue;
        if (format !== "") format += "@"; //变量分隔符
        format += key + "=" + value;
      }
    }
    format += "||"; //元素分隔符
  }
  return format;
}
</script>

<template>
  <section id="explore_area" class="section_padding">
    <div class="container">
      <div class="list-or-fare-show">
        <div
          v-if="itineraryArrangementRef && itineraryArrangementRef.length > 0"
          class="row"
        >
          <div class="col-lg-12">
            <div class="flight-list-navigation p-3 font-14">
              <div
                v-if="
                  itineraryArrangementRef && itineraryArrangementRef.length > 0
                "
                class="flex-row align-items-center"
              >
                <div
                  class="flex-row list-navigation-items justify-content-start"
                >
                  <div
                    v-for="(
                      originDesItem, originDesIndex
                    ) in itineraryArrangementRef"
                    :key="originDesIndex"
                    class="list-nav-item mg-r-8 flex cursor-point"
                  >
                    <div class="flex-column">
                      <div
                        :class="{
                          font_weight:
                            activeFlightGroupNumberRef === originDesIndex &&
                            !fareOptionShowRef,
                          'text-muted':
                            activeFlightGroupNumberRef !== originDesIndex,
                        }"
                      >
                        <div
                          v-if="
                            originDesIndex < activeFlightGroupNumberRef ||
                            (originDesIndex === activeFlightGroupNumberRef &&
                              fareOptionShowRef)
                          "
                        >
                          <div>
                            {{
                              flightCommonTools.getAirlineName(
                                locale,
                                flightGroupInfosRef[activeFlightGroupNumberRef]
                                  .marketingAirline,
                                airlineItemMapRef
                              )
                            }}
                            · {{ originDesItem.originLocationCode }} ->
                            {{ originDesItem.destinationLocationCode }}
                          </div>
                          <span
                            v-if="
                              (fareOptionShowRef &&
                                originDesIndex <= activeFlightGroupNumberRef) ||
                              (!fareOptionShowRef &&
                                originDesIndex < activeFlightGroupNumberRef)
                            "
                            class="text-primary"
                            @click="
                              goToSpecificFlightGroup(
                                'CHANGE',
                                originDesIndex,
                                itineraryArrangementRef,
                                fareOptionsSelectedRef,
                                flightGroupInfosRef
                              )
                            "
                            >{{ $t("change-flight") }}</span
                          >
                        </div>
                        <div
                          v-else-if="
                            originDesIndex === activeFlightGroupNumberRef &&
                            !fareOptionShowRef
                          "
                        >
                          {{
                            $t("choose-flight-to", {
                              locationName:
                                flightCommonTools.getAirportCityName(
                                  locale,
                                  itineraryArrangementRef[originDesIndex]
                                    .destinationLocationCode,
                                  airportCityMapRef
                                ),
                            })
                          }}
                        </div>
                        <div
                          v-if="
                            originDesIndex > activeFlightGroupNumberRef &&
                            !fareOptionShowRef
                          "
                        >
                          {{
                            $t("choose-flight-to", {
                              locationName:
                                flightCommonTools.getAirportCityName(
                                  locale,
                                  originDesItem.destinationLocationCode,
                                  airportCityMapRef
                                ),
                            })
                          }}
                        </div>
                      </div>
                    </div>
                    <i
                      v-if="
                        (originDesIndex <= activeFlightGroupNumberRef &&
                          fareOptionShowRef) ||
                        (originDesIndex < itineraryArrangementRef.length - 1 &&
                          !fareOptionShowRef)
                      "
                      class="fa-solid fa-angle-right mg-t-3 mg-l-8"
                    ></i>
                    <div
                      v-if="
                        fareOptionShowRef &&
                        activeFlightGroupNumberRef === originDesIndex
                      "
                      :class="{
                        font_weight: fareOptionShowRef,
                      }"
                      class="mg-l-8"
                    >
                      {{ $t("fare-options") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 手机端过滤排序展示-->
        <div v-if="equipmentType === 'iphone'" class="row">
          <div
            v-if="
              shoppingFilterFormModelRef &&
              shoppingSortFormModelRef &&
              !fareOptionShowRef
            "
            class="col-lg-12 col-md-12 col-sm-12 col-12 mg-t-15"
          >
            <div class="filter-sort">
              <div class="filtrate_btn h5" @click="showFilterAndSortDrawer">
                {{ $t("filter-and-sort")
                }}<i class="fa fa-fw fa-angle-down"></i>
              </div>
            </div>
            <a-drawer
              :open="filterAndSortOpenRef"
              placement="bottom"
              title="Filter Option"
              @close="closeFilterAndSortDrawer"
            >
              <FlightShoppingFilterAndSortForm
                :active-flight-group-info="
                  itineraryArrangementRef && itineraryArrangementRef.length
                    ? itineraryArrangementRef[activeFlightGroupNumberRef]
                    : {}
                "
                :airline-item-map="airlineItemMapRef"
                :airport-city-map="airportCityMapRef"
                :filterOptions="filterOptionsRef"
                :flightItemsLength="flightItemsShowLengthRef"
                :shoppingFilterFormModel="shoppingFilterFormModelRef"
                :shoppingSortFormModel="shoppingSortFormModelRef"
                @sortAndFilter="sortAndFilter"
              />
            </a-drawer>
          </div>
        </div>
        <!-- 手机端过滤排序展示END-->

        <!-- 过滤排序和机票列表-->
        <div class="row">
          <div v-if="equipmentType !== 'iphone'" class="col-lg-3">
            <!-- 过滤排序-->
            <div class="left_side_search_area">
              <FlightShoppingFilterAndSortForm
                :active-flight-group-info="
                  itineraryArrangementRef && itineraryArrangementRef.length
                    ? itineraryArrangementRef[activeFlightGroupNumberRef]
                    : {}
                "
                :airline-item-map="airlineItemMapRef"
                :airport-city-map="airportCityMapRef"
                :filterOptions="filterOptionsRef"
                :flightItemsLength="flightItemsShowLengthRef"
                :shoppingFilterFormModel="shoppingFilterFormModelRef"
                :shoppingSortFormModel="shoppingSortFormModelRef"
                @sortAndFilter="sortAndFilter"
              />
            </div>
            <!-- 过滤排序END-->
          </div>
          <!-- 机票列表-->
          <div class="col-lg-9">
            <div class="row">
              <div class="col-lg-12">
                <div
                  v-if="flightItemsShowRef && flightItemsShowRef.length > 0"
                  class="flight_list"
                >
                  <div
                    v-for="(flightItem, flightIndex) in flightItemsShowRef"
                    :key="flightIndex"
                  >
                    <BaseFlightListItem
                      v-if="
                        flightIndex < showMaxIndex && equipmentType !== 'iphone'
                      "
                      :aircraft-item-map="aircraftItemMapRef"
                      :airline-item-map="airlineItemMapRef"
                      :airport-city-map="airportCityMapRef"
                      :class="{
                        'flight-active':
                          fareKeyRef ===
                          activeFlightGroupNumberRef + '_' + flightIndex,
                      }"
                      :flightInfo="flightItem"
                      :flightInfoIndex="flightIndex"
                      :previous-option-price="
                        flightGroupInfosRef[activeFlightGroupNumberRef] &&
                        flightGroupInfosRef[activeFlightGroupNumberRef]
                          .bestTotalPrice
                          ? flightGroupInfosRef[activeFlightGroupNumberRef]
                              .bestTotalPrice
                          : null
                      "
                      @viewFareOptions="
                        (
                          originDestinationKey,
                          supplierCodes,
                          bestTotalPrice,
                          flightInfo
                        ) =>
                          viewFareOptions(
                            activeFlightGroupNumberRef,
                            itineraryArrangementRef,
                            fareOptionsSelectedRef,
                            originDestinationKey,
                            supplierCodes,
                            bestTotalPrice,
                            flightInfo,
                            flightIndex
                          )
                      "
                    />
                    <BaseFlightListMobileItem
                      v-else-if="
                        showMaxIndex > flightIndex && equipmentType === 'iphone'
                      "
                      :aircraft-item-map="aircraftItemMapRef"
                      :airline-item-map="airlineItemMapRef"
                      :airport-city-map="airportCityMapRef"
                      :flightInfo="flightItem"
                      :flightInfoIndex="flightIndex"
                      :previous-option-price="
                        flightGroupInfosRef[activeFlightGroupNumberRef] &&
                        flightGroupInfosRef[activeFlightGroupNumberRef]
                          .bestTotalPrice
                          ? flightGroupInfosRef[activeFlightGroupNumberRef]
                              .bestTotalPrice
                          : null
                      "
                      @viewFareOptions="
                        (
                          originDestinationKey,
                          supplierCodes,
                          bestTotalPrice,
                          flightInfo
                        ) =>
                          viewFareOptions(
                            activeFlightGroupNumberRef,
                            itineraryArrangementRef,
                            fareOptionsSelectedRef,
                            originDestinationKey,
                            supplierCodes,
                            bestTotalPrice,
                            flightInfo,
                            flightIndex
                          )
                      "
                    />
                  </div>
                </div>

                <div v-else class="text-center black_color">
                  <AEmpty
                    :description="
                      getFlightEmptyLabel(
                        flightUpsellStore.flightShoppingFormModel
                      )
                    "
                  ></AEmpty>
                </div>

                <div
                  v-if="
                    flightItemsShowRef &&
                    flightItemsShowRef.length > 15 &&
                    isLoadMore
                  "
                  class="load_more_flight"
                >
                  <Spin></Spin>
                </div>
              </div>
            </div>
          </div>
          <!-- 机票列表END-->
        </div>
        <!-- 过滤排序和机票列表END-->

        <!-- Fare Options -->
        <div v-if="fareOptionShowRef" class="fare-options-fixed">
          <FlightFareOptionsSelectPanel
            :active-flight-group-number="activeFlightGroupNumberRef"
            :fare-option-selected="fareOptionsSelectedRef"
            :fare-options="specificFlightFareOptionsRef"
            :flight-info="
              flightGroupInfosRef[activeFlightGroupNumberRef]
                ? flightGroupInfosRef[activeFlightGroupNumberRef].flightInfo
                : null
            "
            :previous-option-price="preFareOptionPriceRef"
            :price-classes="flightUpsellStore.priceClasses"
            @closeFareOptions="closeFareOptions"
            @goToSpecificFlightGroup="
              (nextFlightGroupNumber) =>
                goToSpecificFlightGroup(
                  'UPSELL',
                  nextFlightGroupNumber,
                  itineraryArrangementRef,
                  fareOptionsSelectedRef,
                  flightGroupInfosRef
                )
            "
          />
        </div>
        <!-- Fare Options END -->
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.list-or-fare-show {
  .flight-active {
    border: 1px solid #8b3eea;
  }

  .fare-options-fixed {
    width: 60%;
    height: 100vh;
    position: fixed;
    z-index: 111111;
    right: 0;

    top: 0;
    overflow-y: scroll;
    margin-bottom: 100px;
  }
}
</style>
