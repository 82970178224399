<script setup>
import { ref, watchEffect } from "vue";
import dateUtils from "../../../../../utils/dateUtils";

const props = defineProps({
  tripAirItem: {},
  recordLocator: {},
  idContext: {},
  tripId: {},
  tripItemId: {},
  tripAirItemId: {
    default: null,
  },
  isFromTripList: {},
  airlineCapability: {},
  itineraryCancelled: {},
  ticketVoided: {},
  tripItemType: {},
  expireInSecond: {},
  createdDateTime: {},
  bookingStatus: {},
});

let second = ref(0);
let minutes = ref(0);

watchEffect(() => {
  if (props.expireInSecond) {
    let secondExpire = JSON.parse(JSON.stringify(props.expireInSecond));
    setInterval(() => {
      secondExpire--;
      second.value = Math.floor(secondExpire % 60);
      minutes.value = Math.floor(secondExpire / 60);
    }, 1000);
  }
});
</script>

<template>
  <div class="tour_booking_form_box">
    <!-- Booking Numbers Info -->
    <div class="col-12 fs-sm">
      <p class="h4">{{ $t("booking") }} #{{ tripId }}</p>
      <p v-if="createdDateTime" class="h6">
        {{ $t("created-date") }}:
        <span class="text-muted">{{
          dateUtils.formatDateTime(createdDateTime)
        }}</span>
      </p>
      <p
        v-if="
          expireInSecond > 0 &&
          tripItemType === 'HOTEL' &&
          bookingStatus !== 'CANCELLED' &&
          bookingStatus !== 'CONFIRMED' &&
          bookingStatus !== 'BOOKING_RECEIVED'
        "
        class="h6 text-warning"
      >
        {{ $t("expire-time-min-s", { minutes: minutes, second: second }) }}
      </p>
      <p
        v-else-if="
          expireInSecond <= 0 &&
          bookingStatus !== 'CANCELLED' &&
          bookingStatus !== 'CONFIRMED' &&
          bookingStatus !== 'BOOKING_RECEIVED'
        "
        class="fw-semibold rounded-pill bg-danger-light text-danger fs-sm"
      >
        {{ $t("expired") }}
      </p>
      <p>
        <span
          v-if="
            bookingStatus === 'CANCELLED' ||
            bookingStatus === 'BOOKING_CANCELLED'
          "
          class="fw-semibold d-inline-block rounded-pill bg-danger-light text-danger fs-sm"
          >{{ bookingStatus }}</span
        >
        <span
          v-else-if="bookingStatus === 'UNPAID'"
          class="fw-semibold d-inline-block rounded-pill bg-info-light text-info fs-sm"
          >{{ bookingStatus }}</span
        >
        <span
          v-else
          class="fw-semibold d-inline-block rounded-pill bg-success-light text-success fs-sm"
          >{{ bookingStatus }}</span
        >
      </p>
      <!--        v-if="-->
      <!--          bookingDetailsViewItem.confirmationCode &&-->
      <!--          bookingDetailsViewItem.supplierName-->
      <!--        "-->
      <!--        class="h6 mb-3"-->
      <!--      >-->
      <!--        {{-->
      <!--          $t("confirmation-number-with-supplier", {-->
      <!--            supplierName: bookingDetailsViewItem.supplierName,-->
      <!--          })-->
      <!--        }}:-->
      <!--        <span class="text-muted">-->
      <!--          {{ bookingDetailsViewItem.confirmationCode }}</span-->
      <!--        >-->
      <!--      </p>-->
      <!--      <p v-else-if="bookingDetailsViewItem.confirmationCode" class="h6 mb-3">-->
      <!--        {{ $t("confirmation-number") }}-->
      <!--        <span class="text-muted">-->
      <!--          {{ bookingDetailsViewItem.confirmationCode }}-->
      <!--        </span>-->
      <!--      </p>-->
      <!--      <span v-if="bookingDetailsViewItem.recordLocators">-->
      <!--        <p-->
      <!--          v-for="(-->
      <!--            recordLocator, recordLocatorIndex-->
      <!--          ) in bookingDetailsViewItem.recordLocators"-->
      <!--          :key="recordLocatorIndex"-->
      <!--          class="h6 mb-3"-->
      <!--        >-->
      <!--          Ref.({{ recordLocator.id_context }}):-->
      <!--          <span class="text-muted"> {{ recordLocator.id }}</span>-->
      <!--        </p>-->
      <!--      </span>-->
    </div>
  </div>
</template>

<style scoped></style>
