<script setup>
import { reactive, ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import paymentApi from "@/apis/paymentApi";
import { useTemplateStore } from "@/stores/template";
import { useBookingStore } from "@/stores/booking";
import { useCmsContentStore } from "@/stores/cms";
import invoiceApi from "@/apis/invoiceApi";
import { Modal } from "ant-design-vue";

const AModal = Modal;

let conditionChecked = ref(null);

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  bannerContent = cmsContentStore.productPageBackgroundImage;
  imageUrl.value =
    cmsContentStore.productPageBackgroundImage &&
    cmsContentStore.productPageBackgroundImage.paymentBackgroundImage
      ? cmsContentStore.productPageBackgroundImage.paymentBackgroundImage.url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});
//endregion cms内容管理

const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const bookingStore = useBookingStore();
bookingStore.$reset();

const bookingId = route.query.bookingId;
const invoiceId = route.query.invoiceId;
const productType = route.query.productType;
JSON.parse(sessionStorage.getItem("bookRQ"));
if (bookingId) {
  syncSessionAndSearch(bookingId);
} else if (invoiceId) {
  invoiceApi.getInvoiceById(invoiceId).then((res) => {
    let data = res.data;
    syncSessionAndSearch(data.bookingId);
  });
}

async function syncSessionAndSearch(id) {
  templateStore.pageLoader({ mode: "on" });
  let condition = {
    type: "BOOKING_ID",
    id: id,
    limit: 1,
    offset: 0,
  };

  await bookingStore.searchBookingDetails(condition);
  templateStore.pageLoader({ mode: "off" });
}

let passengerFares = reactive({
  flightPassengerFareItems: null,
});

watchEffect(() => {
  let flightPassengerFareItems = null;
  let hotelPassengerFareItems = null;
  if (
    bookingStore.bookingDetailsViewItem &&
    bookingStore.bookingDetailsViewItem.flightItems
  ) {
    flightPassengerFareItems = [];
    bookingStore.bookingDetailsViewItem.flightItems.forEach((flightItem) => {
      let passengerFareItems = flightItem.passengerFareViewItems;
      flightPassengerFareItems.push(...passengerFareItems);
    });
  }
  if (
    bookingStore.bookingDetailsViewItem &&
    bookingStore.bookingDetailsViewItem.hotelItems &&
    bookingStore.bookingDetailsViewItem.hotelItems.length
  ) {
    hotelPassengerFareItems = [];
    bookingStore.bookingDetailsViewItem.hotelItems.forEach((hotelItem) => {
      let roomTypeNames = hotelItem.roomTypeNames;
      let roomTypeName = roomTypeNames.split(",")[0];
      hotelItem.roomViewItems.forEach((roomViewItem) => {
        let totalPrice = roomViewItem.totalPrice;
        let currency = roomViewItem.currency;
        hotelPassengerFareItems.push({
          totalPrice,
          currency,
          roomTypeName,
        });
      });
    });
  }

  passengerFares.flightPassengerFareItems = flightPassengerFareItems;
  passengerFares.hotelPassengerFareItems = hotelPassengerFareItems;

  let totalAmount = 0;
  let currency = null;
  let adultTotalAmount = 0;
  let childTotalAmount = 0;
  let infantTotalAmount = 0;
  let adultNum = 0;
  let childNum = 0;
  let infantNum = 0;
  let taxesTotalAmount = 0;
  if (flightPassengerFareItems) {
    flightPassengerFareItems.forEach((breakdownItem) => {
      let totalFare = breakdownItem.totalFare;
      totalAmount += totalFare;
      taxesTotalAmount += breakdownItem.taxes;
      currency = breakdownItem.currency;

      if (breakdownItem.passengerTypeCode == "ADT") {
        adultNum++;
        adultTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "CNN") {
        childNum++;
        childTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "INF") {
        infantNum++;
        infantTotalAmount += breakdownItem.baseFare;
      }
    });
  }
  if (hotelPassengerFareItems) {
    hotelPassengerFareItems.forEach((hotelPassengerFareItem) => {
      totalAmount += hotelPassengerFareItem.totalPrice;
      //(hotelPassengerFareItem.currency);
      if (!currency && hotelPassengerFareItem.currency) {
        currency = hotelPassengerFareItem.currency;
      }
    });
  }

  if (
    bookingStore.bookingDetailsViewItem &&
    bookingStore.bookingDetailsViewItem.activityItems &&
    bookingStore.bookingDetailsViewItem.activityItems.length
  ) {
    const activityItem = bookingStore.bookingDetailsViewItem.activityItems[0];

    if (activityItem) {
      totalAmount = activityItem.totalAmount;
      currency = activityItem.currencyCode;
    }
  }

  //car Rental
  if (
    bookingStore.bookingDetailsViewItem &&
    bookingStore.bookingDetailsViewItem.vehicleItems &&
    bookingStore.bookingDetailsViewItem.vehicleItems.length
  ) {
    const vehicleItem = bookingStore.bookingDetailsViewItem.vehicleItems[0];

    if (vehicleItem) {
      totalAmount = vehicleItem.totalAmount;
      currency = vehicleItem.currencyCode;
    }
  }
  let totalAmountContent = {
    totalAmount,
    currency,
    adultTotalAmount,
    childTotalAmount,
    infantTotalAmount,
    infantNum,
    adultNum,
    childNum,
    taxesTotalAmount,
  };
  passengerFares.totalAmountContent = totalAmountContent;
});

function payByPaymentMethod(paymentMethod, cardType) {
  if (conditionChecked.value && conditionChecked.value != null) {
    const bookRQSession =
      sessionStorage.getItem("bookRQ") != null
        ? JSON.parse(sessionStorage.getItem("bookRQ"))
        : null;
    templateStore.pageLoader({ mode: "on" });
    paymentApi
      .payByPaymentMethod(
        invoiceId,
        productType,
        paymentMethod,
        bookRQSession,
        cardType
      )
      .then((res) => {
        templateStore.pageLoader({ mode: "off" });
        if (res.data.redirect && res.data.redirect.redirectUrl) {
          let redirectUrl = res.data.redirect.redirectUrl;
          window.location.href = redirectUrl;
        }
      });
  } else {
    conditionChecked.value = false;
  }
}

function stripePay(currency) {
  if (conditionChecked.value) {
    const bookRQ =
      sessionStorage.getItem("bookRQ") != null
        ? JSON.parse(sessionStorage.getItem("bookRQ"))
        : null;
    templateStore.pageLoader({ mode: "on" });
    paymentApi
      .commonInitiatePayment(invoiceId, productType, "STRIPE", bookRQ, currency)
      .then((res) => {
        templateStore.pageLoader({ mode: "off" });
        let chargeRS = res.data;
        let redirectUrl = chargeRS.redirect
          ? chargeRS.redirect.redirectUrl
          : null;
        if (redirectUrl) {
          window.open(redirectUrl);
          templateStore.pageLoader({ mode: "off" });
        } else {
          //如果没有地址
          AModal.error({
            title: "This is an error message",
            content: "Have no url",
            centered: true,
            width: 600,
          });
        }
        templateStore.pageLoader({ mode: "off" });
      })
      .catch((error) => {
        AModal.error({
          title: "This is an error message",
          content:
            "Payment may not continue due to lack of information on the reservation",
          centered: true,
          width: 600,
        });
        templateStore.pageLoader({ mode: "off" });
      });
  } else {
    conditionChecked.value = false;
  }
}
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("payment") }}</h2>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="router.back">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <div class="tou_booking_form_Wrapper">
            <div>
              <h3 class="heading_theme">{{ $t("payment-method") }}</h3>
              <div class="tour_booking_form_box hotel_details_content_area">
                <h3>
                  {{ $t("total-amount") }}
                  {{ passengerFares.totalAmountContent.currency }}
                  {{ passengerFares.totalAmountContent.totalAmount.toFixed(2) }}
                </h3>
                <div class="row">
                  <div class="col-4">
                    <i
                      class="fa-brands fa-cc-stripe cursor-point"
                      style="font-size: 90px; margin-left: 35px"
                      @click="
                        stripePay(passengerFares.totalAmountContent.currency)
                      "
                    ></i>
                  </div>
                </div>
                <div class="form-check write_spical_check">
                  <input
                    id="flexCheckDefaultf1"
                    v-model="conditionChecked"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="flexCheckDefaultf1">
                    <!--                    <router-link-->
                    <!--                      class="main_color"-->
                    <!--                      to="/templates/terms-service"-->
                    <!--                      >{{ $t("read-and-accept-conditions") }}-->
                    <!--                    </router-link>-->
                    <a class="text-black cursor-point"
                      >{{ $t("read-and-accept-conditions") }}
                    </a>
                  </label>
                  <div
                    v-if="!conditionChecked && conditionChecked != null"
                    class="text-danger"
                  >
                    Please check the above option！
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
