<script setup>
import { ref, watch } from "vue";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { useI18n } from "vue-i18n";
import { Select } from "ant-design-vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const props = defineProps({
  ffpNumer: {},
  ffpNumerIndex: {},
  ffpNumerNum: {},
  frequentFlyerProgramOption: {},
});

const { t } = useI18n();

const emits = defineEmits();
const ffpNumberFormModelRef = ref(null);

watch(
  () => props.ffpNumer,
  (newValue) => {
    ffpNumberFormModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);

watch(
  () => props.ffpNumer,
  (newValue) => {
    ffpNumberFormModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);

let rulesRef = ref({
  programID: { required },
  membershipID: { required },
});

const v$ = useVuelidate(rulesRef.value, ffpNumberFormModelRef);

function addMembership() {
  emits("addMembership");
}

function removeMembership(index, custLoyaltyId) {
  emits("removeMembership", index, custLoyaltyId);
}
</script>

<template>
  <div class="col-5">
    <div class="form-group">
      <label class="form-label">Programme Name</label>
      <select
        id="passenger-form-flyerAirline"
        v-model="v$.programID.$model"
        :class="{
          'is-invalid': v$.programID.$errors.length > 0,
        }"
        @blur="v$.programID.$touch"
        class="form-control main_input_height"
        type="text"
      >
        <option
          v-for="(
            frequentFlyerProgram, frequentFlyerIndex
          ) in frequentFlyerProgramOption"
          :key="frequentFlyerIndex"
          :value="frequentFlyerProgram.programID"
        >
          {{ frequentFlyerProgram.programName }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-5">
    <div class="form-group">
      <label class="form-label">Programme Number </label>
      <input
        id="passenger-form-surname"
        v-model="v$.membershipID.$model"
        :class="{
          'is-invalid': v$.membershipID.$errors.length > 0,
        }"
        @blur="v$.membershipID.$touch"
        :placeholder="$t('frequent-flyer-airline-number-option')"
        autocomplete="off"
        class="form-control main_input_height"
        name="passenger-form-surname"
        type="text"
      />
    </div>
  </div>
  <div
    class="col-2"
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="mg-t-30 cursor-point text-primary" @click="addMembership">
      Add
    </div>
    <div
      class="mg-t-30 cursor-point text-danger mg-l-8"
      @click="
        removeMembership(ffpNumerIndex, ffpNumberFormModelRef.custLoyaltyId)
      "
      v-if="ffpNumerNum > 1"
    >
      {{ $t("remove") }}
    </div>
  </div>
</template>

<style lang="scss">
.vue-tel-input {
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #dfe3ea;
  text-align: left;
}
</style>
