<script setup>
import { getCurrentInstance, onMounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useSecurityStore } from "@/stores/security";
import { useCmsContentStore } from "@/stores/cms";
import { useTemplateStore } from "@/stores/template"; //i18n引入
import { useI18n } from "vue-i18n";
import { Modal } from "ant-design-vue";
import commonContentApi from "@/apis/v2/content/commonContentApi";
import headerTabs from "@/data/headerTabs";

const AModal = Modal;
let language = ref(
  sessionStorage.getItem("languageCode")
    ? sessionStorage.getItem("languageCode")
    : "en"
);
let currency = ref("EN");
let isSticky = ref(false);

const { t, locale } = useI18n(); // 解构调用函数
const userRef = ref(null);

const securityStore = useSecurityStore();
const headerTabsRef = ref(null);
const slotContentsRef = ref(null);

//region cms
const headerContentStore = useCmsContentStore();
headerContentStore.getHeaderContent();
headerContentStore.getContentManagements();
headerContentStore.getSlotContents();
headerContentStore.getWebsiteInfoContent();
//endregion

const templateStore = useTemplateStore();

let equipmentType = ref(templateStore.responsive.equipmentType);

//保存网站包含的产品内容
if (!templateStore.webContent) {
  commonContentApi.capabilityContent().then((res) => {
    sessionStorage.setItem("capabilityContent", JSON.stringify(res.data));
    //capability有结果
    if (res && Array.isArray(res.data) && res.data && res.data.length > 0) {
      templateStore.setWebContent(res.data);
    }
  });
}
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
watch(
  () => securityStore.userInfo,
  (newValue) => {
    console.log(newValue);
    if (!newValue) {
      securityStore.getUserInfo();
    } else {
      userRef.value = newValue;
    }
  },
  { immediate: true, deep: true }
);
watch(
  () => language.value,
  (newValue) => {
    sessionStorage.setItem("languageCode", language.value);
    locale.value = newValue;
  },
  { immediate: true }
);
watch(
  () => headerContentStore.slotContents,
  (newValue) => {
    slotContentsRef.value = newValue;
  },
  { immediate: true, deep: true }
);

watch(
  () => templateStore.webContent,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      headerTabsRef.value = [];
      newValue.forEach((code) => {
        if (headerTabs.find((item) => item.code === code)) {
          headerTabsRef.value.push(
            headerTabs.find((item) => item.code === code)
          );
        }
      });
    } else {
      //没有设置的话默认所有的
      headerTabsRef.value = headerTabs;
    }
  },
  { immediate: true }
);

const router = useRouter();
onMounted(() => {
  window.addEventListener("scroll", () => {
    let scroll = window.scrollY;
    if (scroll >= 200) {
      isSticky.value = true;
    } else {
      isSticky.value = false;
    }
  });

  document.addEventListener("click", function (e) {
    // Hamburger menu
    if (e.target.classList.contains("hamburger-toggle")) {
      e.target.children[0].classList.toggle("active");
    }
  });
});

/*
logOut
 */
const cns = getCurrentInstance();

async function logOut() {
  sessionStorage.removeItem("user");
  await securityStore.signOut();
  userRef.value = null;
  cns.appContext.config.globalProperties.$gAuth.signOut(); //google 退出登录
  router.push({ name: "home" });
}

const baseUrl = "/api/v3/content/collections/images";

//region 语言切换
//模态框打开关闭
const open = ref(false);
const showModal = () => {
  open.value = true;
};

//当前语言
let currentLanguageIndex = ref(
  sessionStorage.getItem("languageCode") &&
    sessionStorage.getItem("languageCode") === "en"
    ? 0
    : 1
);

//语言切换列表
const languageOptions = reactive([
  {
    language: "English",
    icon: "/assets/flagIcon/en.png",
    type: "en",
  },
  {
    language: "简体中文",
    icon: "/assets/flagIcon/cn.png",
    type: "zh_CN",
  },
]);

//切换语言方法
function changeLanguage(index) {
  currentLanguageIndex.value = index;
  const language = languageOptions[index].type;
  sessionStorage.setItem("languageCode", language);
  locale.value = language;
  open.value = false;
}

//endregion

function toBookings() {
  sessionStorage.setItem("profileActiveTab", "BOOKING");
  router.push({ name: "profiles-customer-dashboard" });
}

function toProfile() {
  sessionStorage.setItem("profileActiveTab", "PROFILE");
  router.push({ name: "profiles-customer-dashboard" });
}

function toSpecificContent(contentId) {
  router.push({ name: "content", query: { contentId: contentId } });
}
</script>

<template>
  <header class="main_header_arae">
    <!-- Top Bar -->
    <div class="topbar-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <ul v-if="headerContentStore.headerContent" class="topbar-list">
              <!-- phone-->
              <li v-if="headerContentStore.contentManagement">
                <a
                  v-if="
                    headerContentStore.contentManagement
                      .showHeaderPhoneNumberContent
                  "
                  href="#!"
                  ><span>{{
                    headerContentStore.headerContent.phoneNumber
                  }}</span></a
                >
              </li>
              <li v-if="headerContentStore.contentManagement">
                <a
                  v-if="
                    headerContentStore.contentManagement.showHeaderEmailContent
                  "
                  href="#!"
                  ><span>{{
                    headerContentStore.headerContent.emailAddress
                  }}</span></a
                >
              </li>
            </ul>
          </div>
          <div v-if="equipmentType !== 'iphone'" class="col-lg-5 col-md-5">
            <ul class="header-topbar">
              <li v-if="!userRef">
                <router-link to="/security/sign-in"
                  >{{ $t("sign-in") }}
                </router-link>
              </li>
              <li v-if="!userRef">
                <router-link to="/security/sign-up"
                  >{{ $t("sign-up") }}
                </router-link>
              </li>
              <li v-if="userRef" class="user">
                <div class="dropdown">
                  <button
                    id="page-header-user-dropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-sm d-flex align-items-center header-user-dropdown-btn"
                    data-bs-toggle="dropdown"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                    type="button"
                  >
                    <i class="bi bi-person-circle white_color font-20"></i>
                    <a class="d-none d-sm-inline-block ms-2">{{
                      userRef.username
                    }}</a>
                    <i class="bi bi-chevron-down white_color mg-t-3 mg-l-5"></i>
                  </button>
                  <div
                    aria-labelledby="page-header-user-dropdown"
                    class="dropdown-menu dropdown-menu-md dropdown-menu-end p-0 border-0 header-dropdown-menu"
                  >
                    <div class="p-2">
                      <RouterLink
                        :to="{ name: 'security-change-password' }"
                        class="dropdown-item d-flex align-items-center justify-content-between"
                      >
                        <span class="fs-sm fw-medium">{{
                          $t("reset-password")
                        }}</span>
                      </RouterLink>
                    </div>
                    <div class="p-2">
                      <a
                        class="dropdown-item d-flex align-items-center justify-content-between"
                        @click="toProfile"
                      >
                        <span class="fs-sm fw-medium">{{ $t("profile") }}</span>
                      </a>
                    </div>
                    <div class="p-2">
                      <a
                        class="dropdown-item d-flex align-items-center justify-content-between"
                        @click="logOut"
                      >
                        <span class="fs-sm fw-medium">{{ $t("log-out") }}</span>
                      </a>
                    </div>
                  </div>
                </div>
                <!-- END User Dropdown -->
              </li>
              <li v-if="userRef">
                <a class="white_color" @click="toBookings"
                  >{{ $t("bookings") }}
                </a>
              </li>
              <li class="flex" @click="showModal">
                <!--                  <div class="dropdown language-option">-->
                <!--                    <select v-model="language">-->
                <!--                      <option value="en">{{ $t("english") }}</option>-->
                <!--                      <option value="zh">{{ $t("chinese") }}</option>-->
                <!--                    </select>-->
                <!--                  </div>-->
                <img
                  :src="languageOptions[currentLanguageIndex].icon"
                  class="ms-2"
                  style="width: 30px; height: 20px; cursor: pointer"
                />
                <div class="mg-l-8 white_color">
                  {{ languageOptions[currentLanguageIndex].language }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Navbar Bar -->
    <div
      :class="{ 'is-sticky': isSticky && equipmentType !== 'iphone' }"
      class="navbar-area"
    >
      <div class="main-responsive-nav">
        <div class="container">
          <div class="main-responsive-menu mean-container">
            <nav class="navbar">
              <div class="container">
                <div v-if="headerContentStore.headerContent" class="logo">
                  <router-link to="/">
                    <!-- <img src="../../assets/img/logo.png" alt="logo" />-->
                    <img
                      v-if="
                        headerContentStore.headerContent.logo &&
                        headerContentStore.headerContent.logo.url
                      "
                      :src="baseUrl + headerContentStore.headerContent.logo.url"
                      alt="logo"
                      style="max-height: 100px"
                    />
                    <img
                      v-else
                      alt="logo"
                      src="@/assets/img/logo.png"
                      style="max-height: 100px"
                    />
                  </router-link>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                  "
                >
                  <button
                    class="navbar-toggler collapsed"
                    data-bs-target="#navbar-content"
                    data-bs-toggle="collapse"
                    type="button"
                  >
                    <div class="hamburger-toggle">
                      <div class="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </button>
                  <div
                    id="navbar-content"
                    class="collapse navbar-collapse mean-nav"
                  >
                    <ul
                      v-if="headerContentStore.headerContent"
                      class="navbar-nav mr-auto mb-2 mb-lg-0"
                    >
                      <li v-if="!userRef" class="nav-item dropdown">
                        <router-link to="/security/sign-in"
                          >{{ $t("sign-in") }}
                        </router-link>
                      </li>
                      <li v-if="!userRef" class="nav-item dropdown">
                        <router-link to="/security/sign-up"
                          >{{ $t("sign-up") }}
                        </router-link>
                      </li>
                      <li v-if="userRef" class="nav-item dropdown user">
                        <div class="dropdown" style="margin-left: 10px">
                          <button
                            id="page-header-user-dropdown"
                            aria-expanded="false"
                            aria-haspopup="true"
                            class="btn d-flex align-items-center header-user-dropdown-btn"
                            data-bs-toggle="dropdown"
                            style="
                              display: flex;
                              justify-content: space-between;
                              align-items: center;
                            "
                            type="button"
                          >
                            <i
                              class="bi bi-person-circle"
                              style="font-size: 21px"
                            ></i>
                            <span>{{ userRef.username }}</span>
                            <i class="bi bi-chevron-down white_color"></i>
                          </button>
                          <div
                            aria-labelledby="page-header-user-dropdown"
                            class="dropdown-menu dropdown-menu-md dropdown-menu-end p-0 border-0 header-dropdown-menu"
                          >
                            <div class="p-2">
                              <RouterLink
                                :to="{ name: 'security-change-password' }"
                                class="dropdown-item d-flex align-items-center justify-content-between"
                              >
                                <span class="fs-sm fw-medium">{{
                                  $t("reset-password")
                                }}</span>
                              </RouterLink>
                            </div>
                            <div class="p-2">
                              <a
                                class="dropdown-item d-flex align-items-center justify-content-between"
                                @click="toProfile"
                              >
                                <span class="fs-sm fw-medium">{{
                                  $t("profile")
                                }}</span>
                              </a>
                            </div>
                            <div class="p-2">
                              <a
                                class="dropdown-item d-flex align-items-center justify-content-between"
                                @click="logOut"
                              >
                                <span class="fs-sm fw-medium">{{
                                  $t("log-out")
                                }}</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <!-- END User Dropdown -->
                      </li>
                      <li class="nav-item dropdown">
                        <a class="dropdown-item" @click="toBookings"
                          >{{ $t("bookings") }}
                        </a>
                      </li>

                      <li class="nav-item dropdown">
                        <router-link class="dropdown-item" to="/"
                          >{{ $t("home") }}
                        </router-link>
                      </li>
                      <li
                        v-for="(tab, tabIndex) in headerTabsRef"
                        :key="tabIndex"
                        class="nav-item dropdown"
                      >
                        <a
                          class="dropdown-item dropdown-toggle"
                          @click="router.push({ name: tab.to })"
                          >{{ $t(tab.name) }}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <select
                    v-model="language"
                    class="header-language"
                    style="
                      color: white;
                      margin-left: 15px;
                      margin-top: 10px;
                      background-color: #8b3eea;
                      border: none;
                    "
                  >
                    <option value="en">{{ $t("english") }}</option>
                    <option value="zh">{{ $t("chinese") }}</option>
                  </select>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div class="main-navbar">
        <div class="container">
          <nav
            v-if="headerContentStore.headerContent"
            class="navbar navbar-expand-md navbar-light"
          >
            <router-link class="navbar-brand" to="/"
              ><img
                v-if="
                  headerContentStore.headerContent.logo &&
                  headerContentStore.headerContent.logo.url
                "
                :src="baseUrl + headerContentStore.headerContent.logo.url"
                alt="logo"
                style="max-height: 100px"
              />
              <img
                v-else
                alt="logo"
                src="../../assets/img/logo.png"
                style="max-height: 100px"
              />
            </router-link>

            <div
              id="navbarSupportedContent"
              class="collapse navbar-collapse mean-menu"
            >
              <ul class="navbar-nav">
                <li class="nav-item">
                  <router-link class="nav-link" to="/">
                    {{ $t("home") }}
                  </router-link>
                </li>
                <li
                  v-for="(tab, tabIndex) in headerTabsRef"
                  :key="tabIndex"
                  class="nav-item cursor-point"
                  @click="router.push({ name: tab.to })"
                >
                  <a class="nav-link"> {{ $t(tab.name) }} </a>
                </li>

                <li
                  v-for="(slotContent, slotContentIndex) in slotContentsRef"
                  :key="slotContentIndex"
                  class="nav-item cursor-point"
                  @click="toSpecificContent(slotContent.documentId)"
                >
                  <a class="nav-link"> {{ slotContent.name }} </a>
                </li>

                <!--    todo get contents of slots from CMS        -->
              </ul>
              <!--                <div class="others-options d-flex align-items-center">-->
              <!--                  <div class="option-item">-->
              <!--                    <router-link to="/become-vendor" class="btn btn_navber"-->
              <!--                      >Become a partner</router-link-->
              <!--                    >-->
              <!--                  </div>-->
              <!--                </div>-->
            </div>
          </nav>
        </div>
      </div>
      <div class="others-option-for-responsive">
        <div class="container">
          <div class="container">
            <div class="option-inner">
              <div class="others-options d-flex align-items-center">
                <div class="option-item">
                  <a class="search-box" href="#"
                    ><i class="fas fa-search"></i
                  ></a>
                </div>
                <div class="option-item">
                  <router-link class="btn btn_navber" to="/contact"
                    >{{ $t("get-free-quote") }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <a-modal
    v-model:open="open"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
  >
    <div class="mg-t-10">
      <h4>{{ $t("current-language") }}</h4>
      <div class="row mg-b-20">
        <div class="col-12">
          <img
            :src="languageOptions[currentLanguageIndex].icon"
            class="ms-2"
            style="width: 40px; height: 30px; cursor: pointer"
          />

          <span
            class="mg-l-8"
            style="font-weight: 600; font-size: 14px; color: #4c78dd"
            >{{ languageOptions[currentLanguageIndex].language }}
            <span
              v-if="
                languageOptions[currentLanguageIndex].language === 'English'
              "
              >- US</span
            ></span
          >
        </div>
      </div>
      <h4>{{ $t("all-languages") }}</h4>

      <div class="row">
        <div
          v-for="(item, index) in languageOptions"
          :key="index"
          class="col-4 mg-b-20"
          style="cursor: pointer"
          @click="changeLanguage(index)"
        >
          <img
            :src="item.icon"
            class="ms-2"
            style="width: 40px; height: 30px"
          />
          <span
            :class="{ main_color: index === currentLanguageIndex }"
            class="mg-l-8"
            style="font-weight: 600; font-size: 14px"
            >{{ item.language }}</span
          >
        </div>
      </div>
    </div>
  </a-modal>
</template>
