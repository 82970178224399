import { defineStore } from "pinia";
import flightShoppingTools from "@/tools/v2/flight/shopping/flightShoppingTools";
import flightPrimeBookingApi from "@/apis/v2/flight/flightPrimeBookingApi";
import flightCommonTools from "@/tools/v2/flight/common/flightCommonTools";
import flightFilterTools from "@/tools/v2/flight/shopping/flightFilterTools";
import contentApi from "@/apis/contentApi";

export const useFlightShoppingStore = defineStore("flightShopping", {
  state: () => ({
    flightShoppingFormModel: null,
    itineraryArrangement: null,
    itineraryFareOptionKeyMap: null,
    flightItems: null,
    airportCityMap: null,
    flightKeyGroups: null,
    flightItemsShow: null,
    flightFareOptionsMap: null,
    flightFareOptionsShow: null,
    priceClasses: null,
    filterOptions: null,
    flightSegmentMap: null,
    airLowFareSearchRS: null,
  }),
  actions: {
    airLowFareSearch(flightShoppingFormModel) {
      const thisRef = this;

      thisRef.flightShoppingFormModel = sessionStorage.getItem(
        "flightShoppingFormModel"
      )
        ? JSON.parse(sessionStorage.getItem("flightShoppingFormModel"))
        : null;
      const flightShoppingRQ = flightShoppingTools.buildFlightShoppingRQ(
        flightShoppingFormModel
      );
      return flightPrimeBookingApi
        .lowFareSearch(flightShoppingRQ)
        .then((res) => {
          let airLowFareSearchRS = res.data;
          thisRef.airLowFareSearchRS = airLowFareSearchRS;
          sessionStorage.setItem(
            "airLowFareSearchRS",
            JSON.stringify(airLowFareSearchRS)
          );
          if (
            airLowFareSearchRS.pricedItineraryList &&
            airLowFareSearchRS.pricedItineraryList.length > 0
          ) {
            thisRef.itineraryArrangement =
              flightShoppingTools.buildItineraryArrangement(
                flightShoppingFormModel
              );
            //Basic Info
            thisRef.flightItems =
              flightShoppingTools.buildFlightItems(airLowFareSearchRS);
            thisRef.flightSegmentMap = airLowFareSearchRS.flightSegmentMap;
            thisRef.airItineraryMap = airLowFareSearchRS.airItineraryMap;

            thisRef.flightKeyGroups = flightShoppingTools.buildFlightKeyGroups(
              airLowFareSearchRS.airItineraryMap
            );

            thisRef.itineraryFareOptionKeyMap =
              flightShoppingTools.buildItineraryFareOptionKeyMap(
                airLowFareSearchRS
              );

            thisRef.flightFareOptionsMap =
              flightShoppingTools.buildFlightFareOptionsMap(airLowFareSearchRS);
            thisRef.priceClasses = flightCommonTools.buildPriceClasses(
              airLowFareSearchRS.offer
            );

            //Default trip flight items show
            thisRef.flightItemsShow =
              flightShoppingTools.findSpecificFlightItems(
                thisRef.flightItems,
                0
              );

            //Filter
            thisRef.filterOptions = flightFilterTools.buildFilterOptions(
              thisRef.flightItemsShow
            );

            thisRef.airportCityMap = {};
            const airportCodes = flightShoppingTools.findAllAirportCodes(
              thisRef.flightItems
            );
            // const allNecessaryCodes = flightShoppingTools.findAllNecessaryCodes(
            //   thisRef.flightItems
            // );
            let promises = [];
            for (const airportCode of airportCodes) {
              let promise = contentApi.airportCity(airportCode);
              promises.push(promise);
            }

            return Promise.all(promises).then((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  let data = res[i].data;
                  thisRef.airportCityMap[data.code] = data;
                }
              }
            });
          } else {
            thisRef.emptyState();
          }
        })
        .catch((err) => {
          console.error(err);
          thisRef.emptyState();
        });
    },
    saveShoppingFormModal(formModal) {
      console.log(formModal);
      this.flightShoppingFormModel = formModal;
    },
    changeFlightItemsShow(
      tripSequenceNumber,
      combineKeys,
      fareOptionsSelected,
      amount,
      filterType
    ) {
      const thisRef = this;
      if (!thisRef.flightItems || !thisRef.flightKeyGroups) {
        //Default trip flight items show
        thisRef.flightItemsShow = null;

        //Filter and Sorting
        thisRef.filterOptions = null;
      } else {
        thisRef.flightItemsShow = flightShoppingTools.findSpecificFlightItems(
          thisRef.flightItems,
          tripSequenceNumber,
          thisRef.flightKeyGroups,
          combineKeys
        );

        if (fareOptionsSelected && fareOptionsSelected.length > 0) {
          thisRef.flightItemsShow = thisRef.flightItemsShow.filter(
            (flightItem) => {
              const itineraryFareOptionKey =
                flightShoppingTools.buildItineraryFareOptionKey(
                  fareOptionsSelected
                );
              const itineraryPriceClassKeySplit =
                itineraryFareOptionKey.split("!!");
              const itineraryKey = itineraryFareOptionKey.split(":!")[0];
              const priceClassIdsKey = itineraryPriceClassKeySplit[1];
              const flightFareOptions = thisRef.flightFareOptionsMap[
                flightItem.originDestinationKey
              ].filter((flightFareOption) => {
                return this.fareOptionsIndicator(
                  flightFareOption,
                  filterType,
                  amount,
                  itineraryKey,
                  priceClassIdsKey
                );
              });

              if (!flightFareOptions || flightFareOptions.length === 0)
                return false;

              flightFareOptions.sort((a, b) => a.totalPrice - b.totalPrice);

              flightItem.bestTotalPrice = flightFareOptions[0].totalPrice;
              return true;
            }
          );
        }

        //Filter and Sorting
        thisRef.filterOptions = flightFilterTools.buildFilterOptions(
          thisRef.flightItemsShow
        );
      }
    },
    fareOptionsIndicator(
      flightFareOption,
      filterType,
      amount,
      itineraryKey,
      priceClassIdsKey
    ) {
      let match = false;

      const totalPrice = flightFareOption.totalPrice;

      if (filterType === "UP" && amount && totalPrice < amount) {
        return false;
      } else if (filterType === "DOWN" && amount && totalPrice > amount) {
        return false;
      }
      const prePriceClassIdsKey = flightFareOption.prePriceClassIdsKey;
      if (
        priceClassIdsKey &&
        prePriceClassIdsKey !== priceClassIdsKey &&
        !(
          prePriceClassIdsKey == null &&
          (priceClassIdsKey === "null" || priceClassIdsKey === "undefined")
        )
      )
        return false;
      const itineraryKeyCompare =
        itineraryKey + "#" + flightFareOption.originDestinationKey;
      const itineraryKeyCombine =
        flightFareOption.supplierCode +
        ":" +
        flightFareOption.preItineraryKey +
        "#" +
        flightFareOption.originDestinationKey;
      if (itineraryKeyCompare !== itineraryKeyCombine) {
        return false;
      } else {
        match = true;
      }
      return match;
    },
    changeFareOptionsShow(
      fareOptionsSelected,
      supplierCodes,
      originDestinationKey,
      amount,
      filterType
    ) {
      const thisRef = this;
      // if (!thisRef.flightFareOptions || thisRef.flightFareOptions.length === 0)
      //   thisRef.flightFareOptionsShow = null;
      if (
        !thisRef.flightFareOptionsMap ||
        Object.keys(thisRef.flightFareOptionsMap).length === 0
      )
        thisRef.flightFareOptionsShow = null;

      if (fareOptionsSelected && fareOptionsSelected.length > 0) {
        const itineraryFareOptionKey =
          flightShoppingTools.buildItineraryFareOptionKey(fareOptionsSelected);
        const itineraryKey = itineraryFareOptionKey.split(":!")[0];
        const itineraryPriceClassKeySplit = itineraryFareOptionKey.split("!!");
        const priceClassIdsKey = itineraryPriceClassKeySplit[1];

        thisRef.flightFareOptionsShow = thisRef.flightFareOptionsMap[
          originDestinationKey
        ].filter((flightFareOption) => {
          return this.fareOptionsIndicator(
            flightFareOption,
            filterType,
            amount,
            itineraryKey,
            priceClassIdsKey
          );
        });
      } else {
        thisRef.flightFareOptionsShow =
          flightShoppingTools.findSpecificFlightFareOptions(
            thisRef.flightFareOptionsMap[originDestinationKey],
            supplierCodes,
            originDestinationKey,
            amount,
            filterType
          );
      }
    },
    findItineraryRPH(itineraryFareOptionKey) {
      return this.itineraryFareOptionKeyMap[itineraryFareOptionKey];
    },
    findItineraryRPHWithPreItinKey(fareOptionSelected) {
      if (fareOptionSelected) {
        const bookingSource = fareOptionSelected.supplierCode;
        let preItineraryKey = fareOptionSelected.preItineraryKey;
        let originDestinationKey = fareOptionSelected.originDestinationKey;
        const itineratyKeyCombined = preItineraryKey
          ? bookingSource + ":" + preItineraryKey + "#" + originDestinationKey
          : bookingSource + ":" + originDestinationKey;

        let find = Object.keys(this.itineraryFareOptionKeyMap).find(
          (key) => key.indexOf(itineratyKeyCombined) === 0
        );
        return this.itineraryFareOptionKeyMap[find];
      }
    },
    emptyState() {
      const thisRef = this;
      //Basic Info
      thisRef.flightItems = null;
      thisRef.itineraryFareOptionKeyMap = null;
      thisRef.flightKeyGroups = null;
      thisRef.flightFareOptions = null;

      //Default trip flight items show
      thisRef.flightItemsShow = null;

      //Filter and Sorting
      thisRef.filterOptions = null;
    },
  },
});
