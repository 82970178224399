<script setup>
import { email, required } from "@vuelidate/validators";
import { reactive, ref, watch } from "vue";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import * as regexUtils from "@/utils/regexUtils";
import useVuelidate from "@vuelidate/core";
import FlatPickr from "vue-flatpickr-component";
import { useI18n } from "vue-i18n";

import {
  adultNameTitleOptions,
  childNameTitleOptions,
  genderOptions,
  passengerTypeOptions,
} from "@/formmodel/sales/flight/common/options/flightCommonOption";
import dayjs from "dayjs";
import dateUtils from "@/utils/dateUtils";
import DateUtils from "@/utils/dateUtils";
import { useRoute } from "vue-router";
import { useFlightPriceStore } from "@/stores/v2/flight/price/flightPriceStore";
import FlightPassengerPassportFormItem from "@/views/v2/sales/flight/items/form/booking/FlightPassengerPassportFormItem.vue";
import FlightPassengerFfpNumberForm from "@/views/v2/sales/flight/items/form/booking/FlightPassengerFfpNumberForm.vue";
import { Empty, Modal, Select } from "ant-design-vue";

const route = useRoute();
const props = defineProps({
  passengerIndex: {
    type: Number,
  },
  passengerNumber: {},
  passengerModel: {},
  earlyDepartureDate: {},
  latestDepartureDate: {},
  isPassportMandatory: {},
  frequentFlyerProgramOptions: {},
  profileViewItems: {},
  countryOptions: {},
});

const { t } = useI18n();
const ASelect = Select;
const AModal = Modal;
const AEmpty = Empty;

const flightPriceStore = useFlightPriceStore();

const genderOptionsRef = ref(genderOptions);
const adultNameTitleOptionsRef = ref(adultNameTitleOptions);
const childNameTitleOptionsRef = ref(childNameTitleOptions);
//for initiate
const tripAirItemRef = ref(null);
const passengerModelRef = ref(null);
const warnExpireDate = ref(false); //护照过期提示

const passengerTypeOptionsRef = ref(passengerTypeOptions);

//校验相关
const surnameRule = (value) => regexUtils.surnameRegx.test(value);

const givenNameRule = (value) => regexUtils.givenNameRegx.test(value);
const phoneRule = (value) =>
  (regexUtils.numberRegx.test(value) &&
    regexUtils.phoneNumberRegx.test(value)) ||
  !value;

watch(
  () => flightPriceStore.tripAirItem,
  (newValue) => {
    tripAirItemRef.value = newValue;
  },
  { deep: true, immediate: true }
);

watch(
  () => props.passengerModel,
  (newValue) => {
    passengerModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);

// if (!passengerModelRef.value.documents) {
//   passengerModelRef.value.addNewDocument("PASSPORT", null, null, null, null);
// }

watch(
  () => props.isPassportMandatory,
  (newValue) => {
    passengerModelRef.value.addNewDocument(22, null, null, null, null);
    //强制填护照的再添加其它证件的选择
    if (newValue) {
      passengerModelRef.value.addNewDocument(37, null, null, null, null);
    }
  },
  { immediate: true, deep: true }
);

if (!passengerModelRef.value.ffpNumber) {
  passengerModelRef.value.addNewCustLoyalty("AIR", null, "", "");
}
// Use vuelidate
const rulesRef = ref({
  nameTitle: { required },
  gender: { required },
  givenName: { required, givenNameRule },
  surname: { required, surnameRule },
  birthDate: { required },
  phoneNumber: { phoneRule },
  emailAddress: { email },
});

const v$ = useVuelidate(rulesRef.value, passengerModelRef);

const flatPickrLimit = reactive({
  disable: [
    function (date) {
      // return true to disable
      return disableDates(date, props.passengerModel);
    },
  ],
});

function disableDates(time, passenger) {
  let maxAge = null;
  let minAge = null;
  if (passenger.passengerTypeCode === "INF") {
    minAge = 0;
    maxAge = 1;
  } else if (passenger.passengerTypeCode === "CNN") {
    minAge = 2;
    maxAge = 12;
  } else if (passenger.passengerTypeCode === "ADT") {
    minAge = 13;
  }
  let dateLatest = DateUtils.getOffsetDate(
    new Date(props.latestDepartureDate),
    0
  ); //最晚出发时间

  let maxDate = dayjs(dateLatest).subtract(minAge, "year");
  let minDate = dayjs(dateLatest)
    .subtract(maxAge + 1, "year")
    .add(1, "day");

  if (passenger.passengerTypeCode === "INF") {
    maxDate = dateUtils.getOffsetDate(new Date(), 0);
  }

  let compareDate = dayjs(new Date(time).getTime());
  let isBefore = compareDate.isBefore(minDate);
  let isAfter = compareDate.isAfter(maxDate);
  if (passenger.passengerTypeCode === "ADT") {
    isBefore = false;
  }
  return isBefore || isAfter;
}

//region 电话号码
function phoneNumberInput(value, event) {
  let isPhoneNumber =
    regexUtils.phoneNumberRegx.test(value) && regexUtils.numberRegx.test(value);
  if (
    isPhoneNumber &&
    event != null &&
    event.nationalNumber != null &&
    event.nationalNumber != ""
  ) {
    passengerModelRef.value.phoneNumber = event.nationalNumber;
  } else if (
    isPhoneNumber &&
    event != null &&
    event.nationalNumber == null &&
    event.formatted != null &&
    event.formatted !== ""
  ) {
    passengerModelRef.value.phoneNumber = event.formatted;
  } else if (typeof value === "string") {
    passengerModelRef.value.phoneNumber = value;
  }
}

//endregion 电话号码

//region 护照相关
const documentSelectModalOpenRef = ref(false);

function addPassport() {
  documentSelectModalOpenRef.value = true;
}

//endregion

function getPassengerType(typeCode) {
  let passengerType = {};
  if (
    passengerTypeOptionsRef.value &&
    passengerTypeOptionsRef.value.length > 0
  ) {
    passengerType = passengerTypeOptionsRef.value.find(
      (item) => item.code === typeCode
    );
  }

  return passengerType.name;
}

function countryChange(value) {
  passengerModelRef.value.countryAccessCode = value.dialCode;
}

//region select a account
const travellerTypeRef = ref("Select a account");
const documentOptionsRef = ref(null);
const custLoyaltyOptionsRef = ref(null);
const seatPrefRef = ref(null);
const mealPrefRef = ref(null);
const redressNumberRef = ref(null);
const knownTravelerNumberRef = ref(null);
const travellerTypeOptionsRef = ref(null);

watch(
  () => props.profileViewItems,
  (newValue) => {
    travellerTypeOptionsRef.value = buildTravellerTypeOptions(newValue);
  },
  { immediate: true, deep: true }
);

function buildTravellerTypeOptions(profileViewItems) {
  const children = [];
  if (profileViewItems && profileViewItems.length > 0) {
    profileViewItems.forEach((item) => {
      const partyId = item.partyId;
      const travellerName = item.surname + "/" + item.givenName;
      children.push({
        id: partyId,
        name: travellerName,
        birthDate: item.birthDate,
      });
    });
  }

  return [
    {
      id: "Select a account",
      name: "Select a account",
      children: children,
    },
  ];
}

// watch()

function changeTravellerType(partyId) {
  if (props.profileViewItems && props.profileViewItems.length > 0) {
    const profile = props.profileViewItems.find(
      (profile) => profile.partyId === partyId
    );
    if (profile) {
      // ffpNumber,
      passengerModelRef.value.nameTitle = profile.nameTitle;
      passengerModelRef.value.gender = profile.gender;
      passengerModelRef.value.givenName = profile.givenName;
      passengerModelRef.value.surname = profile.surname;
      passengerModelRef.value.birthDate = profile.birthDate;
      passengerModelRef.value.countryAccessCode =
        profile.mobileCountryAccessCode;
      passengerModelRef.value.phoneNumber = profile.mobileNumber;
      passengerModelRef.value.emailAddress = profile.emailAddress;
      passengerModelRef.value.partyId = profile.partyId;

      //可选项
      custLoyaltyOptionsRef.value = profile.custLoyalties;
      documentOptionsRef.value = profile.documents;
      seatPrefRef.value = profile.seatPref;
      mealPrefRef.value = profile.mealPref;
      redressNumberRef.value = profile.redressNumber;
      knownTravelerNumberRef.value = profile.knownTravelerNumber;
    }
  }
}

function resetFormItem() {
  //清空表单
  passengerModelRef.value.nameTitle = "MR";
  passengerModelRef.value.gender = "MALE";
  passengerModelRef.value.givenName = null;
  passengerModelRef.value.surname = null;
  passengerModelRef.value.birthDate = null;
  passengerModelRef.value.countryAccessCode = null;
  passengerModelRef.value.phoneNumber = null;
  passengerModelRef.value.emailAddress = null;
  passengerModelRef.value.documents = null;
  passengerModelRef.value.addNewDocument(22, "", "", "", "");
  passengerModelRef.value.ffpNumber = {
    programID: "",
    membershipID: "",
  };
  passengerModelRef.value.partyId = null;
  custLoyaltyOptionsRef.value = null;
  documentOptionsRef.value = null;
  seatPrefRef.value = null;
  mealPrefRef.value = null;
  redressNumberRef.value = null;
  knownTravelerNumberRef.value = null;
  travellerTypeRef.value = "Select a account";
}

function filterOptionsByTravellerType(travellerTypeOptions, passenger) {
  if (travellerTypeOptions && travellerTypeOptions.length > 0) {
    return travellerTypeOptions[0].children.filter(
      (traveller) => !disableDates(traveller.birthDate, passenger)
    );
  }
}

function filterDocumentOptions(passengerDocumentOptions, documents) {
  let options = [];
  if (passengerDocumentOptions && passengerDocumentOptions.length > 0) {
    for (const optionsKey in passengerDocumentOptions) {
      const option = passengerDocumentOptions[optionsKey];
      const docType = option.docTypeId;
      const docID = option.docID;
      if (documents && documents.length > 0) {
        const have = documents.some(
          (item) => item.docID === docID && item.docType === docType
        );
        if (!have) {
          options.push(option);
        }
      } else {
        options.push(option);
      }
    }
  }
  return options.filter((item) => item.docType === "PASSPORT");
}

function chooseDocumentToAdd(passengerModel, document) {
  const docID = document.docID;
  const docIssueCountry = document.docIssueCountry;
  const docHolderNationality = document.docHolderNationality;
  const expireDate = document.expireDate;
  const docTypeId = document.docTypeId;
  passengerModel.documents = null;
  passengerModel.addNewDocument(
    docTypeId,
    docID,
    expireDate,
    docIssueCountry,
    docHolderNationality
  );
  documentSelectModalOpenRef.value = false;
}

const loyaltyModifyModalOpenRef = ref(false);

function addMembership() {
  loyaltyModifyModalOpenRef.value = true;
}

function chooseLoyaltyToChange(passengerModel, loyalty) {
  passengerModel.addNewCustLoyalty(
    "AIR",
    loyalty.vendorCode,
    loyalty.programID,
    loyalty.membershipID
  );
  loyaltyModifyModalOpenRef.value = false;
}

function filterLoyaltyOptions(passengerLoyaltyOptions, passengerModel) {
  let membershipID = null;
  let programID = null;
  let vendorCode = null;
  if (passengerModel && passengerModel.ffpNumber) {
    const ffpNumber = passengerModel.ffpNumber;
    vendorCode = ffpNumber.vendorCode;
    programID = ffpNumber.programID;
    membershipID = ffpNumber.membershipID;
  }
  if (passengerLoyaltyOptions && passengerLoyaltyOptions.length > 0) {
    return passengerLoyaltyOptions.filter(
      (item) =>
        item.programID !== programID && item.membershipID !== membershipID
    );
  }
}

//endregion
</script>

<template>
  <div class="row">
    <div class="col-12">
      <span
        v-if="passengerModelRef.travelerRefNumberRPH"
        class="form-label font_weight"
        >{{ $t("traveler") }} {{ passengerIndex }}:
      </span>
      <span class="text-muted">
        {{ getPassengerType(passengerModelRef.passengerTypeCode) }} ({{
          passengerModelRef.travelerRefNumberRPH
        }})</span
      >
    </div>
  </div>
  <div class="row mg-t-10 flight-passenger-info-form">
    <div class="col-lg-12">
      <!-- Form Inline - Default Style -->
      <form class="align-items-center" @sumbit.prevent>
        <div class="row">
          <div
            class="col-12"
            v-if="travellerTypeOptionsRef && travellerTypeOptionsRef.length > 0"
          >
            <label class="form-label" for="example-text-input"
              >Traveller Name
              <small class="text-lowercase"><code>*</code></small></label
            >
          </div>
          <div
            class="col-12 mg-b-10"
            v-if="travellerTypeOptionsRef && travellerTypeOptionsRef.length > 0"
          >
            <a-select
              ref="select"
              style="width: 195px"
              v-model:value="travellerTypeRef"
              :options="
                filterOptionsByTravellerType(
                  travellerTypeOptionsRef,
                  passengerModelRef
                )
              "
              :field-names="{ label: 'name', value: 'id', options: 'children' }"
              @change="changeTravellerType"
            ></a-select>
          </div>
          <div class="col-2">
            <label class="form-label" for="example-text-input"
              >{{ $t("gender") }}
              <small class="text-lowercase"><code>*</code></small></label
            >
            <select
              id="passenger-form-nameTitle"
              v-model="v$.gender.$model"
              :class="{
                'is-invalid': v$.gender.$errors.length > 0,
              }"
              :disabled="passengerModelRef.partyId"
              class="form-control"
              type="text"
            >
              <option
                v-for="(
                  nameTitleOption, nameTitleOptionIndex
                ) in genderOptionsRef"
                :key="nameTitleOptionIndex"
                :value="nameTitleOption.code"
              >
                {{ nameTitleOption.name }}
              </option>
            </select>
          </div>
          <div class="col-2">
            <label class="form-label" for="example-text-input"
              >{{ $t("name-title") }}
              <small class="text-lowercase"><code>*</code></small></label
            >
            <select
              v-if="
                passengerModelRef.passengerTypeCode === 'CNN' ||
                passengerModelRef.passengerTypeCode === 'INF'
              "
              :disabled="passengerModelRef.partyId"
              id="passenger-form-nameTitle"
              v-model="v$.nameTitle.$model"
              :class="{
                'is-invalid': v$.nameTitle.$errors.length > 0,
              }"
              class="form-control"
              type="text"
            >
              <option
                v-for="(
                  nameTitleOption, nameTitleOptionIndex
                ) in childNameTitleOptionsRef"
                :key="nameTitleOptionIndex"
                :value="nameTitleOption.code"
              >
                {{ nameTitleOption.name }}
              </option>
            </select>
            <select
              :disabled="passengerModelRef.partyId"
              v-else
              id="passenger-form-nameTitle"
              v-model="v$.nameTitle.$model"
              :class="{
                'is-invalid': v$.nameTitle.$errors.length > 0,
              }"
              class="form-control"
              type="text"
            >
              <option
                v-for="(
                  nameTitleOption, nameTitleOptionIndex
                ) in adultNameTitleOptionsRef"
                :key="nameTitleOptionIndex"
                :value="nameTitleOption.code"
              >
                {{ nameTitleOption.name }}
              </option>
            </select>
          </div>

          <div class="col-3">
            <label class="form-label" for="example-text-input"
              >{{ $t("last-name") }}
              <small class="text-lowercase"><code>*</code></small></label
            >
            <input
              :disabled="passengerModelRef.partyId"
              id="passenger-form-surname"
              v-model="v$.surname.$model"
              :class="{
                'is-invalid': v$.surname.$errors.length > 0,
              }"
              :placeholder="$t('last-name')"
              autocomplete="off"
              class="form-control"
              name="passenger-form-surname"
              type="text"
              @blur="v$.surname.$touch"
            />
          </div>
          <div class="col-3">
            <label class="form-label" for="example-text-input"
              >{{ $t("first-name") }}
              <small class="text-lowercase"><code>*</code></small></label
            >
            <input
              :disabled="passengerModelRef.partyId"
              id="passenger-form-givenName"
              v-model="v$.givenName.$model"
              :class="{
                'is-invalid': v$.givenName.$errors.length,
              }"
              :placeholder="$t('first-name')"
              autocomplete="off"
              class="form-control"
              name="passenger-form-givenName"
              type="text"
              @blur="v$.givenName.$touch"
            />
          </div>
          <div class="col-2">
            <label class="form-label" for="example-text-input"
              >{{ $t("birth-date") }}
              <small class="text-lowercase"><code>*</code></small></label
            >
            <FlatPickr
              v-if="
                passengerModelRef.passengerTypeCode === 'CNN' ||
                passengerModelRef.passengerTypeCode === 'INF'
              "
              :disabled="passengerModelRef.partyId"
              id="example-flatpickr-default"
              v-model="v$.birthDate.$model"
              :class="{
                'is-invalid': v$.birthDate.$errors.length > 0,
              }"
              :config="flatPickrLimit"
              :placeholder="$t('birth-date')"
              class="form-control"
            />
            <FlatPickr
              v-else
              :disabled="passengerModelRef.partyId"
              id="example-flatpickr-default"
              v-model="v$.birthDate.$model"
              :class="{
                'is-invalid': v$.birthDate.$errors.length > 0,
              }"
              :config="{
                maxDate: 'today',
              }"
              :placeholder="$t('birth-date')"
              class="form-control"
            />
          </div>
        </div>
        <!-- 护照强制-->
        <div v-if="isPassportMandatory" class="row mg-t-5 flight-passport">
          <FlightPassengerPassportFormItem
            v-for="(passportItem, passportIndex) in passengerModelRef.documents"
            :key="passportIndex"
            :country-options="countryOptions"
            :document-item="passportItem"
            :early-departure-date="earlyDepartureDate"
            :is-passport-mandatory="isPassportMandatory"
            :passport-index="passportIndex"
            :party-id="passengerModelRef.partyId"
            @addPassport="addPassport"
          />
        </div>
        <!-- 护照强制END-->
        <div class="row">
          <div class="col-12 text-end">
            <span
              class="mg-r-8 cursor-point text-primary"
              @click="resetFormItem"
            >
              {{ $t("reset") }}
            </span>
            <span
              :data-bs-target="'#collapseExample' + passengerIndex"
              aria-controls="collapseExample"
              aria-expanded="false"
              class="mg-l-20 cursor-point"
              data-bs-toggle="collapse"
            >
              {{ $t("show-more-options") }}
              <i class="fa fa-fw fa-angle-down"></i
            ></span>
          </div>
        </div>
        <div :id="'collapseExample' + passengerIndex" class="collapse">
          <div class="row" v-if="passengerNumber > 1">
            <div class="col-5">
              <label class="form-label" for="example-text-input"
                >{{ $t("phone-number") }}
              </label>
              <VueTelInput
                :disabled="passengerModelRef.partyId"
                :value="passengerModelRef.phoneNumber"
                :autoFormat="false"
                :class="{
                  'is-invalid': v$.phoneNumber.$errors.length > 0,
                }"
                :inputOptions="{
                  placeholder: $t('enter-your-phone-number'),
                  autoFormat: false,
                }"
                autocomplete="off"
                class="form-control flex"
                mode="international"
                @blur="v$.phoneNumber.$touch"
                @input="phoneNumberInput"
                @country-changed="countryChange"
              />
            </div>
            <div class="col-5">
              <label class="form-label" for="example-text-input"
                >{{ $t("email-address") }}
              </label>
              <input
                :disabled="passengerModelRef.partyId"
                id="passenger-form-email"
                v-model="passengerModelRef.emailAddress"
                :class="{
                  'is-invalid': v$.emailAddress.$errors.length > 0,
                }"
                :placeholder="$t('enter-a-email-address')"
                autocomplete="off"
                class="form-control"
                name="passenger-form-email"
                type="email"
                @blur="v$.emailAddress.$touch"
              />
            </div>
          </div>
          <!-- 护照非强制-->
          <div v-if="!isPassportMandatory" class="row mg-t-5 flight-passport">
            <FlightPassengerPassportFormItem
              v-for="(
                passportItem, passportIndex
              ) in passengerModelRef.documents"
              :key="passportIndex"
              :country-options="countryOptions"
              :document-item="passportItem"
              :early-departure-date="earlyDepartureDate"
              :is-passport-mandatory="isPassportMandatory"
              :passport-index="passportIndex"
              :party-id="passengerModelRef.partyId"
              @addPassport="addPassport"
            />
          </div>
          <!-- 护照非强制END-->
          <!-- ffpNumber-->
          <div class="row mg-t-5">
            <FlightPassengerFfpNumberForm
              :frequent-flyer-program-options="frequentFlyerProgramOptions"
              :ffp-numer="passengerModelRef.ffpNumber"
              :party-id="passengerModelRef.partyId"
              @addMembership="addMembership"
            />
          </div>
          <!-- ffpNumber END-->
          <!--          <div class="row mg-t-5">-->
          <!--            <div class="col-lg-5 col-5">-->
          <!--              <label class="form-label" for="example-text-input"-->
          <!--                >{{ $t("redress-number") }}-->
          <!--              </label>-->
          <!--              <input-->
          <!--                id="passenger-form-surname"-->
          <!--                v-model="passengerModelRef.redressNumber"-->
          <!--                :placeholder="$t('enter-redress-number-option')"-->
          <!--                autocomplete="off"-->
          <!--                class="form-control main_input_height"-->
          <!--                name="passenger-form-surname"-->
          <!--                type="text"-->
          <!--              />-->
          <!--            </div>-->
          <!--            <div class="col-lg-5 col-5">-->
          <!--              <label class="form-label" for="example-text-input"-->
          <!--                >{{ $t("known-traveler-number") }}-->
          <!--              </label>-->
          <!--              <input-->
          <!--                id="passenger-form-surname"-->
          <!--                v-model="passengerModelRef.knownTravelerNumber"-->
          <!--                :placeholder="$t('enter-known-traveler-number-option')"-->
          <!--                autocomplete="off"-->
          <!--                class="form-control main_input_height"-->
          <!--                name="passenger-form-surname"-->
          <!--                type="text"-->
          <!--              />-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </form>

      <hr v-if="passengerIndex < passengerNumber" />
      <!-- END Form Inline - Default Style -->
    </div>
  </div>

  <!-- DOCUMENT content-->
  <a-modal
    v-model:open="documentSelectModalOpenRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
    class="payment-method-modal-content"
    width="45%"
    @cancel="documentSelectModalOpenRef = false"
  >
    <div class="mg-t-20">
      <h3>Select a passport for travel</h3>
    </div>
    <div class="mg-t-20 text-center">
      <table
        class="table table-hover table-vcenter"
        v-if="
          filterDocumentOptions(documentOptionsRef) &&
          filterDocumentOptions(documentOptionsRef).length > 0
        "
      >
        <thead>
          <tr class="text-center">
            <th class="fw-semibold fs-sm">ID</th>
            <th>{{ $t("nationality") }}</th>
            <th>{{ $t("expire-date") }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-for="(document, documentIndex) in filterDocumentOptions(
              documentOptionsRef
            )"
            :key="documentIndex"
          >
            <td class="fw-semibold fs-sm">
              <a href="javascript:void(0)">{{ document.docID }}</a>
            </td>
            <td>
              {{ document.docHolderNationality }}
            </td>
            <td>
              {{ document.expireDate }}
            </td>
            <td class="text-center">
              <div class="btn-group">
                <span
                  class="cursor-point text-primary"
                  @click="chooseDocumentToAdd(passengerModelRef, document)"
                >
                  {{ $t("choose") }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-else>
        <AEmpty :description="$t('no-data')"></AEmpty>
      </div>
    </div>
  </a-modal>
  <!--  DOCUMENT content END-->

  <!--  loyalty content-->
  <a-modal
    v-model:open="loyaltyModifyModalOpenRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
    class="payment-method-modal-content"
    width="40%"
    @cancel="loyaltyModifyModalOpenRef = false"
  >
    <div class="mg-t-20">
      <h3>{{ $t("choose-the-loyalty-to-replace") }}</h3>

      <div class="mg-t-20 text-center">
        <table
          class="table table-hover table-vcenter"
          v-if="
            filterLoyaltyOptions(custLoyaltyOptionsRef, passengerModelRef) &&
            filterLoyaltyOptions(custLoyaltyOptionsRef, passengerModelRef)
              .length > 0
          "
        >
          <thead>
            <tr>
              <th>Program ID</th>

              <th>Membership ID</th>

              <th>{{ $t("actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="(loyalty, loyaltyIndex) in filterLoyaltyOptions(
                custLoyaltyOptionsRef,
                passengerModelRef
              )"
              :key="loyaltyIndex"
            >
              <td>
                {{ loyalty.programID }}
              </td>
              <td class="fw-semibold fs-sm">
                <a href="javascript:void(0)">{{ loyalty.membershipID }}</a>
              </td>

              <td class="text-center">
                <div class="btn-group">
                  <span
                    class="text-primary cursor-point"
                    @click="chooseLoyaltyToChange(passengerModelRef, loyalty)"
                  >
                    {{ $t("select") }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-else>
          <AEmpty :description="$t('no-data')"></AEmpty>
        </div>
      </div>
    </div>
  </a-modal>
  <!--  loyalty content END-->
</template>

<style lang="scss">
.vue-tel-input {
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #dfe3ea;
  text-align: left;
}

:where(.css-dev-only-do-not-override-eq3tly).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  width: 100%;
  height: 45px;
  padding: 5px 10px;
  font-size: 18px;
}
</style>
