//region Build Flight Shopping RQ
import { FlightSegmentVO } from "@/viewobject/sales/flight/shopping/flightSegmentVO";
import { FlightItemVO } from "@/viewobject/sales/flight/shopping/flightItemVO";
import { FlightFareOption } from "@/viewobject/sales/flight/shopping/flightFareOption";
import dateUtils from "@/utils/dateUtils";
import { FlightOriginDestinationFormItem } from "@/formmodel/sales/flight/shopping/flightShoppingFormModel";
import FlightPenaltyOptionVO from "@/viewobject/sales/flight/shopping/FlightPenaltyOptionVO";
import FlightFareOptionBuilder from "@/stores/v2/flight/shopping/flightFareOptionBuilder";

function buildFlightShoppingRQ(flightShoppingFormModel) {
  const originDestinations = flightShoppingFormModel.originDestinations;
  const passengers = flightShoppingFormModel.passengers;
  const bestPricingOption = flightShoppingFormModel.bestPricingOption;
  const contentChannel = flightShoppingFormModel.contentChannel;
  const corporateCodes = flightShoppingFormModel.corporateCodes;
  const contractID = flightShoppingFormModel.contractID;
  const tourCode = flightShoppingFormModel.tourCode;
  const fareBasisCodes = flightShoppingFormModel.fareBasisCodes;
  const promotionCode = flightShoppingFormModel.promotionCode;
  const fareTypePrefs = flightShoppingFormModel.fareTypePrefs;
  const servicePrefs = flightShoppingFormModel.servicePrefs;
  const brandedFareOnlyIndicator =
    flightShoppingFormModel.brandedFareOnlyIndicator;

  let refNumIndex = 1;
  const originDestinationInformationList = [];
  const origDestPrefs = [];
  for (const originDestination of originDestinations) {
    const equipPrefs = originDestination.equipPrefs;
    const cabinTypePrefs = originDestination.cabinTypePrefs;
    const airlinePrefs = originDestination.airlinePrefs;
    const stopsPrefs = originDestination.stops;
    const connectionsPrefs = originDestination.connections;
    const originDestinationInformation = buildOriginDestinationInformation(
      originDestination,
      refNumIndex
    );
    originDestinationInformationList.push(originDestinationInformation);

    //暂时只支持参考第一行程搜索条件
    if (origDestPrefs.length === 0) {
      origDestPrefs.push({
        refNumber: originDestinationInformation.refNumber,
        equipPrefs,
        cabinTypePrefs,
        airlinePrefs,
        stopsPrefs,
        connectionsPrefs,
      });
    }

    refNumIndex++;
  }

  //乘客信息,以及常飞计划相关
  const airTravelerAvailList = [];
  let airTravelerAvailWithoutFFPNumber = null;

  if (passengers && passengers.length > 0) {
    const item = passengers.find((item) => item.partyId);
    if (item) {
      //CBT
      airTravelerAvailList.push({
        passengerTypeQuantityList: [
          {
            code: "ADT",
            quantity: passengers.length,
          },
        ],
      });
    } else {
      for (const passenger of passengers) {
        const passengerTypeCode = passenger.passengerTypeCode;
        const quantity = passenger.quantity;

        if (!quantity || quantity === 0) continue;

        //若该乘客类型下无FFPNumber，则共用同一个airTravelerAvailWithoutFFPNumber对象
        let psgTypeLoyaltyAccountQuantity =
          flightShoppingFormModel.countPsgTypeLoyaltyAccountQuantity(
            passengerTypeCode
          );
        if (psgTypeLoyaltyAccountQuantity > 0) {
          let loyaltyAccountsByTypeCode =
            flightShoppingFormModel.findLoyaltyAccountsByTypeCode(
              passengerTypeCode
            );
          const custLoyaltyList = [];
          for (const loyaltyAccount of loyaltyAccountsByTypeCode) {
            let ffpNumberFormat = loyaltyAccount.ffpNumber; //SQ/123456
            custLoyaltyList.push({
              vendorCode: ffpNumberFormat.split("/")[0],
              membershipID: ffpNumberFormat.split("/")[1],
            });
          }

          const airTravelerAvailWithFFPNumber = {
            passengerTypeQuantityList: [
              {
                code: passengerTypeCode,
                quantity: quantity,
              },
            ],
            airTraveler: {
              passengerTypeCode: passengerTypeCode,
              custLoyaltyList: custLoyaltyList,
            },
          };

          airTravelerAvailList.push(airTravelerAvailWithFFPNumber);
        } else if (!airTravelerAvailWithoutFFPNumber) {
          airTravelerAvailWithoutFFPNumber = {
            passengerTypeQuantityList: [
              {
                code: passengerTypeCode,
                quantity: quantity,
              },
            ],
          };
          airTravelerAvailList.push(airTravelerAvailWithoutFFPNumber);
        } else {
          airTravelerAvailWithoutFFPNumber.passengerTypeQuantityList.push({
            code: passengerTypeCode,
            quantity: quantity,
          });
        }
      }
    }
  }

  //Corporate Code
  let negotiatedFareCodeList = null;
  if (tourCode && tourCode !== "") {
    negotiatedFareCodeList = [];
    for (const corporateCodeFormat of corporateCodes) {
      const supplierCode = tourCode.split("/")[0]; //AX/DSC001
      const accountCode = tourCode.split("/")[1];

      negotiatedFareCodeList.push({
        code: accountCode,
        supplierCode: supplierCode,
        codeContext: "TOUR_CODE",
      });
    }
  }

  let accountList = null;
  if (corporateCodes && corporateCodes.length > 0) {
    accountList = [];
    for (const corporateCodeFormat of corporateCodes) {
      const supplierCode = corporateCodeFormat.split("/")[0]; //AX/DSC001
      const accountCode = corporateCodeFormat.split("/")[1];

      accountList.push({
        code: accountCode,
        supplierCode: supplierCode,
        codeContext: "CORPORATE_CODE",
      });
    }
  }

  if (contractID && contractID !== "") {
    if (accountList == null) accountList = [];

    const supplierCode = contractID.split("/")[0]; //AX/DSC001
    const accountCode = contractID.split("/")[1];
    accountList.push({
      code: accountCode,
      supplierCode: supplierCode,
      codeContext: "CONTRACT_ID",
    });
  }

  //偏好
  const travelPreferencesList = [];
  for (const origDestPref of origDestPrefs) {
    let refNumber = origDestPref.refNumber;
    let cabinTypePrefs = origDestPref.cabinTypePrefs;
    let airlinePrefs = origDestPref.airlinePrefs;
    let equipPrefsRequest = origDestPref.equipPrefs;
    let stopsPrefs = origDestPref.stopsPrefs;
    let connectionsPrefs = origDestPref.connectionsPrefs;

    const originDestinationRPHs = [];
    const cabinPrefList = [];
    let vendorPrefs = null;
    let channelPrefs = null;
    let equipPrefs = null;
    let fareRestrictPrefs = null;

    //舱型
    if (cabinTypePrefs && cabinTypePrefs.length > 0) {
      for (const cabinTypePref of cabinTypePrefs) {
        cabinPrefList.push({
          preferLevel: cabinTypePref.preference, //仅支持Preferred
          cabin: cabinTypePref.code,
        });
      }
    } else {
      cabinPrefList.push({
        preferLevel: "Preferred", //仅支持Preferred
        cabin: "YY", //for all
      });
    }

    //航司
    if (airlinePrefs && airlinePrefs.length > 0) {
      vendorPrefs = [];
      for (const airlinePref of airlinePrefs) {
        const code = airlinePref.code;
        const preference =
          airlinePref.preference === "EXCLUDE" ? "Unacceptable" : "Preferred";

        vendorPrefs.push({
          preferLevel: preference,
          code: code,
        });
      }
    }

    //飞机型号
    if (equipPrefsRequest && equipPrefsRequest.length > 0) {
      equipPrefs = [];
      for (const equipPref of equipPrefs) {
        const code = equipPref.code;
        const preference =
          equipPref.preference === "EXCLUDE" ? "Unacceptable" : "Preferred";

        equipPrefs.push({
          preferLevel: preference,
          code: code,
        });
      }
    }

    //机票来源
    if (contentChannel) {
      channelPrefs = [
        {
          code: contentChannel,
        },
      ];
    }

    //基础票价代码
    if (fareBasisCodes && fareBasisCodes.length > 0) {
      if (!fareRestrictPrefs) fareRestrictPrefs = [];
      for (const fareBasisCode of fareBasisCodes) {
        fareRestrictPrefs.push({
          fareBasisCode: fareBasisCode,
        });
      }
    }

    //票价类型
    if (fareTypePrefs && fareTypePrefs.length > 0) {
      if (!fareRestrictPrefs) fareRestrictPrefs = [];
      for (const fareTypePref of fareTypePrefs) {
        fareRestrictPrefs.push({
          fareRestriction: fareTypePref.code,
        });
      }
    }

    let nonStopsOnlyInd = null;
    let maxConnections = null;
    if (!stopsPrefs) {
      nonStopsOnlyInd = null;
      maxConnections = null;
    } else if (stopsPrefs == "-1") {
      nonStopsOnlyInd = true;
      maxConnections = null;
    } else {
      nonStopsOnlyInd = false;
      maxConnections = parseInt(stopsPrefs);
    }
    const travelPreferences = {
      originDestinationRPHs: originDestinationRPHs, //Optional, origionDestination refNum
      cabinPrefList: cabinPrefList,
      flightTypePrefs: [
        {
          maxConnections: maxConnections,
          nonStopsOnlyInd: nonStopsOnlyInd, //无需停靠
        },
      ],
      vendorPrefs: vendorPrefs,
      channelPrefs: channelPrefs,
      //先选择航空公司，再能选择fare type、fareBasisCode
      fareRestrictPrefs: fareRestrictPrefs,
      equipPrefs: equipPrefs,
      brandedFareOnlyIndicator: brandedFareOnlyIndicator,
      bestPricingOption: bestPricingOption,
    };

    travelPreferencesList.push(travelPreferences);
  }

  //服务
  let multiModalOffer = null;
  if (servicePrefs && servicePrefs.length > 0) {
    const offers = [];
    for (const servicePref of servicePrefs) {
      let code = servicePref.code;
      const preference = servicePref.preference === "EXCLUDE" ? true : false;
      offers.push({
        excludedInd: preference, // false for including, true for excluding， default is false
        offerCode: code, //Checked baggage, IATA taxonomy
      });
    }

    multiModalOffer = {
      requestedOffer: {
        offerTypes: {
          offers: offers,
        },
      },
    };
  }

  return {
    processingInfo: {
      reducedDataIndicator: true,
    },
    originDestinationInformationList: originDestinationInformationList,
    travelerInfoSummary: {
      airTravelerAvailList: airTravelerAvailList,
      priceRequestInformation: {
        discountPricing: {
          ticketDesignatorCode: promotionCode,
        },
        accountList: accountList,
        negotiatedFareCodeList: negotiatedFareCodeList,
      },
    },
    travelPreferencesList: travelPreferencesList,
    //Optinal， 可选
    multiModalOffer: multiModalOffer,
  };
}

//endregion Build Flight Shopping RQ

/**
 * 构建 ItineraryArrangement
 * @param flightShoppingFormModel
 * @returns {{flights: []}}
 */
function buildItineraryArrangement(flightShoppingFormModel) {
  const originDestinations = flightShoppingFormModel.originDestinations;

  const itineraryArrangement = {
    flights: [],
  };
  for (const originDestination of originDestinations) {
    const refNumber = originDestination.refNumber;
    const originLocationCode = originDestination.originLocationCode;
    const destinationLocationCode = originDestination.destinationLocationCode;
    const departureDate = originDestination.departureDate;

    const flightOriginDestinationFormItem = new FlightOriginDestinationFormItem(
      refNumber,
      originLocationCode,
      null,
      destinationLocationCode,
      null,
      null,
      null,
      null,
      departureDate
    );
    itineraryArrangement.flights.push(flightOriginDestinationFormItem);
  }

  return itineraryArrangement;
}

function buildOriginDestinationInformation(originDestination, refNumIndex) {
  const refNumber =
    originDestination.refNumber != null
      ? originDestination.refNumber
      : refNumIndex;
  const originLocationCode = originDestination.originLocationCode;
  const originLocationName = originDestination.originLocationName;
  const destinationLocationCode = originDestination.destinationLocationCode;
  const destinationLocationName = originDestination.destinationLocationName;
  const departureDate = originDestination.departureDate;
  const departureTime =
    originDestination.departureTime != null
      ? originDestination.departureTime
      : "00:00";
  const departureDateTime = departureDate + "T" + departureTime;
  const connections = originDestination.connections;
  const stops = originDestination.stops;
  const departureTimeWindowBefore = originDestination.departureTimeWindowBefore;
  const departureTimeWindowAfter = originDestination.departureTimeWindowAfter;
  const daysBeforeDeparture = originDestination.daysBeforeDeparture;
  const daysAfterDeparture = originDestination.daysAfterDeparture;
  const arrivalDate = originDestination.arrivalDate;
  const arrivalTime =
    originDestination.arrivalTime != null
      ? originDestination.arrivalTime
      : "00:00";
  const arrivalDateTime =
    arrivalDate != null && arrivalTime != null
      ? arrivalDate + "T" + arrivalTime
      : null;

  return {
    departureDateTime: departureDateTime,
    departureDateTimeWindowBefore: departureTimeWindowBefore,
    departureDateTimeWindowAfter: departureTimeWindowAfter,
    daysBeforeNumber: daysBeforeDeparture,
    daysAfterNumber: daysAfterDeparture,
    arrivalDateTime: arrivalDateTime,
    originLocation: {
      locationCode: originLocationCode,
    },
    destinationLocation: {
      locationCode: destinationLocationCode,
    },
    refNumber: refNumber,
  };
}

//region FlightItems
function buildFlightItems(airLowFareSearchRS) {
  if (
    !airLowFareSearchRS ||
    !airLowFareSearchRS.pricedItineraryList ||
    airLowFareSearchRS.pricedItineraryList.length === 0
  )
    return null;

  const pricedItineraryList = airLowFareSearchRS.pricedItineraryList;
  const airItineraryMap = airLowFareSearchRS.airItineraryMap;
  const flightSegmentMap = airLowFareSearchRS.flightSegmentMap;

  const flightItemMap = {};
  for (const pricedItinerary of pricedItineraryList) {
    const airItineraryKey = pricedItinerary.rph;
    const airItinerary = airItineraryMap[airItineraryKey];

    const originDestinationOptions = airItinerary.originDestinationOptions;
    const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
    const score = pricedItinerary.score;
    const itinTotalFare = airItineraryPricingInfo.itinTotalFares[0];
    const totalFare = itinTotalFare.totalFare;
    const totalFareCurrencyCode = totalFare.currencyCode;
    const totalFareAmount = totalFare.amount;

    const bookingSourceType = pricedItinerary.bookingSourceType;
    const reasons = pricedItinerary.reasons;
    const bookingSource = pricedItinerary.bookingSource;
    const supplierCode = bookingSource
      ? bookingSource
      : airItineraryPricingInfo.pricingSource;

    for (let i = 0; i < originDestinationOptions.length; i++) {
      const originDestinationOption = originDestinationOptions[i];
      const flightSegmentKeyList = originDestinationOption.flightSegmentKeyList;
      const originDestinationKey = flightSegmentKeyList.join("+");
      if (flightItemMap[originDestinationKey]) {
        const flightItem = flightItemMap[originDestinationKey];
        if (flightItem.bestTotalPrice > totalFareAmount)
          flightItem.bestTotalPrice = totalFareAmount; //同一航班组合的最低价

        if (flightItem.bookingSourceTypes.indexOf(bookingSourceType) < 0)
          flightItem.bookingSourceTypes.push(bookingSourceType);

        if (flightItem.supplierCodes.indexOf(supplierCode) < 0)
          flightItem.supplierCodes.push(supplierCode);

        continue;
      }
      const tripSequenceNumber = i;
      const originLocationCode = originDestinationOption.originLocation;
      const destinationLocationCode =
        originDestinationOption.destinationLocation;
      const connections = originDestinationOption.connections;
      let numStops = originDestinationOption.numStops;
      const flightSegments = [];
      for (const flightSegmentKey of flightSegmentKeyList) {
        const flightSegment = flightSegmentMap[flightSegmentKey];
        flightSegments.push(flightSegment);
      }

      let durationInMins = originDestinationOption.durationInMins;
      let originLocationName = null;
      let departureAirportCodeFlight = null;
      let destinationLocationName = null;
      let arrivalAirportCodeFlight = null;
      let departureDateTimeFlight = null;
      let arrivalDateTimeFlight = null;

      const flightSegmentVOs = [];
      flightSegments.sort((a, b) => {
        return (
          new Date(a.departureDateTime).getTime() -
          new Date(b.departureDateTime).getTime()
        );
      });
      for (const flightSegment of flightSegments) {
        const departureDateTime = flightSegment.departureDateTime;
        const arrivalDateTime = flightSegment.arrivalDateTime;
        const segmentKey = flightSegment.segmentKey;
        const flightNumber = flightSegment.flightNumber;
        const fareBasisCode = flightSegment.fareBasisCode;
        const fareTypeName = flightSegment.fareTypeName;
        const resBookDesigCode = flightSegment.resBookDesigCode;
        const numberInParty = flightSegment.numberInParty;
        const stopoverInd = flightSegment.stopoverInd;
        const departureAirportCode = flightSegment.departureAirportCode;
        const arrivalAirportCode = flightSegment.arrivalAirportCode;
        const operatingAirlineCode = flightSegment.operatingAirlineCode;
        const operatingAirlineName = flightSegment.operatingAirlineName;
        const equipmentCode = flightSegment.equipmentCode;
        const marketingAirlineCode = flightSegment.marketingAirlineCode;
        const cabinClass = flightSegment.cabinClass;
        // const baggageAllowance = flightSegment.baggageAllowance;
        const baggageAllowance = null;
        const rph = flightSegment.rph;

        const flightSegmentVO = new FlightSegmentVO(
          departureDateTime,
          arrivalDateTime,
          segmentKey,
          flightNumber,
          fareBasisCode,
          fareTypeName,
          resBookDesigCode,
          numberInParty,
          stopoverInd,
          departureAirportCode,
          arrivalAirportCode,
          operatingAirlineCode,
          operatingAirlineName,
          equipmentCode,
          marketingAirlineCode,
          cabinClass,
          baggageAllowance,
          rph
        );

        flightSegmentVOs.push(flightSegmentVO);

        if (!departureDateTimeFlight) {
          departureDateTimeFlight = departureDateTime;
          departureAirportCodeFlight = departureAirportCode;
        }
        arrivalDateTimeFlight = arrivalDateTime;
        arrivalAirportCodeFlight = arrivalAirportCode;
      }

      if (!durationInMins) {
        durationInMins = dateUtils.calcDurationInMinsWithTimeZone(
          departureDateTimeFlight,
          arrivalDateTimeFlight
        );
      }

      if (!numStops) {
        numStops = flightSegmentVOs.length - 1;
      }

      const flightItemVO = new FlightItemVO(
        null,
        tripSequenceNumber,
        originLocationCode,
        originLocationName,
        departureAirportCodeFlight,
        destinationLocationCode,
        destinationLocationName,
        arrivalAirportCodeFlight,
        departureDateTimeFlight,
        arrivalDateTimeFlight,
        null,
        originDestinationKey,
        durationInMins,
        connections,
        numStops,
        totalFareCurrencyCode,
        totalFareAmount,
        null,
        null,
        null,
        flightSegmentVOs,
        score
      );
      flightItemVO.addBookingSourceType(bookingSourceType);
      flightItemVO.addSupplierCode(supplierCode);
      flightItemMap[originDestinationKey] = flightItemVO;
    }
  }
  return Object.values(flightItemMap);
}

function buildItineraryFareOptionKeyMap(airLowFareSearchRS) {
  if (
    !airLowFareSearchRS ||
    !airLowFareSearchRS.pricedItineraryList ||
    airLowFareSearchRS.pricedItineraryList.length === 0
  )
    return null;

  const pricedItineraryList = airLowFareSearchRS.pricedItineraryList;
  const itineraryFareOptionKeyMap = {};
  for (const pricedItinerary of pricedItineraryList) {
    const airItineraryRPH = pricedItinerary.airItineraryRPH;
    const airItineraryKey = pricedItinerary.rph;
    const bookingSource = pricedItinerary.bookingSource;
    const offer = pricedItinerary.offer;
    const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
    const itinTotalFare = airItineraryPricingInfo.itinTotalFares[0];
    const totalFare = itinTotalFare.totalFare;
    const totalFareCurrencyCode = totalFare.currencyCode;
    const totalFareAmount = totalFare.amount;

    const supplierCode =
      airItineraryPricingInfo.priceRequestInformation &&
      airItineraryPricingInfo.priceRequestInformation.negotiatedFareCodeList
        ? airItineraryPricingInfo.priceRequestInformation
            .negotiatedFareCodeList[0].supplierCode
        : airItineraryPricingInfo.pricingSource;

    let key =
      supplierCode +
      ":" +
      airItineraryKey +
      ":!" +
      totalFareCurrencyCode +
      totalFareAmount;

    //OD分组
    let prePriceClassIdMap = {};
    if (offer && offer.summary && offer.summary.length > 0) {
      const summary = offer.summary;
      key += "!!";

      let priceClassIdGroup = [];
      for (const summaryEle of summary) {
        const priceClassId = summaryEle.bundleID;
        const originDestinationKey = summaryEle.origDestRPH;
        if (!priceClassId) continue;

        //遍历到没有该OD的则直接push，如果已经有该OD的则将其用@@连接替换
        if (prePriceClassIdMap[originDestinationKey]) {
          let combinePriceClassId =
            priceClassIdGroup[priceClassIdGroup.length - 1] +
            "@@" +
            priceClassId;
          priceClassIdGroup.splice(priceClassIdGroup.length - 1);
          priceClassIdGroup.push(combinePriceClassId);
        } else {
          priceClassIdGroup.push(priceClassId);
          prePriceClassIdMap[originDestinationKey] = 1;
        }
      }

      key += priceClassIdGroup.join(":");
    } else {
      key += "!!";
    }
    itineraryFareOptionKeyMap[key] = airItineraryRPH;
  }

  return itineraryFareOptionKeyMap;
}

function findAllAirportCodes(flightItems) {
  if (!flightItems || flightItems.length == null) return [];

  const airportCodes = [];
  for (const flightItem of flightItems) {
    const arrivalAirportCode = flightItem.arrivalAirportCode;
    const departureAirportCode = flightItem.departureAirportCode;
    if (airportCodes.indexOf(arrivalAirportCode) < 0)
      airportCodes.push(arrivalAirportCode);
    if (airportCodes.indexOf(departureAirportCode) < 0)
      airportCodes.push(departureAirportCode);
    const flightSegments = flightItem.flightSegments;
    if (flightSegments && flightSegments.length > 0) {
      for (const flightSegment of flightSegments) {
        const segArrivalAirportCode = flightSegment.arrivalAirportCode;
        const segDepartureAirportCode = flightSegment.departureAirportCode;
        if (airportCodes.indexOf(segArrivalAirportCode) < 0)
          airportCodes.push(segArrivalAirportCode);
        if (airportCodes.indexOf(segDepartureAirportCode) < 0)
          airportCodes.push(segDepartureAirportCode);
      }
    }
  }
  return airportCodes;
}

function findAllNecessaryCodes(flightItems) {
  const necessaryCodes = {
    airportCodes: [],
    airlineCodes: [],
    aircraftCodes: [],
  };

  if (!flightItems || flightItems.length == null) return necessaryCodes;

  const airportCodes = [];
  const airlineCodes = [];
  const aircraftCodes = [];
  for (const flightItem of flightItems) {
    const arrivalAirportCode = flightItem.arrivalAirportCode;
    const destinationLocationCode = flightItem.destinationLocationCode;
    if (airportCodes.indexOf(arrivalAirportCode) < 0)
      airportCodes.push(arrivalAirportCode);
    if (airportCodes.indexOf(destinationLocationCode) < 0)
      airportCodes.push(destinationLocationCode);

    for (const flightSegment of flightItem.flightSegments) {
      const equipmentCode = flightSegment.equipmentCode;
      const operatingAirlineCode = flightSegment.operatingAirlineCode;
      const marketingAirlineCode = flightSegment.marketingAirlineCode;

      if (airlineCodes.indexOf(operatingAirlineCode) < 0)
        airlineCodes.push(operatingAirlineCode);
      if (airlineCodes.indexOf(marketingAirlineCode) < 0)
        airlineCodes.push(marketingAirlineCode);
      if (aircraftCodes.indexOf(equipmentCode) < 0)
        aircraftCodes.push(equipmentCode);
    }
  }

  necessaryCodes.airportCodes = airportCodes;
  necessaryCodes.airlineCodes = airlineCodes;
  necessaryCodes.aircraftCodes = aircraftCodes;

  return necessaryCodes;
}

function buildFlightKeyGroups(airItineraryMap) {
  return Object.keys(airItineraryMap);
}

function findSpecificFlightKeyGropus(flightKeyGroups, originDestinationKey) {
  if (!flightKeyGroups) return null;
  return flightKeyGroups.filter(
    (key) => key.indexOf(originDestinationKey) === 0
  );
}

/**
 *
 * @param flightItems
 * @param tripSequenceNumber
 * @param flightKeyGroups
 * @param airItineraryKeyPrefix string，已选择航班的关键信息组合
 * @returns {*[]}
 */
function findSpecificFlightItems(
  flightItems,
  tripSequenceNumber,
  flightKeyGroups,
  airItineraryKeyPrefix
) {
  const specificFlightItems = [];
  const originDestinationKeyMap = {};
  const flightItemsCopy = JSON.parse(JSON.stringify(flightItems));

  for (const flightItem of flightItemsCopy) {
    if (flightItem.tripSequenceNumber !== tripSequenceNumber) continue;
    const originDestinationKey = flightItem.originDestinationKey;
    if (
      airItineraryKeyPrefix &&
      !flightKeyGroups.filter(
        (group) =>
          group.indexOf(airItineraryKeyPrefix + "#" + originDestinationKey) !==
          -1
      )
    ) {
      continue;
    }

    specificFlightItems.push(flightItem);
    originDestinationKeyMap[originDestinationKey] = specificFlightItems.length;
  }

  return specificFlightItems;
}

//endregion FlightItems

//region FlightFareOptions
function buildFlightFareOptionsMap(airLowFareSearchRS) {
  if (
    !airLowFareSearchRS ||
    !airLowFareSearchRS.pricedItineraryList ||
    airLowFareSearchRS.pricedItineraryList.length === 0
  )
    return null;

  const pricedItineraryList = airLowFareSearchRS.pricedItineraryList;
  const airItineraryMap = airLowFareSearchRS.airItineraryMap;
  const fareInfoMap = airLowFareSearchRS.fareInfoMap;
  const passengerFareMap = airLowFareSearchRS.passengerFareMap;
  const voluntaryChangesMap = airLowFareSearchRS.voluntaryChangesMap;

  const flightFareOptionsMap = {};
  const flightFareOptionKeyMap = {};
  for (const pricedItinerary of pricedItineraryList) {
    const airItineraryKey = pricedItinerary.rph;
    const offer = pricedItinerary.offer;

    const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
    const approvalRequired = pricedItinerary.approvalRequired;
    const notes = pricedItinerary.notes;
    const outOfPolicy = pricedItinerary.outOfPolicy;
    const upgradeAllowed = pricedItinerary.upgradeAllowed;
    const reasons = pricedItinerary.reasons;

    const ptcFareBreakdowns = airItineraryPricingInfo.PTC_FareBreakdowns;
    const fareRuleInfos =
      airItineraryPricingInfo.fareInfoList &&
      airItineraryPricingInfo.fareInfoList.length > 0 &&
      airItineraryPricingInfo.fareInfoList[0]
        ? airItineraryPricingInfo.fareInfoList[0].ruleInfos
        : null;
    const itinTotalFare = airItineraryPricingInfo.itinTotalFares[0];
    const totalFare = itinTotalFare.totalFare;
    const totalFareCurrencyCode = totalFare.currencyCode;
    const totalFareAmount = totalFare.amount;

    const bookingSourceType = pricedItinerary.bookingSourceType;
    const supplierCode =
      airItineraryPricingInfo.priceRequestInformation &&
      airItineraryPricingInfo.priceRequestInformation.negotiatedFareCodeList
        ? airItineraryPricingInfo.priceRequestInformation
            .negotiatedFareCodeList[0].supplierCode
        : airItineraryPricingInfo.pricingSource;
    let priceExpirationDatetime = null;
    if (
      airItineraryPricingInfo.tpaExtensions &&
      airItineraryPricingInfo.tpaExtensions.length > 0 &&
      airItineraryPricingInfo.tpaExtensions[0] &&
      airItineraryPricingInfo.tpaExtensions[0].valueMap
    ) {
      const valueMap = airItineraryPricingInfo.tpaExtensions[0].valueMap;
      priceExpirationDatetime = valueMap.priceExpirationDatetime;
    }

    if (!offer || !offer.summary) {
      const airItinerary = airItineraryMap[airItineraryKey];
      const originDestinationOptions = airItinerary.originDestinationOptions;
      for (const originDestinationOption of originDestinationOptions) {
        const originDestinationKey = originDestinationOption.rph;
        const airItineraryKeySplit =
          airItineraryKey.split(originDestinationKey)[0];
        const value =
          airItineraryKeySplit[0] == null || airItineraryKeySplit[0] === ""
            ? null
            : airItineraryKey.split("#" + originDestinationKey)[0];
        const preItineraryKey = value && value !== "" ? value : null;
        const flightFareOption = new FlightFareOption(
          supplierCode,
          bookingSourceType,
          null,
          null,
          preItineraryKey,
          originDestinationKey,
          totalFareCurrencyCode,
          totalFareAmount,
          null,
          null,
          null,
          null,
          null,
          null,
          priceExpirationDatetime,
          outOfPolicy,
          approvalRequired,
          upgradeAllowed,
          reasons,
          null,
          notes,
          null,
          null,
          null,
          null
        );

        for (const ptcFareBreakdown of ptcFareBreakdowns) {
          const fareBasisCodes = ptcFareBreakdown.fareBasisCodes;
          const fareInfoList = ptcFareBreakdown.fareInfoList;
          const passengerFare = ptcFareBreakdown.passengerFareList[0];
          const passengerFareDetails = passengerFareMap
            ? passengerFareMap[passengerFare.rph]
            : null;
          if (fareBasisCodes) {
            for (const fareBasisCode of fareBasisCodes) {
              const flightSegmentRPH = fareBasisCode.flightSegmentRPH;

              if (originDestinationKey.indexOf(flightSegmentRPH) < 0) continue;

              const fareBasisCodeStringValue =
                fareBasisCode.fareBasisCodeStringValue;
              flightFareOption.addFareBasisCode(fareBasisCodeStringValue);
              flightFareOption.addFareSegmentBasisCode({
                fareBasisCode: fareBasisCodeStringValue,
                flightRefNumber: flightSegmentRPH,
              }); //包含航段信息
            }
          }

          if (fareInfoList) {
            for (const fareInfo of fareInfoList) {
              const fareInfoRPH = fareInfo.rph;
              const fareInfoDetails = fareInfoMap[fareInfoRPH];
              if (!fareInfoDetails) continue;

              const negotiatedFareCode = fareInfoDetails.negotiatedFareCode;
              flightFareOption.addNegotiatedFareCode(negotiatedFareCode);

              const fareReferences = fareInfoDetails.fareReferences;
              if (!fareReferences) continue;
              for (const fareReference of fareReferences) {
                const flightSegmentRPH = fareReference.flightSegmentRPH;
                if (originDestinationKey.indexOf(flightSegmentRPH) < 0)
                  continue;

                const cabinType = fareReference.cabinType;
                const resBookDesigCode = fareReference.resBookDesigCode;

                if (resBookDesigCode)
                  flightFareOption.addBookingClassCode(resBookDesigCode);
                if (cabinType) flightFareOption.addCabinType(cabinType);
              }
            }
          }

          if (
            passengerFareDetails &&
            passengerFareDetails.fareBaggageAllowanceList &&
            passengerFareDetails.fareBaggageAllowanceList.length > 0
          ) {
            for (const fareBaggageAllowance of passengerFareDetails.fareBaggageAllowanceList) {
              const flightSegmentRPH = fareBaggageAllowance.flightSegmentRPH;
              if (originDestinationKey.indexOf(flightSegmentRPH) < 0) continue;

              const unitOfMeasureQuantity =
                fareBaggageAllowance.unitOfMeasureQuantity;
              const unitOfMeasure =
                fareBaggageAllowance.unitOfMeasure.toLowerCase() === "kg"
                  ? "Kg"
                  : "Piece(s)";

              flightFareOption.addBaggageAllowances(
                unitOfMeasureQuantity + " " + unitOfMeasure
              );
            }
          }
        }

        //Penalty Info
        if (fareRuleInfos && fareRuleInfos.length > 0) {
          fareRuleInfos.forEach((ruleInfo) => {
            const fareRuleKey = ruleInfo.fareRuleKey;
            const chargesRules = ruleInfo.chargesRules;
            if (chargesRules) {
              const voluntaryChangesKey =
                chargesRules.voluntaryChanges &&
                chargesRules.voluntaryChanges.voluntaryChangesKey
                  ? chargesRules.voluntaryChanges.voluntaryChangesKey
                  : null;
              const voluntaryRefundsKey =
                chargesRules.voluntaryRefunds &&
                chargesRules.voluntaryRefunds.voluntaryChangesKey
                  ? chargesRules.voluntaryRefunds.voluntaryChangesKey
                  : null;

              const voluntaryChanges = voluntaryChangesMap[voluntaryChangesKey];
              const voluntaryRefunds = voluntaryChangesMap[voluntaryRefundsKey];
              if (voluntaryChanges && voluntaryChanges.penalty) {
                const penaltyType = voluntaryChanges.penalty.penaltyType;
                const departureStatus =
                  voluntaryChanges.penalty.departureStatus;
                const currencyCode =
                  voluntaryChanges && voluntaryChanges.penalty
                    ? voluntaryChanges.penalty.currencyCode
                    : null;
                const amount = voluntaryChanges.penalty.amount;
                const percent = voluntaryChanges.penalty.percent;
                const volChangeInd = voluntaryChanges.volChangeInd;
                const flightPenaltyOptionVO = new FlightPenaltyOptionVO(
                  volChangeInd,
                  fareRuleKey,
                  penaltyType,
                  departureStatus,
                  percent,
                  amount,
                  currencyCode
                );
                flightFareOption.addPenaltyOption(flightPenaltyOptionVO);
              }
              if (voluntaryRefunds && voluntaryRefunds.penalty) {
                const penaltyType = voluntaryRefunds.penalty.penaltyType;
                const departureStatus =
                  voluntaryRefunds.penalty.departureStatus;
                const currencyCode =
                  voluntaryChanges && voluntaryChanges.penalty
                    ? voluntaryChanges.penalty.currencyCode
                    : null;
                const amount = voluntaryRefunds.penalty.amount;
                const percent = voluntaryRefunds.penalty.percent;
                const volChangeInd = voluntaryRefunds.volChangeInd;

                const flightPenaltyOptionVO = new FlightPenaltyOptionVO(
                  volChangeInd,
                  fareRuleKey,
                  penaltyType,
                  departureStatus,
                  percent,
                  amount,
                  currencyCode
                );

                flightFareOption.addPenaltyOption(flightPenaltyOptionVO);
              }
            }
          });
        }

        const itineraryKeyCombine =
          flightFareOption.supplierCode +
          ":" +
          flightFareOption.preItineraryKey +
          "#" +
          flightFareOption.originDestinationKey +
          ":" +
          null +
          ":" +
          totalFareAmount;

        if (flightFareOptionKeyMap[itineraryKeyCombine]) {
          continue;
        } else {
          flightFareOptionKeyMap[itineraryKeyCombine] = 1;
        }

        if (flightFareOptionsMap[originDestinationKey]) {
          flightFareOptionsMap[originDestinationKey].push(flightFareOption); //该行程下面的所有价格
        } else {
          flightFareOptionsMap[originDestinationKey] = [];
          flightFareOptionsMap[originDestinationKey].push(flightFareOption);
        }
      }
    } else {
      let flightFareOption = null;
      const summary = offer.summary;
      const preClassIdArr = [];
      const segmentPriceClass = [];

      //OD分组
      let preFareOptionMap = {};

      for (const summaryEle of summary) {
        const priceClassId = summaryEle.bundleID;
        const originDestinationKey = summaryEle.origDestRPH;
        const flightSegmentRPH = summaryEle.flightSegmentRPH;
        const bundleID = summaryEle.bundleID;
        segmentPriceClass.push({ flightSegmentRPH, bundleID });
        const prePriceClassIdsKey =
          preClassIdArr.length === 0 ? null : preClassIdArr.join(":");

        if (preFareOptionMap[originDestinationKey]) {
          const prePriceClassId =
            preFareOptionMap[originDestinationKey].priceClassId;
          let combinePriceClassId = prePriceClassId + "@@" + priceClassId;
          preFareOptionMap[originDestinationKey].priceClassId =
            combinePriceClassId;
          //todo 修改之前行程的priceClassId
          preClassIdArr.splice(preClassIdArr.length - 1);
          preClassIdArr.push(combinePriceClassId);
        } else {
          const airItineraryKeySplit =
            airItineraryKey.split(originDestinationKey)[0];
          const value =
            airItineraryKeySplit[0] == null || airItineraryKeySplit[0] === ""
              ? null
              : airItineraryKey.split("#" + originDestinationKey)[0];
          const preItineraryKey = value && value !== "" ? value : null;
          flightFareOption = new FlightFareOption(
            supplierCode,
            bookingSourceType,
            priceClassId,
            prePriceClassIdsKey,
            preItineraryKey,
            originDestinationKey,
            totalFareCurrencyCode,
            totalFareAmount,
            null,
            null,
            null,
            null,
            null,
            null,
            priceExpirationDatetime,
            outOfPolicy,
            approvalRequired,
            upgradeAllowed,
            reasons,
            null,
            notes,
            null,
            null,
            null,
            null
          );

          for (const ptcFareBreakdown of ptcFareBreakdowns) {
            const fareBasisCodes = ptcFareBreakdown.fareBasisCodes;
            const fareInfoList = ptcFareBreakdown.fareInfoList;
            const passengerFare = ptcFareBreakdown.passengerFareList[0];
            const passengerFareDetails = passengerFareMap
              ? passengerFareMap[passengerFare.rph]
              : null;
            if (fareBasisCodes) {
              for (const fareBasisCode of fareBasisCodes) {
                const flightSegmentRPH = fareBasisCode.flightSegmentRPH;

                if (originDestinationKey.indexOf(flightSegmentRPH) < 0)
                  continue;

                const fareBasisCodeStringValue =
                  fareBasisCode.fareBasisCodeStringValue;
                flightFareOption.addFareBasisCode(fareBasisCodeStringValue);
                flightFareOption.addFareSegmentBasisCode({
                  fareBasisCode: fareBasisCodeStringValue,
                  flightRefNumber: flightSegmentRPH,
                }); //包含航段信息
              }
            }

            if (fareInfoList) {
              for (const fareInfo of fareInfoList) {
                const fareInfoRPH = fareInfo.rph;
                const fareInfoDetails = fareInfoMap[fareInfoRPH];
                if (!fareInfoDetails) continue;

                const negotiatedFareCode = fareInfoDetails.negotiatedFareCode;
                flightFareOption.addNegotiatedFareCode(negotiatedFareCode);

                const fareReferences = fareInfoDetails.fareReferences;
                if (!fareReferences) continue;
                for (const fareReference of fareReferences) {
                  const flightSegmentRPH = fareReference.flightSegmentRPH;
                  if (originDestinationKey.indexOf(flightSegmentRPH) < 0)
                    continue;

                  const cabinType = fareReference.cabinType;
                  const resBookDesigCode = fareReference.resBookDesigCode;

                  if (resBookDesigCode)
                    flightFareOption.addBookingClassCode(resBookDesigCode);
                  if (cabinType) flightFareOption.addCabinType(cabinType);
                }
              }
            }

            if (
              passengerFareDetails &&
              passengerFareDetails.fareBaggageAllowanceList &&
              passengerFareDetails.fareBaggageAllowanceList.length > 0
            ) {
              for (const fareBaggageAllowance of passengerFareDetails.fareBaggageAllowanceList) {
                const flightSegmentRPH = fareBaggageAllowance.flightSegmentRPH;
                if (originDestinationKey.indexOf(flightSegmentRPH) < 0)
                  continue;

                const unitOfMeasureQuantity =
                  fareBaggageAllowance.unitOfMeasureQuantity;
                const unitOfMeasure =
                  fareBaggageAllowance.unitOfMeasure.toLowerCase() === "kg"
                    ? "Kg"
                    : "Piece(s)";

                flightFareOption.addBaggageAllowances(
                  unitOfMeasureQuantity + " " + unitOfMeasure
                );
              }
            }
          }

          //Penalty Info
          if (fareRuleInfos && fareRuleInfos.length > 0) {
            fareRuleInfos.forEach((ruleInfo) => {
              const fareRuleKey = ruleInfo.fareRuleKey;
              const chargesRules = ruleInfo.chargesRules;
              if (chargesRules) {
                const voluntaryChangesKey =
                  chargesRules.voluntaryChanges &&
                  chargesRules.voluntaryChanges.voluntaryChangesKey
                    ? chargesRules.voluntaryChanges.voluntaryChangesKey
                    : null;
                const voluntaryRefundsKey =
                  chargesRules.voluntaryRefunds &&
                  chargesRules.voluntaryRefunds.voluntaryChangesKey
                    ? chargesRules.voluntaryRefunds.voluntaryChangesKey
                    : null;

                const voluntaryChanges =
                  voluntaryChangesMap[voluntaryChangesKey];
                const voluntaryRefunds =
                  voluntaryChangesMap[voluntaryRefundsKey];
                if (voluntaryChanges && voluntaryChanges.penalty) {
                  const penaltyType = voluntaryChanges.penalty.penaltyType;
                  const departureStatus =
                    voluntaryChanges.penalty.departureStatus;
                  const currencyCode =
                    voluntaryChanges && voluntaryChanges.penalty
                      ? voluntaryChanges.penalty.currencyCode
                      : null;
                  const amount = voluntaryChanges.penalty.amount;
                  const percent = voluntaryChanges.penalty.percent;
                  const volChangeInd = voluntaryChanges.volChangeInd;
                  const flightPenaltyOptionVO = new FlightPenaltyOptionVO(
                    volChangeInd,
                    fareRuleKey,
                    penaltyType,
                    departureStatus,
                    percent,
                    amount,
                    currencyCode
                  );
                  flightFareOption.addPenaltyOption(flightPenaltyOptionVO);
                }
                if (voluntaryRefunds && voluntaryRefunds.penalty) {
                  const penaltyType = voluntaryRefunds.penalty.penaltyType;
                  const departureStatus =
                    voluntaryRefunds.penalty.departureStatus;
                  const currencyCode =
                    voluntaryChanges && voluntaryChanges.penalty
                      ? voluntaryChanges.penalty.currencyCode
                      : null;
                  const amount = voluntaryRefunds.penalty.amount;
                  const percent = voluntaryRefunds.penalty.percent;
                  const volChangeInd = voluntaryRefunds.volChangeInd;

                  const flightPenaltyOptionVO = new FlightPenaltyOptionVO(
                    volChangeInd,
                    fareRuleKey,
                    penaltyType,
                    departureStatus,
                    percent,
                    amount,
                    currencyCode
                  );

                  flightFareOption.addPenaltyOption(flightPenaltyOptionVO);
                }
              }
            });
          }

          const itineraryKeyCombine =
            flightFareOption.supplierCode +
            ":" +
            flightFareOption.preItineraryKey +
            "#" +
            flightFareOption.originDestinationKey +
            ":" +
            priceClassId +
            ":" +
            totalFareAmount;

          if (flightFareOptionKeyMap[itineraryKeyCombine]) {
            continue;
          } else {
            flightFareOptionKeyMap[itineraryKeyCombine] = 1;
          }

          if (flightFareOptionsMap[originDestinationKey]) {
            flightFareOptionsMap[originDestinationKey].push(flightFareOption);
          } else {
            flightFareOptionsMap[originDestinationKey] = [];
            flightFareOptionsMap[originDestinationKey].push(flightFareOption);
          }
          preFareOptionMap[originDestinationKey] = flightFareOption;

          preClassIdArr.push(priceClassId);

          flightFareOption.segmentPriceClass = segmentPriceClass;
        }
      }
    }
  }
  return flightFareOptionsMap;
}

function buildFlightFareOptionsMapBackUp(airLowFareSearchRS) {
  if (
    !airLowFareSearchRS ||
    !airLowFareSearchRS.pricedItineraryList ||
    airLowFareSearchRS.pricedItineraryList.length === 0
  )
    return null;

  const pricedItineraryList = airLowFareSearchRS.pricedItineraryList;
  const airItineraryMap = airLowFareSearchRS.airItineraryMap;
  const fareInfoMap = airLowFareSearchRS.fareInfoMap;
  const passengerFareMap = airLowFareSearchRS.passengerFareMap;
  const voluntaryChangesMap = airLowFareSearchRS.voluntaryChangesMap;

  const flightFareOptionsMap = {};
  for (const pricedItinerary of pricedItineraryList) {
    const airItineraryKey = pricedItinerary.rph; //行程键组合
    const offer = pricedItinerary.offer; //brand fares
    const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
    const airItinerary = airItineraryMap[airItineraryKey];
    const originDestinationOptions = airItinerary.originDestinationOptions;
    for (const originDestinationOption of originDestinationOptions) {
      //每个OD都会有对应可选择的fareOptions
      const originDestinationRPH = originDestinationOption.rph;
      const flightSegmentRPHs = originDestinationOption.flightSegmentKeyList;

      const flightFareOptionBuilder = new FlightFareOptionBuilder();
      //1.创建一个fareOption对象
      const flightFareOption = flightFareOptionBuilder
        .buildBaseFareOption(
          pricedItinerary,
          airItinerary,
          originDestinationRPH
        )
        .addPriceInfo(airItineraryPricingInfo)
        .addFareBasisCodes(
          airItineraryPricingInfo,
          flightSegmentRPHs,
          passengerFareMap,
          fareInfoMap
        )
        .addBrandFareInfos(offer, originDestinationRPH, flightSegmentRPHs)
        .addPenaltyInfos(airItineraryPricingInfo, voluntaryChangesMap)
        .build();

      //当前OD下，航班组合存在的所有Fare Options
      const originDestFareOptions = flightFareOptionsMap[originDestinationRPH];

      if (originDestFareOptions && originDestFareOptions.length > 0) {
        //去重，根据priceClassId，以及prePriceClassIdsKey决定，并且选择同样Brand Fare的最低价格，作为显示价格
        const sameDestFareOption = originDestFareOptions.find(
          (option) => option.priceClassId === flightFareOption.priceClassId
        );
        //确保上一步选择的价格依旧还在
        if (
          sameDestFareOption &&
          ((sameDestFareOption.totalPrice > flightFareOption.totalPrice &&
            flightFareOption.origFareIndicator) ||
            flightFareOption.origFareIndicator)
        ) {
          sameDestFareOption.totalPrice = flightFareOption.totalPrice;
        } else if (!sameDestFareOption) {
          originDestFareOptions.push(flightFareOption);
        }
      } else {
        flightFareOptionsMap[originDestinationRPH] = [];
        flightFareOptionsMap[originDestinationRPH].push(flightFareOption);
      }
    }
  }
  return flightFareOptionsMap;
}

function findSpecificFlightFareOptions(
  flightFareOptions,
  supplierCodes,
  originDestinationKey,
  amountRef,
  filterType
) {
  if (!flightFareOptions || flightFareOptions.length === 0) return null;
  let flightFareOptionIdMap = {};
  return flightFareOptions.filter((flightFareOption) => {
    const totalPrice = flightFareOption.totalPrice;
    const supplierCode = flightFareOption.supplierCode;
    const priceClassId = flightFareOption.priceClassId;

    //第一程只需要关注航班信息和最低价格，所以将重名的brand fare去重
    if (flightFareOptionIdMap[priceClassId]) {
      return false;
    } else {
      flightFareOptionIdMap[priceClassId] = 1;
    }

    if (
      supplierCodes &&
      supplierCodes.length > 0 &&
      supplierCodes.indexOf(supplierCode) < -1
    ) {
      return false;
    }
    if (filterType === "UP" && amountRef && totalPrice < amountRef) {
      return false;
    } else if (filterType === "DOWN" && amountRef && totalPrice > amountRef) {
      return false;
    }

    return true;
  });
}

function buildItineraryFareOptionKey(fareOptionSelected) {
  let originDestinationKeys = [];
  let currencyCode = null;
  let totalAmount = 0;
  let priceClassIds = null;
  let bookingSource = null;
  for (const fareOption of fareOptionSelected) {
    const originDestinationKey = fareOption.originDestinationKey;
    currencyCode = fareOption.currencyCode;
    totalAmount = fareOption.totalPrice;
    bookingSource = fareOption.supplierCode;
    const priceClassId = fareOption.priceClassId;

    originDestinationKeys.push(originDestinationKey);
    if (!priceClassIds) priceClassIds = [];
    priceClassIds.push(priceClassId);
  }

  let key =
    bookingSource +
    ":" +
    originDestinationKeys.join("#") +
    ":!" +
    currencyCode +
    totalAmount;
  if (priceClassIds && priceClassIds.length > 0) {
    // key += "!!" + priceClassIds.join(":");
    key += "!!";
    let first = true;
    for (const priceClassId of priceClassIds) {
      if (
        !priceClassId ||
        priceClassId === "undefined" ||
        priceClassId === "null"
      )
        continue;

      if (first) {
        key += priceClassId;
        first = false;
      } else {
        key += ":" + priceClassId;
      }
    }
  }
  return key;
}

//endregion FlightFareOptions

function findSpecificItineraryRPH(
  fareOriginDesBasisCodesMap,
  airLowFareSearchRS,
  airItineraryMap,
  flightSegmentMap
) {
  if (
    !airLowFareSearchRS ||
    !airLowFareSearchRS.pricedItineraryList ||
    airLowFareSearchRS.pricedItineraryList.length === 0
  )
    return null;

  const pricedItineraryList = airLowFareSearchRS.pricedItineraryList;
  for (const pricedItinerary of pricedItineraryList) {
    const airItineraryRPH = pricedItinerary.airItineraryRPH;
    const airItineraryKey = pricedItinerary.rph;
    const airItinerary = airItineraryMap[airItineraryKey];
    const originDestinationOptions = airItinerary.originDestinationOptions;
    const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
    const ptcFareBreakdowns = airItineraryPricingInfo.PTC_FareBreakdowns;
  }
}

export default {
  buildFlightShoppingRQ,
  buildItineraryArrangement,
  buildItineraryFareOptionKeyMap,
  buildFlightItems,
  findAllNecessaryCodes,
  findAllAirportCodes,
  buildFlightKeyGroups,
  buildFlightFareOptionsMap,
  buildFlightFareOptionsMapBackUp,
  buildItineraryFareOptionKey,
  findSpecificFlightItems,
  findSpecificFlightFareOptions,
  findSpecificItineraryRPH,
};
