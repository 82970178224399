<script setup>
import { Collapse, CollapsePanel, Modal } from "ant-design-vue";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { createVNode, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useTemplateStore } from "@/stores/template";
import { useProfileStore } from "@/stores/profile";
import CustomerProfileMembershipModifyForm from "@/views/profiles/items/form/profile/CustomerProfileMembershipModifyForm.vue";
import UserProfileFormModel from "@/formmodel/user/profile/userProfileFormModel";
import commonUtils from "@/utils/commonUtils";
import { useVuelidate } from "@vuelidate/core";
import CustomerProfileFlightPreferenceModifyForm from "@/views/profiles/items/form/profile/CustomerProfileFlightPreferenceModifyForm.vue";
import CustomerProfileTravelDocumentModifyForm from "@/views/profiles/items/form/profile/CustomerProfileTravelDocumentModifyForm.vue";
import CustomerProfileAirportSecurityModifyForm from "@/views/profiles/items/form/profile/CustomerProfileAirportSecurityModifyForm.vue";
import CustomerProfileAdditionalPersonInfoForm from "@/views/profiles/items/form/profile/CustomerProfileAdditionalPersonInfoForm.vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

const props = defineProps({
  travellerInfo: {},
});

const { t, locale } = useI18n(); // 解构调用函数
const emits = defineEmits([]);
const user = JSON.parse(sessionStorage.getItem("user"));
const AModal = Modal;
const ACollapse = Collapse;
const ACollapsePanel = CollapsePanel;

const templateStore = useTemplateStore();
const profileStore = useProfileStore();
const userProfileFormModalRef = ref(null);

watch(
  () => props.travellerInfo,
  (newValue) => {
    const profile = JSON.parse(JSON.stringify(newValue));
    if (profile) {
      userProfileFormModalRef.value = new UserProfileFormModel(
        profile.partyId,
        profile.username,
        profile.nameTitle,
        profile.gender,
        profile.givenName,
        profile.surname,
        profile.birthDate,
        profile.mobileNumber,
        profile.mobileCountryAccessCode,
        profile.mobileContactMechId,
        profile.emergencyNumber,
        profile.emergencyCountryAccessCode,
        profile.emergencyContactName,
        profile.emergencyContactMechId,
        profile.emailAddress,
        profile.emailContactMechId,
        profile.country,
        profile.province,
        profile.city,
        profile.region,
        profile.postCode,
        profile.addressContactMechId,
        profile.knownTravellerNumber,
        profile.knownTravellerNumberId,
        profile.redressNumber,
        profile.redressNumberId,
        profile.documents,
        profile.seatPref,
        profile.seatPrefId,
        profile.mealPref,
        profile.mealPrefId,
        profile.custLoyalties,
        profile.additionalTravellers
      );
      if (!userProfileFormModalRef.value.custLoyalties)
        userProfileFormModalRef.value.addNewCustLoyalty(null, null, null);
      if (!userProfileFormModalRef.value.documents)
        userProfileFormModalRef.value.addNewDocument(
          "PASSPORT",
          null,
          null,
          null,
          null
        );
    }
  },
  { immediate: true, deep: true }
);

const activeKeyRef = ref([]);

function removeTraveller(partyId) {
  AModal.confirm({
    content: "You will no longer be able to access this traveller.",
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    width: 600,
    wrapClassName: "confirmModal",
    zIndex: "22223",
    onOk() {
      return new Promise((resolve, reject) => {
        emits("removeTraveller", partyId);
        AModal.destroyAll();
      });
    },
    cancelText: "Cancel",
    onCancel() {
      AModal.destroyAll();
    },
  });
}

const v = useVuelidate();

async function modifyPersonInfo(profileFormModel) {
  const result = await v.value.$validate();
  if (!result) return;
  templateStore.pageLoader({ mode: "on" });
  profileStore.modifyAdditionalPersonInfo(profileFormModel).then((res) => {
    commonUtils.openToastBox(
      "SUCCESS",
      t("this-is-an-success-message"),
      "Modified successfully!",
      function () {
        profileStore.loadUserProfile(user.partyId);
      }
    );
    activeKeyRef.value = [];
    templateStore.pageLoader({ mode: "off" });
  });
}

async function modifyAirportSecurity(profileFormModel, type) {
  const result = await v.value.$validate();
  if (!result) return;

  templateStore.pageLoader({ mode: "on" });
  profileStore.modifyAirportSecurity(profileFormModel).then((res) => {
    commonUtils.openToastBox(
      "SUCCESS",
      t("this-is-an-success-message"),
      "Modified successfully!",
      function () {
        profileStore.loadUserProfile(user.partyId);
      }
    );
    templateStore.pageLoader({ mode: "off" });
  });
}

async function modifyTravelDocuments(profileFormModel, type) {
  const result = await v.value.$validate();
  if (!result) return;

  templateStore.pageLoader({ mode: "on" });
  profileStore.modifyTravelDocuments(profileFormModel).then((res) => {
    commonUtils.openToastBox(
      "SUCCESS",
      t("this-is-an-success-message"),
      "Modified successfully!",
      function () {
        profileStore.loadUserProfile(user.partyId);
      }
    );
    templateStore.pageLoader({ mode: "off" });
  });
}

async function modifyFlightPreference(profileFormModel, type) {
  const result = await v.value.$validate();
  if (!result) return;

  templateStore.pageLoader({ mode: "on" });
  profileStore.modifyFlightPreference(profileFormModel).then((res) => {
    commonUtils.openToastBox(
      "SUCCESS",
      t("this-is-an-success-message"),
      "Modified successfully!",
      function () {
        profileStore.loadUserProfile(user.partyId);
      }
    );
    templateStore.pageLoader({ mode: "off" });
  });
}

async function modifyMemberships(profileFormModel) {
  const result = await v.value.$validate();
  if (!result) return;

  templateStore.pageLoader({ mode: "on" });
  profileStore.modifyMemberships(profileFormModel).then((res) => {
    commonUtils.openToastBox(
      "SUCCESS",
      t("this-is-an-success-message"),
      "Modified successfully!",
      function () {
        profileStore.loadUserProfile(user.partyId);
      }
    );
    templateStore.pageLoader({ mode: "off" });
  });
}
</script>

<template>
  <div class="col-6" v-if="travellerInfo">
    <div class="font_weight font-18">
      {{ travellerInfo.surname }} {{ travellerInfo.givenName }}
    </div>
    <a-collapse v-model:activeKey="activeKeyRef" accordion>
      <a-collapse-panel key="1" header="Frequent Flyer and Membership">
        <CustomerProfileMembershipModifyForm
          :user-profile-form-modal="userProfileFormModalRef"
          :is-additional-traveller="true"
          @modifyMemberships="modifyMemberships"
        />
      </a-collapse-panel>
      <a-collapse-panel key="2" header="Personal Info">
        <CustomerProfileAdditionalPersonInfoForm
          :user-profile-form-modal="userProfileFormModalRef"
          @modifyPersonInfo="modifyPersonInfo"
        />
      </a-collapse-panel>

      <a-collapse-panel key="3" header="Flight Preferences">
        <CustomerProfileFlightPreferenceModifyForm
          :user-profile-form-modal="userProfileFormModalRef"
          @modifyFlightPreference="modifyFlightPreference"
        />
      </a-collapse-panel>
      <a-collapse-panel key="4" header="Passports">
        <CustomerProfileTravelDocumentModifyForm
          :user-profile-form-modal="userProfileFormModalRef"
          @modifyTravelDocuments="modifyTravelDocuments"
        />
      </a-collapse-panel>
      <a-collapse-panel key="5" header="TSA Info">
        <CustomerProfileAirportSecurityModifyForm
          :user-profile-form-modal="userProfileFormModalRef"
          @modifyAirportSecurity="modifyAirportSecurity"
        />
      </a-collapse-panel>
    </a-collapse>
    <div
      class="text-end mg-t-10"
      @click="removeTraveller(travellerInfo.partyId)"
    >
      <a class="link-primary">Remove traveller</a>
    </div>
  </div>
</template>

<style scoped></style>
